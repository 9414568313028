/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import Page from '../../components/page';

import './about.less';
import IMG_BANNER1 from './images/banner/t1.jpg';
import IMG_BANNER2 from './images/banner/t2.jpg';
import IMG_BANNER3 from './images/banner/t3.jpg';
import IMG_BANNER4 from './images/banner/t4.jpg';
import IMG_TIMELINE from './images/timeline.jpeg';
import IMG_LOGO from './images/logo-black.png';

const PageContainer = props => {
  const [ target, setTarget ] = useState(1);
	return (
  <section className="temp-container">
    <div className="temp-header" style={{ position: 'relative', backgroundColor: '#ffffff', height: 56 }}>
      <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link className="logo" style={{ backgroundImage: `url(${IMG_LOGO})`, width: 36, height: 36 }} to="/home" />
        <div className="tabs">
          <Link className="tab-item" to="/cases">客户案例</Link>
          <Link className="tab-item" to="/aboutus" style={{ textDecoration: 'underline' }}>公司简介</Link>
          <Link className="tab-item" to="/contact">联系我们</Link>
          {/*<Link className="tab-item" to="">技术支持</Link>*/}
        </div>
      </div>
    </div>
    <section className="temp-body" style={{ paddingBottom: 56 }}>
      <div className="content-banner" style={{ backgroundImage: `url(${IMG_BANNER2})` }} />
      <div className="temp-wrapper">
        <div className="aboutus-tabs">
          <span className={ target == 1 ? 'cur' : '' } onClick={() => setTarget(1)}>了解我们</span>
          <span className={ target == 2 ? 'cur' : '' } onClick={() => setTarget(2)}>优势</span>
          <span className={ target == 3 ? 'cur' : '' } onClick={() => setTarget(3)}>大纪事</span>
        </div>
        <div className="aboutus-main">
          {
            target == 1 && <div className="aboutus-base">
              <div className="data">
                <div className="item"><span>2019</span><label>成立</label></div>
                <div className="item"><span>99+</span><label>服务客户</label></div>
                <div className="item"><span>66</span><label>没想到项</label></div>
              </div>
              <div className="intro">公司成立于2017年，是一家集平面广告设计，展陈空间设计，活动策划，软件开发，社会组织第三方服务，企业形象和品牌推广于一体的综合性公司。团队核心成员均来自腾讯，阿里，国家大剧院等技术骨干，企业形象和品牌推广于一体的综合性公司。团队核心成员均来自腾讯，阿里，国家大剧院等技术骨干，企业形象和品牌推广于一体的综合性公司。团队核心成员均来自腾讯，阿里，国家大剧院等技术骨干。</div>
              <div className="bussiness">
                <h3>业务覆盖范围</h3>
                <p>广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个</p>
                <img src={IMG_BANNER3} />
                <img src={IMG_BANNER3} />
                <p>广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个</p>
                <p>广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个</p>
                <img src={IMG_BANNER3} />
                <p>广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个广告业务包括这个包括那个</p>
              </div>
            </div>
          }
          {
            target == 2 && <div className="aboutus-good">
              {
                [
                  { cover: IMG_BANNER1, title: '高效响应，及时跟进', content: '我们的专业设计师、工程师将会在第一时间联系客户，沟通清楚客户需求，并帮助其落地问题及其解决方案' },
                  { cover: IMG_BANNER2, title: '明确需求，钻研方案', content: '仔细分析客户需求，帮助客户寻找最佳解决方案' },
                  { cover: IMG_BANNER3, title: '改革创新，更进一步', content: '在满足用户基础需求的情况下，提出相关创新方案给到客户，帮助客户进一步的优化产出' },
                  { cover: IMG_BANNER4, title: '团队优势', content: '团队核心成员均来自腾讯、阿里，具有丰富的大型项目经验和资源' },
                ].map((item, idx) => {
                  return (
                    <div className="item" key={idx}>
                      <div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
                      <span className="title">{item.title}</span>
                      <span className="content">{item.content}</span>
                    </div>
                  )
                })
              }
            </div>
          }
          {
            target == 3 && <div className="aboutus-events">
              <img src={IMG_TIMELINE} />
            </div>
          }
        </div>
      </div>
    </section>
    <div className="temp-footer">
      © 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 沪ICP备19019249号-1
    </div>
  </section>
	);
};

export default PageContainer;