/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as WX from '../../common/wx';
import html2canvas from 'html2canvas';

import './townProtect.less';

// source
import IMG_MAIN from './images/townProtect/main-bg.jpg';
import IMG_POP from './images/townProtect/pop-bg.jpg';
import IMG_RES from './images/townProtect/res-bg.jpg';

const activityName = 'townProtect';
const { isInWechat, isInRongMeiApp } = Utils.getUA();
const _width = window.innerWidth;
const _height = window.innerWidth / (750/1334);

const PageCTownProtect = props => {
  const [ maskFlag, setMaskFlag ] = useState(false);
  const [ nameFlag, setNameFlag ] = useState(false);
  const [ name, setName ] = useState('');
  const [ count, setCount ] = useState(0);
  const [ resFlag, setResFlag ] = useState(false);
  const [ activityInfo, setActivityInfo ] = useState({});
  const [ userInfo, setUserInfo ] = useState({});
  const [ resultImg, setResultImg ] = useState('');

  /**
   * initial user information
   */
  useEffect(() => {
    if(!isInWechat) return;
    initialUserInfo();
  }, [123]);
  const initialUserInfo = async () => {
    const info = await Utils.user.getInfo();
    if(info && info.yid && info.nickname) {
      setUserInfo(info);
    }else{
      const info = await Utils.user.login({ appId: CONST.appId.test });
      setUserInfo(info);
    }
  };

  /**
   * initial activity information
   */
  useEffect(() => {
    initialActivityConfig();
  }, [123]);
  const initialActivityConfig = async () => {
    const result = await Request.get({
      headers: { 'Activity-Name': activityName },
      url: '/activity/config'
    });
    if(!result) return;

    const data = result.data || {};
    setActivityInfo(data);

    // initial page title
    if(data.config && data.config.title) {
      Utils.setTitle(data.config.title);
    }
  };

  /**
   * initial data
   */
  useEffect(() => {
    getData();
  }, [123]);
  const getData = () => {
    let name = localStorage.getItem('yeekin-townprotect-name');
    let count = localStorage.getItem('yeekin-townprotect-count');
    setName(name);
    setCount(count);

    if(!!name && !!count) {
      setMaskFlag(true);
      setResFlag(true);

      setTimeout(() => {
        saveToImg();
      }, 666);
    }
  };

  /**
   * submit data
   */
  const submit = async () => {
    if(!name || name == '') {
      Utils._alert('请输入您的姓名');
      return;
    }

    Utils.toast.show('正在生成，可保存分享给你的好朋友！');

    const result = await Request.post({
      headers: { 'Activity-Name': activityName },
      url: '/activity/count'
    });
    if(!result) return;

    const count = result.data.count;
    localStorage.setItem('yeekin-townprotect-name', name);
    localStorage.setItem('yeekin-townprotect-count', count);
    setCount(count);
    setNameFlag(false);
    setResFlag(true);

    // do share
    setTimeout(() => {
      const _shareConf = activityInfo.shareConfig || {};
      if(name && count) {
        _shareConf.title = `${name}是第${count}位参加"练塘三守护全民战疫云行动"，邀请您一起争当练塘守护家！`;
      }
      WX.share(_shareConf);

      saveToImg(() => {
        Utils.toast.hide();
      });
    }, 666);
  };

  /**
   * 保存为图片
   */
  const saveToImg = (callback) => {
    const htmlDom = document.querySelector('#imgHtml');

    html2canvas(htmlDom , {
      allowTaint: false, //允许污染
      taintTest: true, //在渲染前测试图片(没整明白有啥用)
      useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用,下面解释)
      // background: "#fff",
      dpi: window.devicePixelRatio,
      width: _width,
      height: _height
      // width: w,
      // height: h,
    }).then(function(canvas) {
      const imgBlob = canvas.toDataURL( 'image/jpeg', 1.0 );
      setResultImg(imgBlob);

      callback && callback();
    });
  };
  /**
   * initial share config
   * mainly for wechat
   */
  useEffect(() => {
    if(!isInWechat) return;
    initialShare();
  }, [JSON.stringify(activityInfo)]);
  const initialShare = async () => {
    await WX.sign();

    // do share
    setTimeout(() => {
      const _shareConf = activityInfo.shareConfig || {};
      if(name && count) {
        _shareConf.title = `${name}是第${count}位参加"练塘三守护全民战疫云行动"，邀请您一起争当练塘守护家！`;
      }
      WX.share(_shareConf);
    }, 666);
  };

	return (
	  <div>
      <section className="town-protect-container" style={{ height: window.innerWidth/(750/1334), backgroundImage: `url(${IMG_MAIN})` }}>
        <div className="action-btn" onClick={() => {
          if(!!name && !!count) {
            setMaskFlag(true);
            setResFlag(true);
            return;
          }

          setNameFlag(true);
          setMaskFlag(true);
        }} />

        { maskFlag && <div className="masker" onClick={() => {
          if(!resFlag) return;

          setMaskFlag(false);
          setResFlag(false);
        }} /> }
        {
          nameFlag && <div className="name-inputer" style={{ backgroundImage: `url(${IMG_POP})`, height: window.innerWidth/(750/822) }}>
            <input placeholder="请输入您的姓名" onChange={(e) => {
              setName(e.target.value);
            }} />
            <div className="submit-btn" onClick={submit} />
            <div className="close-btn" onClick={() => {
              setNameFlag(false);
              setMaskFlag(false);
            }} />
          </div>
        }

        {
          resFlag && resultImg && <div className="result" style={{ height: 0.85*_height, width: 0.85*_width, zIndex: 101 }}>
            <img src={resultImg} />
          </div>
        }
      </section>

      <div className="out-result-cover" />
      <div className="out-result" id="imgHtml">
        <img src={IMG_RES} style={{ width: _width, height: _height }} />
        <span className="msg">{name}是第{count}位练塘守护家践行者！</span>
        <span className="shuming">练塘镇文明委</span>
      </div>
    </div>
	);
};

export default PageCTownProtect;