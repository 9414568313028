/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactSwipe from 'react-swipe';

// import Page from '../../components/page';

import './home.less';
import IMG_BANNER1 from './images/banner/t1.jpg';
import IMG_BANNER2 from './images/banner/t2.jpg';
import IMG_BANNER3 from './images/banner/t3.jpg';
import IMG_BANNER4 from './images/banner/t4.jpg';
import IMG_LOGO from './images/logo-white.png';

const PageContainer = props => {
  const [ swipeIndex, setSwipeIndex ] = useState(0);

	let reactSwipeEl;

	return (
  <section className="temp-container">
    <div className="temp-header" style={{ position: 'fixed', backgroundImage: 'linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0))', height: 88 }}>
      <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="logo" style={{ backgroundImage: `url(${IMG_LOGO})` }} />
        <div className="tabs">
          <Link class="tab-item" style={{ color: '#ffffff' }} to="/cases">客户案例</Link>
          <Link class="tab-item" style={{ color: '#ffffff' }} to="/aboutus">公司简介</Link>
          <Link class="tab-item" style={{ color: '#ffffff' }} to="/contact">联系我们</Link>
          {/*<Link class="tab-item" style={{ color: '#ffffff' }} to="">技术支持</Link>*/}
        </div>
      </div>
    </div>
    <section className="temp-body" style={{ height: window.innerHeight }}>
      <section className="temp-banner-wrap">
        <ReactSwipe
          className="banner"
          swipeOptions={{
            startSlide: swipeIndex,
            continuous: true,
            stopPropagation: false,
            auto: 3000,
            transitionEnd: setSwipeIndex
          }}
          ref={el => (reactSwipeEl = el)}
        >
          {
            [IMG_BANNER1, IMG_BANNER2, IMG_BANNER3, IMG_BANNER4].map((pic, idx) => (
              <div className="banner-item" key={idx} style={{ backgroundImage: `url(${pic})`, height: window.innerHeight }} onClick={() => {
                props.history.push('/cases/123');
              }} />
            ))
          }
        </ReactSwipe>
        <div className="banner-ctrls temp-wrapper">
          {
            [IMG_BANNER1, IMG_BANNER2, IMG_BANNER3, IMG_BANNER4].map((item, idx) => {
              return <span key={idx} style={{ backgroundImage: `url(${item})`, filter: `blur(${swipeIndex == idx ? 0 : 3}px)` }} onClick={() => setSwipeIndex(idx)} />
            })
          }
        </div>
      </section>
    </section>
  </section>
	);
};

export default PageContainer;