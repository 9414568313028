/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as WX from '../../common/wx';
import html2canvas from 'html2canvas';

import './chuangwen2021.less';

import IMG_BG from './images/chuangwen2021/bg.jpg';
import IMG_CARD_1 from './images/chuangwen2021/card/1.jpg';
import IMG_CARD_2 from './images/chuangwen2021/card/2.jpg';
import IMG_CARD_3 from './images/chuangwen2021/card/3.jpg';
import IMG_CARD_4 from './images/chuangwen2021/card/4.jpg';
import IMG_CARD_5 from './images/chuangwen2021/card/5.jpg';
import IMG_CARD_6 from './images/chuangwen2021/card/6.jpg';
import IMG_CARD_7 from './images/chuangwen2021/card/7.jpg';
import IMG_CARD_8 from './images/chuangwen2021/card/8.jpg';
import IMG_CARD_9 from './images/chuangwen2021/card/9.jpg';
import IMG_CARD_10 from './images/chuangwen2021/card/10.jpg';

const CARD_SETS = [
	IMG_CARD_1,
	IMG_CARD_2,
	IMG_CARD_3,
	IMG_CARD_4,
	IMG_CARD_5,
	IMG_CARD_6,
	IMG_CARD_7,
	IMG_CARD_8,
	IMG_CARD_9,
	IMG_CARD_10,
];

const shareConfig = {
	title:'2021创文，我们同行',
	desc:'创文志愿者们，一起抽练塘话盲盒了！',
	link: 'https://www.yeekin.cn/activity/chuangwen2021',
	imgUrl: 'https://www.yeekin.cn/activity/chuangwen2021-sharelogo.jpg', // 分享图标
};

const { isInWechat, isInRongMeiApp, isIOS, isAndroid } = Utils.getUA();

const PageCTownProtect = props => {
	const [ playState, setPlayState ] = useState(false);
	const [ showMusic, setShowMusic ] = useState(false);
	const [ count, setCount ] = useState(null);
	const [ step, setStep ] = useState(1);
	const [ shareFlag, setShareFlag ] = useState(false);
	const [ card, setCard ] = useState(null);

	const [ isFirst, setIsFirst ] = useState(true);

	const action = async () => {
		if(isFirst) {
			const result = await Request.post({
				headers: { 'Activity-Name': 'chuangwen2021' },
				url: '/activity/count'
			});
			if(!result) return;
			const count = result.data.count;
			setCount(count);
		}

		// 切换下一页
		setStep(2);

		// 随机卡片
		const index = parseInt(Math.random()*CARD_SETS.length);
		const card = CARD_SETS[index || 0];
		setCard(card);
	};

	useEffect(() => {
		initialShare();

		Utils.setTitle('创文志愿者们，一起抽练塘话盲盒了！');

		// 初始化音乐
		window.wx.ready(function() {
			document.getElementById('audio1').play();
		});
		document.getElementById('audio1').onplay = function (){
			setShowMusic(true);
			setPlayState(true);
		};
	}, [123]);
	const initialShare = async () => {
		await WX.sign();
		setTimeout(() => {
			WX.share(shareConfig);
		}, 666);
	};

	return (
    <section className="chuangwen2021-container" style={{ height: window.innerHeight, backgroundImage: `url(${IMG_BG})` }}>
			{
				showMusic && <div className="music music-move" style={{ animationPlayState: playState ? '' : 'paused' }} onClick={() => {
					const audio1 = document.getElementById('audio1');
					if(playState) {
						audio1.pause();
					}else{
						audio1.play();
					}
					setPlayState(!playState);
				}} />
			}
			<div style={{ display: 'none' }}>
				<audio id="audio1" src="https://www.yeekin.cn/activity/brisk-music.mp3" autoPlay preload loop controls></audio>
			</div>

			{
				step && step == 1 && <div className="page-1">
					<div className="chuangwen2021-text">
						<span>城市因文明而美丽</span>
						<span>生活因文明而舒心</span>
						<span>2020年</span>
						<span>青浦成功创建第六届全国文明城区</span>
						<span>2021年</span>
						<span>练塘镇为巩固创文成果持续发力</span>
						<span>练塘的市民朋友们</span>
						<span>您的每一个文明举止</span>
						<span>都会为练塘增光添彩</span>
						<span>您的每一份辛勤付出</span>
						<span>都会唤起更多同行人</span>
						<span>您的每一个爱心行动</span>
						<span>都会让这座城市更有温度</span>
						<span style={{ marginTop: 20 }}>文明创建</span>
						<span>只有起点</span>
						<span>没有终点</span>
						<span>让我们再一次行动起来</span>
						<span>做文明行为的践行者</span>
						<span>做文明风尚的宣传者</span>
						<span>做文明城区的守护者</span>
					</div>
					<div className="chuangwen2021-btn" onClick={action}><span style={{ fontSize: 22 }}>抽取你的专属文明卡</span></div>
				</div>
 			}
			{
				step && step == 2 && card && <div className="page-2">
					<div className="chuangwen2021-card">
						<img src={card} />
						{ count && <div className="span-wrap"><span>您是第 {count} 位创文支持者</span></div> }
					</div>
					<div className="chuangwen2021-btnwrap">
						<div className="chuangwen2021-btn" onClick={() => {
							setIsFirst(false);
							setStep(1);

							WX.share(shareConfig);
						}}><span>再抽一次</span></div>
						<div className="chuangwen2021-btn" onClick={() => {
							WX.share({
								...shareConfig,
								title:'2021创文，我们同行',
								desc: `创文志愿者们，我是第 ${count} 位创文支持者，等你来！`
							});

							setShareFlag(true);
						}}><span>分享好友</span></div>
					</div>
				</div>
			}

			{
				shareFlag && <div className="sharetip-wrap">
					<div className="share-tip" />
					<div className="share-btn" onClick={() => setShareFlag(false)} />
				</div>
			}
    </section>
	);
};

export default PageCTownProtect;
