/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';

import './index.less';

const PageModel = props => {
  return (
    <section className="matrix-wrapper" style={{ height: window.innerHeight }}>
      {
        [
          { label: '实时客流', target: 'https://www.yeekin.cn/zhujiajiao/passenger' },
          { label: '停车信息', target: 'https://www.yeekin.cn/zhujiajiao/parking' },
        ].map((item, idx) => {
          return <div className="matrix-item" key={idx} onClick={() => {
            if(item.target && item.target.startsWith('http')) {
              window.location.href = item.target;
            }
          }}><span>{item.label}</span></div>
        })
      }
    </section>
  );
};

export default PageModel;
