/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Typography, Input, Form, message, Modal, Select} from 'antd';
import BraftEditor from 'braft-editor';

import * as CONST from '../common/const';
import * as REQUEST from "../common/request";

import Page from '../components/page';
import './index.less';
import * as Utils from "../common/utils";
import moment from "moment";

const PageModel = props => {
  console.log('props', props);
  const [ data, setData ] = useState(null);
  const getData = async () => {
    const { id } = props.match.params;
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/announce/get',
      data: { id }
    });
    if(!result) return;

    setData(result.data);
    if(result?.data?.title) {
      Utils.setPageTitle(result.data.title);
    }
  };
  useEffect(() => {
    getData();

    return () => false;
  }, [ props.match.params ]);

  if(!data) return null;

  const contentDetail = data.detail;
  const editorState = BraftEditor.createEditorState(contentDetail);
  const contentHtml = editorState.toHTML();

  return (
    <section className="announce-detail-wrapper">
      <div className="title">{data.title}</div>
      <div className="info">
        { data.description && <span className="description">{data.description}</span> }
        <div className="date">
          <span style={{ color: '#576b95', paddingRight: 4 }}>乐游朱家角</span>
          {data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm')}
        </div>
      </div>
      <div className="detail" dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </section>
  );
};

export default PageModel;
