/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Image, Input, DatePicker, InputNumber, Form, message, Modal, Select, Tooltip, Table, Typography} from 'antd';
import { WarningOutlined, ZoomInOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';

import * as CONST from '../../common/const';
import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import Page from '../../components/page';
import FilterForm from "../../../../../components/filterForm";
import UploadPictureList from '../../components/uploadPictureList';
import './index.less';
import moment from "moment";

const PageModel = props => {
  const [form] = Form.useForm();

  const [ modalFormObj, setModalFormObj ] = useState(null);
  const [ modalVisible, setModalVisible ] = useState(false);
  const closeModalHandle = () => {
    setModalVisible(false);
    setModalFormObj(null);
    setTimeout(() => {
      form.resetFields();
    }, 20);
  };

  const [ list, setList ] = useState([]);
  const getList = async (data = {}) => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/cruise/list',
      data
    });
    if(!result) return;

    result.data.list.map(item => {
      item.key = item.id;
      return item;
    });
    setList(result.data.list);
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const submitHandle = async (params = {}) => {
    if(!params) return;

    let url = '/zhujiajiao/v2/admin/cruise/add';
    if(modalFormObj?.id) {
      params.id = modalFormObj.id;
      url = '/zhujiajiao/v2/admin/cruise/update';
    }

    const result = await REQUEST.post({
      url,
      data: params
    });
    if(!result) return;

    message.success('操作成功');

    closeModalHandle();
    getList();
  };
  const deleteHandle = async (id) => {
    if(!id) return;

    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/cruise/delete',
      data: {
        id
      }
    });
    if(!result) return;

    message.success('操作成功');
    getList();
  };

  return (
    <Page {...{
      ...props,
      breadcrumbs: [ { label: '首页' }, { label: '游船班期列表' } ],
      title: '预约班期设置'
    }}>
      <section className="cruise-schedule-container" style={{ minHeight: window.innerHeight }}>
        <FilterForm {...{
          style: { marginTop: 20, marginBottom: 0 },
          fields: list?.length > 0 && [
            {
              type: 'select', label: '预约日期', name: 's-date', placeholder: '请选择', options: (() => {
                const arr = [];
                const arrMap = {};
                list.map(lst => {
                  if(!arrMap[lst.date]) {
                    arrMap[lst.date] = true;
                    arr.push({ id: lst.date, label: lst.date })
                  }
                });
                return arr;
              })()
            },
          ] || [],
          filterHandle: data => {
            getList(data);
          },
          newHandle: () => {
            setModalVisible(true);
            form.resetFields();
          }
        }} />

        <Table {...{
          style: { marginTop: 20 },
          size: 'small',
          locale: { emptyText: '暂无数据' },
          dataSource: list,
          columns: [
            { title: '日期', dataIndex: 'date', key: 'date' },
            { title: '时段', dataIndex: 'time', key: 'time' },
            { title: '库存', dataIndex: 'stock', key: 'stock' },
            {
              title: '操作', dataIndex: 'edit', key: 'edit', width: 156, fixed: 'right',
              render: (text, record, index) => {
                const _style = { marginRight: 10,	fontSize: 12 };
                return <>
                  <Button size="small" type="primary" ghost style={_style} onClick={() => {
                    const _record = { ...record };
                    _record.date = moment(_record.date, 'YYYY年MM月DD日');
                    setModalFormObj(_record);

                    setModalVisible(true);
                  }}>编辑</Button>
                  <Button type="text" danger size="small" style={_style} onClick={() => {
                    Modal.confirm({
                      title: '删除的数据将无法找回，请慎重操作！',
                      icon: <WarningOutlined style={{ color: '#E45357' }} />,
                      // content: 'Bla bla ...',
                      okText: '确认删除',
                      cancelText: '取消',
                      onOk: () => {
                        deleteHandle(record.id);
                      },
                      onCancel: () => {
                        // console.log('Cancel');
                      },
                    });
                  }}>删除</Button>
                </>
              }
            },
          ]
        }} />
      </section>

      <Modal {...{
        title: modalFormObj && '编辑' || '新增',
        visible: modalVisible,
        maskClosable: false,
        destroyOnClose: true,
        onCancel: closeModalHandle,
        footer: null
      }}>
        {
          modalVisible && <Form {...{
            initialValues: modalFormObj || { stock: 2 },
            form,
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            onFinish: values => {
              if(values.date) {
                values.date = moment(values.date).format('YYYY年MM月DD日');
              }
              submitHandle(values);
            }
          }}>
            <Form.Item label="日期" name="date" rules={[
              { required: true, message: '标题不能为空' }
            ]}>
              <DatePicker placeholder="请选择日期" style={{ width: 200 }} format="YYYY年MM月DD日" />
            </Form.Item>
            <Form.Item label="时段" name="time" rules={[
              { required: true, message: '时段不能为空' }
            ]}>
              <Select placeholder="请选择时段">
                {
                  // [ '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00' ].map((item, idx) => {
                  [ '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00' ].map((item, idx) => {
                    return <Select.Option value={item} key={idx}>{item}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="库存" name="stock" rules={[
              { required: true, message: '库存不能为空' }
            ]}>
              <InputNumber placeholder="请设置库存" style={{ width: 200 }} />
            </Form.Item>

            <Form.Item label=" " colon={false}>
              <Button style={{ marginRight: 16 }} onClick={closeModalHandle}>取消</Button>
              <Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>
        }
      </Modal>

    </Page>
  );
};

export default PageModel;
