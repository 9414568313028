/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';

import './pics.less';
import IMG_404 from './images/404.png'

const PageCIIE = props => {
	const [ status, setStatus ] = useState(1);
	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/activity/pics/list'
		});
		if(!result) return;

		const data = result.data || {};
		setList(data.list);

		setStatus(2);
	};

	useEffect(() => {
		getList();

		Utils.setTitle('图片集');
	}, [123]);

	if(status == 1) {
		setTimeout(() => {
			return (
				<div className="tiper"><span>loading……</span></div>
			)
		}, 1000);
	}
	return (
    <div className="pics-container" style={{ height: window.innerHeight }}>
	    <div className="pics-list">
		    {
			    list && list.length > 0 && list.map((item, idx) => {
			    	const imgPref = 'https://www.yeekin.cn';
			    	return (
					    <div className="picitem" key={idx}>
						    <div className="cover" style={{ backgroundImage: `url(${imgPref + item})` }} />
						    <img src={item} />
						    <span>长按保存图片</span>
					    </div>
				    )
			    }) || (status == 2 && <div className="tiper"><img src={IMG_404} /><span>暂无图片</span></div>)
		    }
	    </div>
    </div>
  )
};

export default PageCIIE;