/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Button, Upload, message, Modal} from 'antd';
import { WarningOutlined, UploadOutlined } from '@ant-design/icons';
import './index.less';

import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';

const CompModel = props => {
	const { fileList = [], defaultFileList = undefined, changeHandle = () => {}, action = '', multiple = false, accept = 'image/*', pictureLength = 8, listType = 'picture-card', buttonText = '上传图片' } = props;
	const [ _fileList, _setFileList ] = useState([]);
	const [ _defaultFileList, _setDefaultFileList ] = useState(undefined);
	const initFileList = () => {
		const _fileList = [];
		if(defaultFileList.length > 0) {
			defaultFileList.map((item, idx) => {
				const url = item;
				const name = (url => {
					const urlArr = url.split('/');
					return urlArr[urlArr.length - 1];
				})(url);
				_fileList.push({
					url: CONST.imgPrefix + url,
					src: url,
					uid: String(Math.random()) + idx,
					percent: 50,
					name,
					status: 'done'
				})
			});
		}
		_setDefaultFileList(_fileList);
		_setFileList(_fileList);
	};
	useEffect(() => {
		initFileList();
	}, [ defaultFileList ]);

	const uploadButton = ({
		'picture-card': <div>
			<UploadOutlined />
			<div style={{ marginTop: 8 }}>{buttonText}</div>
		</div>,
		'picture': <Button icon={<UploadOutlined />}>{buttonText}</Button>
	})[listType];

	if(!_defaultFileList) return null;

	return (
		<Upload {...{
			multiple,
			accept,
			action,
			listType,
			defaultFileList: _defaultFileList || undefined,
			maxCount: pictureLength,
			// fileList: _fileList,
			beforeUpload(file) {
				const flag = Utils.imageUploadSizeVerify(file);
				if(!flag) return Upload.LIST_IGNORE;
				// 只对图片进行压缩
				// if(file && file.type.indexOf('image') > -1) {
				// 	return new Promise(resolve => {
				// 		const reader = new FileReader();
				// 		reader.readAsDataURL(file);
				// 		reader.onload = () => {
				// 			const img = document.createElement('img');
				// 			img.src = reader.result;
				// 			img.onload = () => {
				// 				const canvas = document.createElement('canvas');
				// 				let width = img.naturalWidth;
				// 				let height = img.naturalHeight;
				// 				let ratio = width * height / 180000;
				// 				if(ratio > 1) {
				// 					ratio = Math.sqrt(ratio);
				// 				}else{
				// 					ratio = 1;
				// 				}
				// 				width /= ratio;
				// 				height /= ratio;
				// 				canvas.width = width;
				// 				canvas.height = height;
				//
				// 				const ctx = canvas.getContext('2d');
				// 				ctx.drawImage(img, 0, 0, width, height);
				// 				canvas.toBlob(resolve);
				// 			};
				// 		};
				// 	});
				// }
			},
			onChange: (info) => {
				const { fileList } = info;
				_setFileList([...fileList]);
				const list = [];
				if(fileList && fileList.length > 0) {
					fileList.map(item => {
						if(item && item.src) {
							list.push(item.src);
						}else if(item && item.response && item.response.data && item.response.ok == 0 && item.response.data.src) {
							list.push(item.response.data.src);
						}
					});
				}
				changeHandle && changeHandle(list);
			},
			onDrop: (event) => {
				console.log('onDrop', event);
			},
			onRemove: (target) => {
				if(target) {
					return new Promise((resolve, reject) => {
						Modal.confirm({
							title: '删除的数据将无法找回，请慎重操作！',
							icon: <WarningOutlined style={{ color: '#E45357' }} />,
							// content: 'Bla bla ...',
							okText: '确认删除',
							cancelText: '取消',
							onOk: () => {
								const list = _fileList.filter(item => item && item.uid != target.uid);
								_setFileList(list);
								resolve();
							},
							onCancel: () => {
								reject();
							},
						});
					});
				}
			},
			onPreview: (file) => {
				const src = file.src || file?.response?.data?.src;
				window.open(CONST.imgPrefix + src);
			}
			// previewFile: (file) => {
			// 	console.log('Your upload file:', file);
			// }
		}}>
			{_fileList.length >= pictureLength ? null : uploadButton}
		</Upload>
	)
};

export default CompModel;
