/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';

import * as Utils from '../../../../common/utils';

import './index.less';
import * as CONFIG from '../../config';

// images
import IMG_01 from './images/导游信息_01.jpg';
import IMG_02 from './images/导游信息_02.jpg';
import IMG_03 from './images/导游信息_03.jpg';
import IMG_04 from './images/导游信息_04.jpg';
import IMG_05 from './images/导游信息_05.jpg';
import IMG_06 from './images/导游信息_06.jpg';
import IMG_07 from './images/导游信息_07.jpg';
import IMG_08 from './images/导游信息_08.jpg';
import IMG_09 from './images/导游信息_09.jpg';
import IMG_10 from './images/导游信息_10.jpg';
import IMG_New from './images/guider_new.jpg'

const LIST = [ IMG_New ];

const PageModel = props => {
	return (
		<section className="zhujiajiao-guider-container" style={{ minHeight: window.innerHeight }}>
			{
				LIST.map((img, idx) => <img src={img} key={idx} />)
			}
		</section>
	);
};

export default PageModel;
