/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import Page from '../../components/page';

import './cases-detail.less';
import IMG_BANNER1 from './images/banner/t1.jpg';
import IMG_BANNER2 from './images/banner/t2.jpg';
import IMG_BANNER3 from './images/banner/t3.jpg';
import IMG_BANNER4 from './images/banner/t4.jpg';
import IMG_LOGO from './images/logo-black.png';

const PageContainer = props => {
	return (
  <section className="temp-container">
    <div className="temp-header" style={{ position: 'relative', backgroundColor: '#ffffff', height: 56 }}>
      <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link className="logo" style={{ backgroundImage: `url(${IMG_LOGO})`, width: 36, height: 36 }} to="/home" />
        <div className="tabs">
          <Link className="tab-item" to="/cases" style={{ textDecoration: 'underline' }}>客户案例</Link>
          <Link className="tab-item" to="/aboutus">公司简介</Link>
          <Link className="tab-item" to="/contact">联系我们</Link>
          {/*<Link className="tab-item" to="">技术支持</Link>*/}
        </div>
      </div>
    </div>
    <section className="temp-body" style={{ paddingBottom: 56 }}>
      <div className="content-banner" style={{ backgroundImage: `url(${IMG_BANNER2})` }} />
      <div className="temp-wrapper">
        <div className="content-title">项目一的名字简短扼要</div>
        <img className="content-cover" src={IMG_BANNER1} />
        <div className="content-message">
          <label>客户简介</label>
          <span className="msg">新东方·优答是一款针对初高中及大学生的高效英语学习个性化移动APP产品，以“题”为中心，优答充分了解学生练题场景和需求，依托新东方多年积累的高质量知识库及资深老师的教育经验心得，结合互联网搜索，个性化推荐，OCR等相关技术及优秀的移动交互体验，向用户提供学习能力评估，在线智能练题学习，拍照扫题，在线和老师/学霸互动问答等功能。</span>
        </div>
        <div className="content-message">
          <label>项目类型</label>
          <span className="msg">综合性教育集团</span>
        </div>
        <div className="content-message">
          <label>项目简介</label>
          <span className="msg">项目一的描述，简短扼要详细，突出重点，可以是摘要啥的，一般是两到三行为最佳，可以往下显示，现实不下的省略号显示，一般会控制字数</span>
        </div>
      </div>
    </section>
    <div className="temp-footer">
      © 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 沪ICP备19019249号-1
    </div>
  </section>
	);
};

export default PageContainer;