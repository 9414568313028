/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './imagesDetail.less';

import PageBack from './pageBackComp';

// images
import IMG_PINPAI_TITLE from './images/pinpai/title.png';

import IMG_PINPAI_鹤老汇_TITLE from './images/pinpai/鹤老汇/title.png';
import IMG_PINPAI_鹤老汇_1 from './images/pinpai/鹤老汇/1.jpg';
import IMG_PINPAI_鹤老汇_2 from './images/pinpai/鹤老汇/2.jpg';
import IMG_PINPAI_鹤老汇_3 from './images/pinpai/鹤老汇/2020.10.1环境整治大讨论.jpg';
import IMG_PINPAI_鹤老汇_4 from './images/pinpai/鹤老汇/2020.11.5卫生院助力膏方制作讲座.jpg';
import IMG_PINPAI_鹤老汇_5 from './images/pinpai/鹤老汇/2020.11.5卫生院助力艾条制作活动.jpg';
import IMG_PINPAI_鹤老汇_6 from './images/pinpai/鹤老汇/2020.12.12收看《初心 使命》专题片.jpg';
import IMG_PINPAI_鹤老汇_7 from './images/pinpai/鹤老汇/2020.4.23社会服务中心助力旧衣改造活动.jpg';
import IMG_PINPAI_鹤老汇_8 from './images/pinpai/鹤老汇/2020.5.15妇联国际家庭日观看直播.jpg';
import IMG_PINPAI_鹤老汇_9 from './images/pinpai/鹤老汇/2020.6.17妇女议事会.jpg';
import IMG_PINPAI_鹤老汇_10 from './images/pinpai/鹤老汇/2020.6.23端午节包粽子活动.jpg';
import IMG_PINPAI_鹤老汇_11 from './images/pinpai/鹤老汇/2020.8.16二居为老人送清凉活动.jpg';
import IMG_PINPAI_鹤老汇_12 from './images/pinpai/鹤老汇/2020.8.9端午节包粽子活动.jpg';
import IMG_PINPAI_鹤老汇_13 from './images/pinpai/鹤老汇/2020.9.18 老年人关怀服务 老年出厨房.jpg';
import IMG_PINPAI_鹤老汇_14 from './images/pinpai/鹤老汇/3.jpg';
import IMG_PINPAI_鹤老汇_15 from './images/pinpai/鹤老汇/4.jpg';

import IMG_PINPAI_楼长制_TITLE from './images/pinpai/楼长制/title.png';
import IMG_PINPAI_楼长制_1 from './images/pinpai/楼长制/1.jpg';
import IMG_PINPAI_楼长制_2 from './images/pinpai/楼长制/10.jpg';
import IMG_PINPAI_楼长制_3 from './images/pinpai/楼长制/11.jpg';
import IMG_PINPAI_楼长制_4 from './images/pinpai/楼长制/12.jpg';
import IMG_PINPAI_楼长制_5 from './images/pinpai/楼长制/13.jpg';
import IMG_PINPAI_楼长制_6 from './images/pinpai/楼长制/14.jpg';
import IMG_PINPAI_楼长制_7 from './images/pinpai/楼长制/2.jpg';
import IMG_PINPAI_楼长制_8 from './images/pinpai/楼长制/2020.10.1环境整治大讨论.jpg';
import IMG_PINPAI_楼长制_9 from './images/pinpai/楼长制/2020.4.22垃圾分类两网融合活动.jpg';
import IMG_PINPAI_楼长制_10 from './images/pinpai/楼长制/2020.4.23社会服务中心助力旧衣改造活动.jpg';
import IMG_PINPAI_楼长制_11 from './images/pinpai/楼长制/2020.4.30垃圾分类整治.jpg';
import IMG_PINPAI_楼长制_12 from './images/pinpai/楼长制/2020.7.21家风家训活动.jpg';
import IMG_PINPAI_楼长制_13 from './images/pinpai/楼长制/2020.8.4小手拉大手党员活动.jpg';
import IMG_PINPAI_楼长制_14 from './images/pinpai/楼长制/3.jpg';
import IMG_PINPAI_楼长制_15 from './images/pinpai/楼长制/4.jpg';
import IMG_PINPAI_楼长制_16 from './images/pinpai/楼长制/5.jpg';
import IMG_PINPAI_楼长制_17 from './images/pinpai/楼长制/6.jpg';
import IMG_PINPAI_楼长制_18 from './images/pinpai/楼长制/7.jpg';
import IMG_PINPAI_楼长制_19 from './images/pinpai/楼长制/8.jpg';
import IMG_PINPAI_楼长制_20 from './images/pinpai/楼长制/9.jpg';

import IMG_PINPAI_思享荟_TITLE from './images/pinpai/思享荟/title.png';
import IMG_PINPAI_思享荟_1 from './images/pinpai/思享荟/2020.10.1四史集中学习交流会.jpg';
import IMG_PINPAI_思享荟_2 from './images/pinpai/思享荟/2020.5.14五月主题党日.jpg';
import IMG_PINPAI_思享荟_3 from './images/pinpai/思享荟/2020.5.8花桥社区 二居委共建.jpg';
import IMG_PINPAI_思享荟_4 from './images/pinpai/思享荟/2020.6.24六月主题党日.jpg';
import IMG_PINPAI_思享荟_5 from './images/pinpai/思享荟/2020.6.29花桥居委二居委共建.jpg';
import IMG_PINPAI_思享荟_6 from './images/pinpai/思享荟/2020.7.10《治国理政》集中学习会.jpg';
import IMG_PINPAI_思享荟_7 from './images/pinpai/思享荟/2020.7.12七月主题党日.jpg';
import IMG_PINPAI_思享荟_8 from './images/pinpai/思享荟/2020.7.17安花白共建 参观花桥景点.jpg';
import IMG_PINPAI_思享荟_9 from './images/pinpai/思享荟/2020.7.1情景党课.jpg';
import IMG_PINPAI_思享荟_10 from './images/pinpai/思享荟/2020.8.10学习《习近平谈治国理政》第三卷.jpg';
import IMG_PINPAI_思享荟_11 from './images/pinpai/思享荟/2020.8.21 党员环境整治活动.jpg';
import IMG_PINPAI_思享荟_12 from './images/pinpai/思享荟/2020.8.4小手拉大手党员活动.jpg';
import IMG_PINPAI_思享荟_13 from './images/pinpai/思享荟/2020.9.28区域化党建志愿服务活动.jpg';

import IMG_PINPAI_相聚里_TITLE from './images/pinpai/相聚里/title.png';
import IMG_PINPAI_相聚里_1 from './images/pinpai/相聚里/1.jpg';
import IMG_PINPAI_相聚里_2 from './images/pinpai/相聚里/2.jpg';
import IMG_PINPAI_相聚里_3 from './images/pinpai/相聚里/2020.10.10司法助力民法典教育讲座.jpg';
import IMG_PINPAI_相聚里_4 from './images/pinpai/相聚里/2020.10.1迎国庆 庆中秋 主题活动.jpg';
import IMG_PINPAI_相聚里_5 from './images/pinpai/相聚里/2020.10.21文体助力沪剧赏析活动.jpg';
import IMG_PINPAI_相聚里_6 from './images/pinpai/相聚里/2020.11.5卫生院助力膏方制作讲座.jpg';
import IMG_PINPAI_相聚里_7 from './images/pinpai/相聚里/2020.11.5卫生院助力艾条制作活动.jpg';
import IMG_PINPAI_相聚里_8 from './images/pinpai/相聚里/2020.11.7“培育好家风 传承好家训”主题亲子活动.jpg';
import IMG_PINPAI_相聚里_9 from './images/pinpai/相聚里/2020.12.03卫生院助力艾滋病讲座.jpg';
import IMG_PINPAI_相聚里_10 from './images/pinpai/相聚里/2020.5.15妇联国际家庭日观看直播.jpg';
import IMG_PINPAI_相聚里_11 from './images/pinpai/相聚里/2020.6.23端午节包粽子活动.jpg';
import IMG_PINPAI_相聚里_12 from './images/pinpai/相聚里/2020.6.27端午节未成年人看书活动.jpg';
import IMG_PINPAI_相聚里_13 from './images/pinpai/相聚里/2020.6.5邻里矛盾调解.jpg';
import IMG_PINPAI_相聚里_14 from './images/pinpai/相聚里/2020.7.10法律进社区 法制宣传活动.jpg';
import IMG_PINPAI_相聚里_15 from './images/pinpai/相聚里/2020.7.21公益书法班.jpg';
import IMG_PINPAI_相聚里_16 from './images/pinpai/相聚里/2020.7.21家风家训活动.jpg';
import IMG_PINPAI_相聚里_17 from './images/pinpai/相聚里/2020.7.8未成年人暑假安全知识培训.jpg';
import IMG_PINPAI_相聚里_18 from './images/pinpai/相聚里/2020.8.11未成年人制作飞机活动.jpg';
import IMG_PINPAI_相聚里_19 from './images/pinpai/相聚里/2020.8.17手绘复古伞活动.jpg';
import IMG_PINPAI_相聚里_20 from './images/pinpai/相聚里/2020.8.19垃圾分类主题创意画活动.jpg';
import IMG_PINPAI_相聚里_21 from './images/pinpai/相聚里/2020.8.21辖区未成年人做游戏.jpg';
import IMG_PINPAI_相聚里_22 from './images/pinpai/相聚里/2020.8.29未成年人随手捡助力创全.jpg';
import IMG_PINPAI_相聚里_23 from './images/pinpai/相聚里/2020.8.29未成年人随手捡助力垃圾分类.jpg';
import IMG_PINPAI_相聚里_24 from './images/pinpai/相聚里/2020.8.7公益书法班.jpg';
import IMG_PINPAI_相聚里_25 from './images/pinpai/相聚里/2020.8.7未成年人心理辅导讲座.jpg';
import IMG_PINPAI_相聚里_26 from './images/pinpai/相聚里/2020.8.9端午节包粽子活动.jpg';
import IMG_PINPAI_相聚里_27 from './images/pinpai/相聚里/2020.9.18 老年人关怀服务 老年出厨房.jpg';
import IMG_PINPAI_相聚里_28 from './images/pinpai/相聚里/3.jpg';
import IMG_PINPAI_相聚里_29 from './images/pinpai/相聚里/4.jpg';
import IMG_PINPAI_相聚里_30 from './images/pinpai/相聚里/5.jpg';
import IMG_PINPAI_相聚里_31 from './images/pinpai/相聚里/6.jpg';
import IMG_PINPAI_相聚里_32 from './images/pinpai/相聚里/7.jpg';
import IMG_PINPAI_相聚里_33 from './images/pinpai/相聚里/8.jpg';
import IMG_PINPAI_相聚里_34 from './images/pinpai/相聚里/9.jpg';

import IMG_PINPAI_直通车_TITLE from './images/pinpai/直通车/title.png';
import IMG_PINPAI_直通车_1 from './images/pinpai/直通车/1.jpg';
import IMG_PINPAI_直通车_2 from './images/pinpai/直通车/2.jpg';
import IMG_PINPAI_直通车_3 from './images/pinpai/直通车/2020.10.10司法助力民法典教育讲座.jpg';
import IMG_PINPAI_直通车_4 from './images/pinpai/直通车/2020.10.21文体助力沪剧赏析活动.jpg';
import IMG_PINPAI_直通车_5 from './images/pinpai/直通车/2020.11.5卫生院助力膏方制作讲座.jpg';
import IMG_PINPAI_直通车_6 from './images/pinpai/直通车/2020.11.5卫生院助力艾条制作活动.jpg';
import IMG_PINPAI_直通车_7 from './images/pinpai/直通车/2020.12.03卫生院助力艾滋病讲座.jpg';
import IMG_PINPAI_直通车_8 from './images/pinpai/直通车/2020.12.18环卫所助力二居清理路面.jpg';
import IMG_PINPAI_直通车_9 from './images/pinpai/直通车/2020.4.14卫健委联合执法.jpg';
import IMG_PINPAI_直通车_10 from './images/pinpai/直通车/2020.4.16老街乱拉线联席会议.jpg';
import IMG_PINPAI_直通车_11 from './images/pinpai/直通车/2020.4.23社会服务中心助力旧衣改造活动.jpg';
import IMG_PINPAI_直通车_12 from './images/pinpai/直通车/2020.6.15快反支队助二居排水.jpg';
import IMG_PINPAI_直通车_13 from './images/pinpai/直通车/2020.6.28雨污分流会议.jpg';
import IMG_PINPAI_直通车_14 from './images/pinpai/直通车/2020.6.29赵屯居委 二居委社区工作交流.jpg';
import IMG_PINPAI_直通车_15 from './images/pinpai/直通车/2020.8.7拆违办助力二居公益书法班.jpg';
import IMG_PINPAI_直通车_16 from './images/pinpai/直通车/3.jpg';
import IMG_PINPAI_直通车_17 from './images/pinpai/直通车/4.jpg';
import IMG_PINPAI_直通车_18 from './images/pinpai/直通车/5.jpg';

const _STORE = {
	pinpai1: {
		title: IMG_PINPAI_鹤老汇_TITLE,
		list: [
			{ img: IMG_PINPAI_鹤老汇_1, text: '1' },
			{ img: IMG_PINPAI_鹤老汇_2, text: '2' },
			{ img: IMG_PINPAI_鹤老汇_3, text: '2020.10.1环境整治大讨论' },
			{ img: IMG_PINPAI_鹤老汇_4, text: '2020.11.5卫生院助力膏方制作讲座' },
			{ img: IMG_PINPAI_鹤老汇_5, text: '2020.11.5卫生院助力艾条制作活动' },
			{ img: IMG_PINPAI_鹤老汇_6, text: '2020.12.12收看《初心 使命》专题片' },
			{ img: IMG_PINPAI_鹤老汇_7, text: '2020.4.23社会服务中心助力旧衣改造活动' },
			{ img: IMG_PINPAI_鹤老汇_8, text: '2020.5.15妇联国际家庭日观看直播' },
			{ img: IMG_PINPAI_鹤老汇_9, text: '2020.6.17妇女议事会' },
			{ img: IMG_PINPAI_鹤老汇_10, text: '2020.6.23端午节包粽子活动' },
			{ img: IMG_PINPAI_鹤老汇_11, text: '2020.8.16二居为老人送清凉活动' },
			{ img: IMG_PINPAI_鹤老汇_12, text: '2020.8.9端午节包粽子活动' },
			{ img: IMG_PINPAI_鹤老汇_13, text: '2020.9.18 老年人关怀服务 老年出厨房' },
			{ img: IMG_PINPAI_鹤老汇_14, text: '3' },
			{ img: IMG_PINPAI_鹤老汇_15, text: '4' },
		]
	},
	pinpai2: {
		title: IMG_PINPAI_楼长制_TITLE,
		list: [
			{ img: IMG_PINPAI_楼长制_1, text: '1' },
			{ img: IMG_PINPAI_楼长制_2, text: '10' },
			{ img: IMG_PINPAI_楼长制_3, text: '11' },
			{ img: IMG_PINPAI_楼长制_4, text: '12' },
			{ img: IMG_PINPAI_楼长制_5, text: '13' },
			{ img: IMG_PINPAI_楼长制_6, text: '14' },
			{ img: IMG_PINPAI_楼长制_7, text: '2' },
			{ img: IMG_PINPAI_楼长制_8, text: '2020.10.1环境整治大讨论' },
			{ img: IMG_PINPAI_楼长制_9, text: '2020.4.22垃圾分类两网融合活动' },
			{ img: IMG_PINPAI_楼长制_10, text: '2020.4.23社会服务中心助力旧衣改造活动' },
			{ img: IMG_PINPAI_楼长制_11, text: '2020.4.30垃圾分类整治' },
			{ img: IMG_PINPAI_楼长制_12, text: '2020.7.21家风家训活动' },
			{ img: IMG_PINPAI_楼长制_13, text: '2020.8.4小手拉大手党员活动' },
			{ img: IMG_PINPAI_楼长制_14, text: '3' },
			{ img: IMG_PINPAI_楼长制_15, text: '4' },
			{ img: IMG_PINPAI_楼长制_16, text: '5' },
			{ img: IMG_PINPAI_楼长制_17, text: '6' },
			{ img: IMG_PINPAI_楼长制_18, text: '7' },
			{ img: IMG_PINPAI_楼长制_19, text: '8' },
			{ img: IMG_PINPAI_楼长制_20, text: '9' },

		]
	},
	pinpai3: {
		title: IMG_PINPAI_思享荟_TITLE,
		list: [
			{ img: IMG_PINPAI_思享荟_1, text: '2020.10.1四史集中学习交流会' },
			{ img: IMG_PINPAI_思享荟_2, text: '2020.5.14五月主题党日' },
			{ img: IMG_PINPAI_思享荟_3, text: '2020.5.8花桥社区 二居委共建' },
			{ img: IMG_PINPAI_思享荟_4, text: '2020.6.24六月主题党日' },
			{ img: IMG_PINPAI_思享荟_5, text: '2020.6.29花桥居委二居委共建' },
			{ img: IMG_PINPAI_思享荟_6, text: '2020.7.10《治国理政》集中学习会' },
			{ img: IMG_PINPAI_思享荟_7, text: '2020.7.12七月主题党日' },
			{ img: IMG_PINPAI_思享荟_8, text: '2020.7.17安花白共建 参观花桥景点' },
			{ img: IMG_PINPAI_思享荟_9, text: '2020.7.1情景党课' },
			{ img: IMG_PINPAI_思享荟_10, text: '2020.8.10学习《习近平谈治国理政》第三卷' },
			{ img: IMG_PINPAI_思享荟_11, text: '2020.8.21 党员环境整治活动' },
			{ img: IMG_PINPAI_思享荟_12, text: '2020.8.4小手拉大手党员活动' },
			{ img: IMG_PINPAI_思享荟_13, text: '2020.9.28区域化党建志愿服务活动' },
		]
	},
	pinpai4: {
		title: IMG_PINPAI_相聚里_TITLE,
		list: [
			{ img: IMG_PINPAI_相聚里_1, text: '1' },
			{ img: IMG_PINPAI_相聚里_2, text: '2' },
			{ img: IMG_PINPAI_相聚里_3, text: '2020.10.10司法助力民法典教育讲座' },
			{ img: IMG_PINPAI_相聚里_4, text: '2020.10.1迎国庆 庆中秋 主题活动' },
			{ img: IMG_PINPAI_相聚里_5, text: '2020.10.21文体助力沪剧赏析活动' },
			{ img: IMG_PINPAI_相聚里_6, text: '2020.11.5卫生院助力膏方制作讲座' },
			{ img: IMG_PINPAI_相聚里_7, text: '2020.11.5卫生院助力艾条制作活动' },
			{ img: IMG_PINPAI_相聚里_8, text: '2020.11.7“培育好家风 传承好家训”主题亲子活动' },
			{ img: IMG_PINPAI_相聚里_9, text: '2020.12.03卫生院助力艾滋病讲座' },
			{ img: IMG_PINPAI_相聚里_10, text: '2020.5.15妇联国际家庭日观看直播' },
			{ img: IMG_PINPAI_相聚里_11, text: '2020.6.23端午节包粽子活动' },
			{ img: IMG_PINPAI_相聚里_12, text: '2020.6.27端午节未成年人看书活动' },
			{ img: IMG_PINPAI_相聚里_13, text: '2020.6.5邻里矛盾调解' },
			{ img: IMG_PINPAI_相聚里_14, text: '2020.7.10法律进社区 法制宣传活动' },
			{ img: IMG_PINPAI_相聚里_15, text: '2020.7.21公益书法班' },
			{ img: IMG_PINPAI_相聚里_16, text: '2020.7.21家风家训活动' },
			{ img: IMG_PINPAI_相聚里_17, text: '2020.7.8未成年人暑假安全知识培训' },
			{ img: IMG_PINPAI_相聚里_18, text: '2020.8.11未成年人制作飞机活动' },
			{ img: IMG_PINPAI_相聚里_19, text: '2020.8.17手绘复古伞活动' },
			{ img: IMG_PINPAI_相聚里_20, text: '2020.8.19垃圾分类主题创意画活动' },
			{ img: IMG_PINPAI_相聚里_21, text: '2020.8.21辖区未成年人做游戏' },
			{ img: IMG_PINPAI_相聚里_22, text: '2020.8.29未成年人随手捡助力创全' },
			{ img: IMG_PINPAI_相聚里_23, text: '2020.8.29未成年人随手捡助力垃圾分类' },
			{ img: IMG_PINPAI_相聚里_24, text: '2020.8.7公益书法班' },
			{ img: IMG_PINPAI_相聚里_25, text: '2020.8.7未成年人心理辅导讲座' },
			{ img: IMG_PINPAI_相聚里_26, text: '2020.8.9端午节包粽子活动' },
			{ img: IMG_PINPAI_相聚里_27, text: '2020.9.18 老年人关怀服务 老年出厨房' },
			{ img: IMG_PINPAI_相聚里_28, text: '3' },
			{ img: IMG_PINPAI_相聚里_29, text: '4' },
			{ img: IMG_PINPAI_相聚里_30, text: '5' },
			{ img: IMG_PINPAI_相聚里_31, text: '6' },
			{ img: IMG_PINPAI_相聚里_32, text: '7' },
			{ img: IMG_PINPAI_相聚里_33, text: '8' },
			{ img: IMG_PINPAI_相聚里_34, text: '9' },
		]
	},
	pinpai5: {
		title: IMG_PINPAI_直通车_TITLE,
		list: [
			{ img: IMG_PINPAI_直通车_1, text: '1' },
			{ img: IMG_PINPAI_直通车_2, text: '2' },
			{ img: IMG_PINPAI_直通车_3, text: '2020.10.10司法助力民法典教育讲座' },
			{ img: IMG_PINPAI_直通车_4, text: '2020.10.21文体助力沪剧赏析活动' },
			{ img: IMG_PINPAI_直通车_5, text: '2020.11.5卫生院助力膏方制作讲座' },
			{ img: IMG_PINPAI_直通车_6, text: '2020.11.5卫生院助力艾条制作活动' },
			{ img: IMG_PINPAI_直通车_7, text: '2020.12.03卫生院助力艾滋病讲座' },
			{ img: IMG_PINPAI_直通车_8, text: '2020.12.18环卫所助力二居清理路面' },
			{ img: IMG_PINPAI_直通车_9, text: '2020.4.14卫健委联合执法' },
			{ img: IMG_PINPAI_直通车_10, text: '2020.4.16老街乱拉线联席会议' },
			{ img: IMG_PINPAI_直通车_11, text: '2020.4.23社会服务中心助力旧衣改造活动' },
			{ img: IMG_PINPAI_直通车_12, text: '2020.6.15快反支队助二居排水' },
			{ img: IMG_PINPAI_直通车_13, text: '2020.6.28雨污分流会议' },
			{ img: IMG_PINPAI_直通车_14, text: '2020.6.29赵屯居委 二居委社区工作交流' },
			{ img: IMG_PINPAI_直通车_15, text: '2020.8.7拆违办助力二居公益书法班' },
			{ img: IMG_PINPAI_直通车_16, text: '3' },
			{ img: IMG_PINPAI_直通车_17, text: '4' },
			{ img: IMG_PINPAI_直通车_18, text: '5' },
		]
	}
};

const PageModel = props => {
	const [ data, setData ] = useState(null);
	const getList = () => {
		const { id } = props.match.params;
		setData(_STORE[id] || null);
	};
	useEffect(() => {
		getList();
	}, [ props.match.params ]);

	const [ contentIndex, setContentIndex ] = useState(0);
	const [ bigFlag, setBigFlag ] = useState(null);

	if(!data) return null;
	const imgWidth = window.innerWidth * 0.62;

	return (
		<section className="baihe-images-detail-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="title" style={{ backgroundImage: `url(${data.title})` }} />
			<div className="entrances">
				{
					data.list && data.list.length > 0 && data.list.map((item, idx) => {
						return (
							<div className="item" key={idx} style={{ backgroundImage: `url("${item.img}")` }} onClick={() => {
								setBigFlag(true);
								setContentIndex(idx);
							}} />
						)
					})
				}
			</div>

			{
				!!bigFlag && <div className="big-image-wrapper">
					<div className="big-image" style={{ width: imgWidth }}>
						<div className="close" onClick={() => setBigFlag(null)} />
						{
							data.list && data.list.length > 0 && <div {...{
								className: 'imgswrapper',
								style: {
									width: imgWidth * data.list.length + 'px',
									height: window.innerHeight * 0.75 + 'px',
									transform: `translateX(-${contentIndex * imgWidth}px)`
								},
							}}>
								{
									data.list.map((item, idx) => {
										return <div {...{
											className: 'imgitem',
											style: { backgroundImage: `url('${item.img}')`, width: imgWidth },
											key: idx
										}}>{ item.text && isNaN(item.text) && <span>{item.text}</span>}</div>
									})
								}
							</div>
						}
					</div>
					<div className={`imgleft${contentIndex <= 0 ? ' disabled' : ''}`} onClick={() => {
						setContentIndex(Math.max(0, contentIndex - 1));
					}} />
					<div className={`imgright${ contentIndex >= data.list.length - 1 ? ' disabled' : ' '}`} onClick={() => {
						setContentIndex(Math.min(contentIndex + 1, data.list.length - 1));
					}} />
				</div>
			}
		</section>
	);
};

export default PageModel;