/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import './home.less';
import * as Request from '../../common/request';
import { HOMEDATA } from './data';
import IMG_BANNER from './images/banner.jpg';

const PageModel = props => {
	const [ list ,setList ] = useState([]);
	const getList = async () => {
		const list = [];
		const result = await Request.get({
			url: '/lzbg/list'
		});
		if(!result) return;
		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ data, setData ] = useState({});
	const getData = () => {
		const data = HOMEDATA;
		setData(data);
	};
	useEffect(() => {
		getData();

		document.title = HOMEDATA.title;
	}, [123]);

	return (
		<div className="lzbg-home-wrapper" style={{ minHeight: window.innerHeight }}>
			<div className="bg">
				<img src={IMG_BANNER} />
			</div>
			<div className="intro">
				{
					data.content && data.content.length > 0 && data.content.map((c, idx) => {
						return (
							<c.type key={idx} className={c.noindent && 'noindent' || ''}>{c.htext && <b>{c.htext}</b> || null }{c.text}</c.type>
						)
					})
				}
			</div>
			<div className="list">
				{
					list && list.length > 0 && list.map((lst, idx) => {
						return (
							<div className="item" key={idx} onClick={() => {
								{/*window.location.href = `/lzbg2020/detail/${lst.name}${lst.ext}`;*/}
								window.location.href = `/resource/lvzhibaogao/${lst.name}${lst.ext}`;
							}}>
								<span className="title">{lst.name}</span>
								<span className="see">查看</span>
							</div>
						)
					})
				}
			</div>
		</div>
	);
};

export default PageModel;