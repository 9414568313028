/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './redstory.less';

// components
import PageBack from './pageBackComp';


// images
import IMG_ENTRANCE_1 from './images/home-entrance-1.png';

const PageModel = props => {
	return (
		<section className="baihe-red-story-container" style={{ height: window.innerHeight }}>
			<PageBack />
		</section>
	);
};

export default PageModel;
