/**
 * Created by jimmy on 2020/12/30.
 */
export const HOMEDATA = {
	title: '“谁执法谁普法”履职报告公开啦！',
	content: [
		{ type: 'p', text: '“谁执法谁普法”履职报告公开啦！', noindent: true },
		{ type: 'p', text: '自2019年起，我区连续两年开展了“谁执法谁普法”履职报告评议活动，现场评议会上，分别选取个别单位作现场履职情况报告，并邀请人大代表、政协代表、法学专家代表、律师代表、新闻媒体代表进行了针对性的点评，活动的开展对区内国家机关“谁执法谁普法”“谁主管谁普法”“谁服务谁普法”普法责任制落实起到了良好的推动作用。' },
		{ type: 'p', text: '截至目前，区市场监管局、区卫健委、区人民检察院、区人社局、区生态环境局、区农业农村委、夏阳街道、徐泾镇、赵巷镇9家单位已现场作“谁执法谁普法”履职情况报告，明年我们仍将选取若干单位进行现场评议并采取逐年滚动的方式在“八五”普法期间覆盖全区所有国家机关。' },
		{ type: 'p', text: '在履职评议的现场，小编认真聆听了履职情况报告内容，可谓举措丰富、干货满满，现场其他单位虽没有全部发言，但是旁听过程中也一定得到了不少收获和启迪，那么他们“谁执法谁普法”“谁主管谁普法”“谁服务谁普法”履职情况又是怎样的呢？' },
		{ type: 'p', text: '今天，我们就来公开他们的履职情况报告，让我们一起欣赏全区普法宣传工作的满园春色。' },
	]
};

export const DATA = [
	{
		title: '区财政局2020年“谁执法谁普法”履职报告',
		content: [
			{ type: 'p', text: '区法宣办：', noindent: true },
			{ type: 'p', text: '区财政局普法工作在区委、区府、市局的正确领导下，通过强化组织领导，完善制度机制，明确工作责任，严格纪律要求等措施，落实普法相关工作，实现普法宣传更加深入人心、法制教育内容更加具体化、范围更加广泛、重点更加突出、监管更加到位等多重目标，有效提升了我局财政干部的法律素养，全面依法理财、打造法制财政工作发挥了重要作用。根据区法宣办《关于报送2020年普法依法治理工作总结及2021年普法工作计划的通知》，本局进行了认真自查（87分），现将有关情况报告如下：' },

			{ type: 'h3', text: '一、一年来主要工作' },
			{ type: 'h4', text: '（一）加强组织领导，构建完善工作格局' },
			{ type: 'p', htext: '一是完善组织领导。', text: '局党组高度重视普法工作，始终强调要以普法为契机促进队伍建设、推动执法工作。调整普法领导小组，由局长任组长，分管副局长为副组长，各科室(中心）负责人为成员，形成了一把手负总责、分管领导具体抓、中层负责人积极落实的齐抓共管的良好工作格局。' },
			{ type: 'p', htext: '二是完善工作制度。', text: '制定青浦区财政局党组中心组学习制度、干部职工学习制度、青浦区财政局工作人员年度考核实施办法、机关工作人员问责办法、行政执法过错责任追究办法、行政审批事项办理指南和业务手册等相关制度，不断推进依法行政教育工作规范化、制度化。修订和完善《青浦区财政局内部控制规范（试行）》，梳理内控简易流程，为我局依法行政工作的顺利实施提供了有力的保障。' },
			{ type: 'p', htext: '三是强化考核管理。', text: '我局将依法行政工作和普法教育的各项工作列入全局年度目标管理考核内容，实行责任制管理，对考核不合格的科室，取消其参评先进的资格。通过这些措施，逐步使普法宣传教育工作走上规范化、制度化的轨道，全局干部职工的精神面貌进一步振奋，法制意识和执法水平进一步提高。' },
			{ type: 'p', htext: '四是夯实工作基础。', text: '组建“青财讲师团”，组织开展财经纪律宣传教育。为加强法制宣传学习，专门购买《领导干部法治读本》、《公务员以案释法读本》等书籍、法宣挂图，并邀请法律专家对全体人员开展法律知识培训，提高工作人员法治理念和法律素养，确保财政业务工作依法、优质、高效。' },
			{ type: 'h4', htext: '（二）坚持守法用法，深入推进依法理财' },
			{ type: 'p', htext: '一是依法规范行政行为。', text: '根据区委统一部署和要求，完成机构职责转隶工作。同时在对照梳理原三定及部门职责的基础上，结合我局最新工作要求和任务，重新制定完善“三定”规定并报上级部门批准。梳理了行政执法主体、执法事项、执法依据。推行权力清单、收费清单、负面清单管理制度并实行动态管理，深化“互联网+监督”平台管理，完善执法人员库，认领监管事项目录清单，制订随机抽查事项清单和联合检查计划。' },
			{ type: 'p', htext: '二是稳步推进财政信息公开。', text: '深入推进财政预算信息公开。将项目预算公开范围延伸由区级至镇级，积极推进重点项目预算绩效目标信息公开工作，通过下发通知、加强指导等方式推进本区预算公开工作。选取部分镇和部门作为检查对象，开展2020年预算公开情况检查，对检查中发现的问题进行总结分析，要求相关检查对象及时落实整改，促进预算信息公开的透明度、规范化。组织局各支出科室和各镇财政所对预决算信息公开情况开展全面检查。明确局办公室为局政务公开工作机构，及时、准确地公开有关信息；及时、规范落实信息公开申请答复。' },
			{ type: 'p', htext: '三是不断完善规范性文件制发程序。', text: '严格规范性文件制定和公布程序，全面推行规范性文件公开征求意见制度；完善规范性文件动态管理，推进规范性文件有效期管理制度，严格做到及时清理、即时更新。' },
			{ type: 'p', htext: '四是推进财政行政审批制度改革。', text: '按照市、区进一步贯彻落实“放管服”改革及相关要求，优化代理记账审批服务，加强事中事后监管。简化代理记账资格申请材料。删除有关会计从业资格的规定，拓宽主管代理记账业务负责人条件，明确负责人申请条件为从事会计工作不少于三年。简化代理记账审批流程。坚决贯彻执行“双减半”，实现零材料书面申报，大力压缩代理记账审批时限，审批时限从20个工作日压缩到3个工作日。强化事中事后监管。按照《上海市代理记账管理实施办法》（沪财发〔2019〕4号）文件精神，对取得代理记账机构许可证的单位二个月内进行复查。联合区市场监督局，选取3家2020年新设立的代理记账机构开展联合实地检查，对机构申请材料的真实性进行核查。' },
			{ type: 'p', htext: '五是加强行政执法决定法制审核。', text: '认真贯彻落实《行政执法决定法制审核办法》，在作出行政执法决定前，由办公室进行合法性、合理性审核，确保具体行政行为严格按照法律、法规、规章，保护公民、法人和其他组织合法权益。2020年，我局涉及行政处罚案件2件，行政裁决案件6件，未发生一起行政诉讼案件、行政赔偿案件。' },

			{ type: 'h3', text: '二、存在问题' },
			{ type: 'p', text: '虽然本局普法做了大量工作，取得了一些成绩，但离上级要求和标准也还存在一些差距。一是宣传力度有待加强。财政部门作为区政府的重要职能部门，经费保障任务繁重，平时多忙于业务工作，法制宣传力度不够大。二是普法的深度和广度还不够。法治宣传教育工作的形式和载体还有待于进一步拓宽，学法用法的氛围还有待进一步加强。' },
			{ type: 'h3', text: '三、下一步打算' },
			{ type: 'p', text: '为继续做好普法宣传工作，我局将按照普法规划以及上级要求，在抓好常规工作落实的同时，重点做好以下各项工作。一是认真落实“谁执法、谁普法”普法责任清单，确保普法宣传工作正常有序开展。二是深入推动经常性学法，在市财政局和区委区政府的正确领导下，以推进区法治政府建设示范创建工作为抓手，继续深入开展法制宣传教育工作，严格依法行政，各项财政工作顺利开展。' },
			{ type: 'p', text: '特此报告。' },
			{ type: 'p', text: '附：《青浦区国家机关“谁执法谁普法”履职报告评议表》。' },
		],
		owner:{
			name: '青浦区财政局',
			date: '2020年12月11日'
		}
	},
	{
		title: '区卫健委2020年“谁执法谁普法”履职报告区卫健委2020年“谁执法谁普法”履职报告区卫健委2020年“谁执法谁普法”履职报告',
	},
	{
		title: '区人社局2020年“谁执法谁普法”履职报告',
	},
	{
		title: '区检察院2020年“谁执法谁普法”履职报告',
	},
	{
		title: '区医保局2020年“谁执法谁普法”履职报告',
	}
];