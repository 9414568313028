/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';

import * as Utils from '../../common/utils';

import './card.less';
import * as CONFIG from './config';

// images
import IMG_DETAIL_01 from './images/cardDetail/01.jpg';
import IMG_DETAIL_02 from './images/cardDetail/02.jpg';
import IMG_DETAIL_03 from './images/cardDetail/03.jpg';

const PageModel = props => {
	useEffect(() => {
		Utils.addICO('/images/zhujiajiaov2/favicon.ico');
	}, []);

	const [ details, setDetails ] = useState([]);
	const getDetails = () => {
		const details = [ IMG_DETAIL_01, IMG_DETAIL_02, IMG_DETAIL_03 ];
		setDetails(details);
	};
	useEffect(() => {
		getDetails();

		Utils.setTitle('角里一卡通');
	}, [123]);

	return (
		<section className="yikatong-container">

			<div className="details">
				{
					details && details.length > 0 && details.map((detail, index) => {
						return (
							<img src={detail} key={index} />
						)
					})
				}
			</div>

			<div className="card-footer">
				{/*<div className="price"><span className="price-val">¥</span>{CONFIG.cardPrice}</div>*/}
				<div className="buy-btn" onClick={() => {
					window.location.href = CONFIG.links.yikatong;
				}}>{/*立即购买*/}立即领取</div>
			</div>

			<div className="scancard-footer">
				{/*<img src={IMG_YIKATONG_QR} />*/}
				<QRCode
					{...{
						value: CONFIG.links.yikatong, // 生成二维码的内容
						size: 300, // 二维码的大小
						fgColor: '#000000', // 二维码的颜色
						/*
							imageSettings: { // 中间有图片logo
								src: IMG_LOGO,
								height: 60,
								width: 60,
								excavate: true
							}
						*/
					}}
				/>
				<span>扫码购买一卡通，畅享多重优惠</span>
			</div>
		</section>
	);
};

export default PageModel;
