/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './content.less';

// components

import IMG_ICON_PAGE_BACK from './images/page-back.png';

const CompModel = props => {
	return (
		<div style={{
			position: 'absolute',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
			backgroundRepeat: 'no-repeat',
			backgroundImage: `url(${IMG_ICON_PAGE_BACK})`,
			right: 37,
			top: 73,
			width: 142,
			height: 33
		}} onClick={() => window.history.go(-1)} />
	);
};

export default CompModel;
