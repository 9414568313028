/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Popover, message, Breadcrumb } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Menu from '../menu';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import * as REQUEST from '../../common/request';
import './index.less';

import IMG_LOGO from '../../common/images/logo-default.jpg';
import IMG_COVER_DEFAULT from '../../common/images/cover-default.jpg';

const CompModel = props => {
  const { breadcrumbs = [], rawHeader = false, needLogin = true } = props;
  const [popoverFlag, setPopoverFlag] = useState(false);

  useEffect(() => {
    Utils.setPageTitle(props.title);
    return () => false;
  }, []);

  const [ userInfo, setUserInfo ] = useState({});
  const getUserInfo = async () => {
    if(!needLogin) return;

    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/get'
    });
    if(!result) return;
    setUserInfo(result.data || {});
    window.ZJJUserInfo = result.data;
  };
  useEffect(() => {
    getUserInfo();

    return () => false;
  }, []);

  const logout = async () => {
    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/logout'
    });
    if(!result) return;

    message.success('登出成功');

    let timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;

      props.history.push('/zhujiajiao/v2/admin/login');
    }, 888);
  };

  if(rawHeader) {
    return <section className="component-page-container">
      <header className="page-header">
        <div className="logo">
          <img src={IMG_LOGO} />
          <span>{CONST.appName}</span>
        </div>
      </header>
      <section>
        <section className="page-body">
          {props.children}
        </section>
      </section>
    </section>
  }

  return (
    <section className="component-page-container">
      <header className="page-header">
        <div className="logo">
          <img src={IMG_LOGO} />
          <span>{CONST.appName}</span>
        </div>
        <div className="right-content">
          {/*<div className="right-content-item">
            <Input.Search placeholder="请输入关键字" size="small" allowClear onSearch={() => {}} style={{ width: 256, fontSize: 12 }} />
          </div>*/}
          {/*<div className="right-content-item" onClick={() => window.open('/admin/help')}><QuestionCircleOutlined style={{ fontSize: 16 }} /></div>*/}
          {/*<div className="right-content-item"><Badge size="small" dot={true} offset={[0, 0]}><MessageOutlined style={{ fontSize: 16 }} /></Badge></div>*/}
          <Popover
            content={<div style={{ display: 'flex', flexDirection: 'column' }}>
              {
                [
                  { label: '登出', action: () => logout() },
                  // { label: '账号管理', action: () => props.history.push('/account') },
                ].map((item, idx) => {
                  return <span style={{ textDecoration: 'underline', cursor: 'pointer', height: 32, display: 'flex', alignItems: 'center' }} key={idx} onClick={item.action}>{item.label}</span>
                })
              }
            </div>}
            trigger="hover"
            style={{ cursor: 'pointer' }}
            visible={popoverFlag}
            onVisibleChange={() => { setPopoverFlag(!popoverFlag) }}
          >
            <div className="userinfo">
              <img src={IMG_COVER_DEFAULT} />
              <span>{userInfo.name}</span>
            </div>
          </Popover>
        </div>
      </header>
      <section>
        <Menu {...props}  />
        <section className="page-body">
          {
            breadcrumbs && breadcrumbs.length > 0 && <Breadcrumb>
              {
                breadcrumbs.map((item, idx) => {
                  const child = item.link && <a href={item.link}>{item.label}</a> || <span>{item.label}</span>;
                  return (
                    <Breadcrumb.Item key={idx}>{child}</Breadcrumb.Item>
                  )
                })
              }
            </Breadcrumb>
          }
          {props.children}
        </section>
      </section>
    </section>
  );
};

export default CompModel;
