/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import './footer.less';

const CompFooter = props => {
	return (
		<footer>
			<p>© 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="__blank">沪ICP备19019249号-1</a>
			<img style={{width:'20px',height:'20px',marginLeft:'20px',marginRight:'10px'}} src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"></img><a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011802005020" rel="noreferrer" target="_blank">沪公网安备31011802005020</a></p>
			<p style={{fontSize: '15px', color: '#999'}}>办公地址：上海市青浦区赵巷镇崧泉路1251号三楼101室 联系电话：021-59831500</p>
		</footer>
	);
};

export default CompFooter;