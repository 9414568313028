/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';
import * as WX from '../../common/wx';

import './taoTemp.less';

// source
import IMG_BEEF from './images/taoTemp/beef.jpg';

const PageCIIE = props => {
  const txt = `
    鸿亮旗舰店黑椒牛仔骨1200g，千年茭情，全程告白，拍下立减50元👇👇👇
    【在售价】￥199.0【520抢购价】￥149.00
    復製评论(b0Or1Kqa3AN)，去【tao寶】下单
  `;
	return (
		<section className="taotemp-container" style={{ height: window.innerHeight }}>
      <div className="img" style={{ backgroundImage: `url(${IMG_BEEF})` }} />
      <div className="name">鸿亮牛仔骨</div>
      <div className="txt">1.2kg（200g * 6包）</div>
      <div className="tip">复制以下口令，打开手机淘宝即刻购买</div>
      <div className="taokoulin">{txt}</div>
      <CopyToClipboard text={txt} onCopy={() => {
        Utils.toast.show('已复制');
        setTimeout(() => {
          Utils.toast.hide();
        }, 888);
      }}>
        <div className="copybtn">复制</div>
      </CopyToClipboard>
		</section>
	);
};

export default PageCIIE;