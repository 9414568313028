/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Typography, Input, Form, message, Modal, Select} from 'antd';

import * as CONST from '../common/const';
import * as REQUEST from "../common/request";

import Page from '../components/page';
import './index.less';
import * as Utils from "../common/utils";
import * as module from "module";
import moment from "moment";

import IMG_DETAIL_01 from './images/details/主页面[新]_01.jpg';
import IMG_DETAIL_02 from './images/details/主页面[新]_02.jpg';
import IMG_DETAIL_03 from './images/details/主页面[新]_03.jpg';
import IMG_DETAIL_04 from './images/details/主页面[新]_04.jpg';
import IMG_DETAIL_05 from './images/details/主页面[新]_05.jpg';

const PageModel = props => {
  const radio = Math.min(750/1600, window.innerWidth/window.innerHeight);

  return (
    <section className="cruise-index-wrapper" style={{ height: window.innerWidth/radio }}>
      {
        [
          IMG_DETAIL_01, IMG_DETAIL_02, IMG_DETAIL_03, IMG_DETAIL_04, IMG_DETAIL_05
        ].map((item, idx) => {
          return <img src={item} key={idx} />
        })
      }
      <div className="reverse-space" />
      <div className="to-reserve" onClick={() => {
        props.history.push('/zhujiajiao/v2/cruise/reserve/action');
      }}>预约</div>
    </section>
  );
};

export default PageModel;
