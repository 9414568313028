/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Utils from '../common/utils';

import './comingsoon.less';

const PageCIIE = props => {
	useEffect(() => {
		Utils.setTitle('敬请期待……');
	}, [123]);

	return (
    <div className="comingsoon-container" style={{ height: window.innerHeight }}>
	    <span>敬请期待……</span>
    </div>
  )
};

export default PageCIIE;