/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';

import * as Utils from '../../common/utils';

import './ly_passenger.less';
import * as CONFIG from './config';

// images
import IMG_DETAIL_01 from './images/cardDetail/01.jpg';
import IMG_DETAIL_02 from './images/cardDetail/02.jpg';
import IMG_DETAIL_03 from './images/cardDetail/03.jpg';
import * as Request from "../../common/request";

const ActivityName = 'zjjPassengerInfo';

const PageModel = props => {
	const [ data, setData ] = useState({});
	const getData = async () => {
		const result = await Request.get({
			headers: { 'Activity-Name': ActivityName },
			url: '/zjj/passenger'
		});
		if(!result) return;

		if(result.data && result.data.info) {
			setData(result.data.info);
		}
	};
	useEffect(() => {
		getData();

		Utils.setTitle('古镇实时客流');
	}, [123]);

	return (
		<section className="zjj-passenger-container" style={{ minHeight: window.innerHeight }}>
			{/*<div className="maxtotal">*/}
			{/*	<div>单日全天最大承载量<span className="num">58313</span>人</div>*/}
			{/*	<div style={{ fontSize: 16 }}>（目前只开放30%）</div>*/}
			{/*</div>*/}
			<div className="zjj-passenger-wrap">
				<div className="bg" />
				<span className="title">古镇实时客流</span>
				{
					[
						// { label: '当前时间进入人数', text: data && data.enterNum || '-' },
						// { label: '当前时间离开人数', text: data && data.exitNum || '-' },
						{ label: '单日全天最大承载量', desc: '', text: '58313人' },
						{ label: '瞬时接待最大承载量', desc: '', text: '18077人' },
						{ label: '当前时间为止进入总人数', text: data && data.enterSum || '-' },
						{ label: '当前时间为止留园人数', text: data && data.keepNum || '-' },
					].map((item, idx) => (
						<div className="zjj-passenger-block" key={idx}>
							<label><em>{item.label}</em><em className="desc">{item.desc ? `（${item.desc}）` : ''}</em></label>
							<span>{item.text}</span>
						</div>
					))
				}
			</div>
		</section>
	);
};

export default PageModel;
