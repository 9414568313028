/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './temp404.less';
import IMG_404 from './images/404.png'

const PageCIIE = props => {
	return (
    <div className="temp404-container" style={{ height: window.innerHeight }}>
      <img src={IMG_404} />
      <span>系统调试中……</span>
      <span>请耐心等待，谢谢配合！</span>
    </div>
  )
};

export default PageCIIE;