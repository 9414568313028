/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import * as Utils from '../../common/utils';
import './ly_recommendrots.less';
import * as CONFIG from './config';
import * as Request from "../../common/request";
const LINK = CONFIG.links;

const PageModel = props => {
	const [ eventList, setEventList ] = useState([]);
	const getEventList = async () => {
		const result = await Request.get({
			url: '/zjj/recommend/hot/recommendrots'
		});
		if(!result) return;

		setEventList(result.data.list);
	};

	useEffect(() => {
		getEventList();
	}, [123]);
	return (
		<section className="recommend-rotes-container">
			{
				eventList && eventList.length > 0 && <div className="block-part">
					{/* <div className="block-space" /> */}
					{/* <div className="block-title"> */}
						{/* <span className="primary"></span> */}
						{/* <span>HOT EVENT</span> */}
					{/* </div> */}
					<div className="block-rotes-list">
						{
							eventList && eventList.length > 0 && eventList.map((item, idx) => {
								return (
									<div className="block-item block-item-event" key={idx} style={{ backgroundImage: `url(${item.cover})` }}  onClick={() => {
										if(item.link) {
											window.location.href = item.link;
											return;
										}
									}} >{item.name}</div>
								)
							})
						}
					</div>
				</div>
			}
		</section>
	);
};

export default PageModel;
