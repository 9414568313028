/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './conte.less';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import * as Request from "../../common/request";


const PageModel = props => {
    const location = useLocation();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [detailValue, setDetailValue] = useState(null);

    const getList = async () => {
        const searchParams = new URLSearchParams(location.search);
        const ids = searchParams.get('id');
        console.log('ids===', ids);


        fetch(`https://zjj-adjust.yeekin.cn/api/law/getLawConteById?id=${ids}`).then(res => {
            return res.json() //res不是需要的请求数据
        }).then(data => {
            console.log(data) //data是请求数据
            setTitle(data[1].title)
            setContent(data[1].content)
            const detailValue = BraftEditor.createEditorState(data[1].content);
            setDetailValue(detailValue);
        }).catch(e => {
            console.log(e) //e是异常信息
        })
    };
    useEffect(() => {
        getList();

        // Utils.setTitle('停车场信息');
    }, [123]);

    const options = {
        defaultFontSize: 50,
    };
    // https://gaode.com/dir?from[name]=起点&from[lnglat]=116.379028,39.865042&to[name]=终点&to[lnglat]=116.427281,39.903719&policy=1&type=car
    return (
        <section className="conte-container" style={{ minHeight: window.innerHeight }}>
           <div className='divimg'></div>
           <div className='conte-content'>
                <div className='conte-title'>{title}</div>
                {/* <div className='conte-conte'>{content}</div> */}
                <BraftEditor
                    {...options}
                    readOnly={true}
                    controls={[]}
                    defaultFontSize='30px'
                    // style={{ height: 'calc(100% - 52px)', overflow: 'auto' }}
                    value={detailValue}
                    // onChange={setDetailValue}
                    // media={{
                    //     // uploadFn: Utils.editorUploadHelper({ url: '/zhujiajiao/v2/admin/announce/upload' }),
                    //     validateFn: (file) => {
                    //         // return Utils.imageUploadSizeVerify(file);
                    //     }
                    // }}
                    // imageControls={[
                    //     'float-left', // 设置图片左浮动
                    //     'float-right', // 设置图片右浮动
                    //     'align-left', // 设置图片居左
                    //     'align-center', // 设置图片居中
                    //     'align-right', // 设置图片居右
                    //     'link', // 设置图片超链接
                    //     'size', // 设置图片尺寸
                    //     'remove' // 删除图片
                    // ]}
                />
           </div>
        </section>
    );
};

export default PageModel;
