/**
 * Created by jimmy on 2019/11/8.
 */

const host = window.location.host;
export const env = (host.indexOf('127.0.0.1') > -1 || host.indexOf('localhost') > -1) ? 'env' : 'product';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
export const uploadPrefix = '/api/v1';
export const imgPrefix = env == 'product' ? `https://${window.location.host}/` : 'http://127.0.0.1:33003/';
export const videoPrefix = `/`;

export const appName = '朱家角旅游';

export const pageLimit = 30;

export const yesnoMap = {
  1: '是',
  2: '否'
};

export const mallStatus = { 1: '初始化中', 2: '运行中', 3: '已过期', 4: '异常' };

//公告类型
export const mallType = { 1: '外链', 2: '本站文章' };
export const announceType = { 1: '外链', 2: '本站文章' };

// 商家主体类型
export const businessCategoryTypeMap = { 1: '企业', 2: '个体户', 3: '个人', 4: '其他' };
