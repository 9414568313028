/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as Request from '../../../common/request';
import * as CONST from '../../../common/const';
import * as Utils from '../../../common/utils';
import * as WX from '../../../common/wx';

import './index.less';

// source
import IMG_BLLC from './images/banliliuc.jpg';
import IMG_SZ from './images/shouze.jpg';
import IMG_XZH from './images/xuzhi.jpg';
import IMG_ZHJ from './images/zhuji.jpg';

const __PAGES__ = {
	banliliuc: IMG_BLLC,
	shouze: IMG_SZ,
	xuzhi: IMG_XZH,
	zhuji: IMG_ZHJ
};

const PageModel = props => {
	return (
		<section className="shxf-container">
      <img src={__PAGES__[Utils.getSearchByKey('p') || 'xuzhi']} />
		</section>
	);
};

export default PageModel;