/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './daibiao.less';

import PageBack from './pageBackComp';

// images
import IMG_DAIBIAO_1 from './images/daibiao-entrance-1.png';
import IMG_SHEQU_1 from './images/shequ-entrance-1.png';
import IMG_SHEQU_2 from './images/shequ-entrance-2.png';
import IMG_SHEQU_3 from './images/shequ-entrance-3.png';
import IMG_SHEQU_4 from './images/shequ-entrance-4.png';

const PageModel = props => {
	return (
		<section className="baihe-daibiao-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="entrances">
				{
					[
						{ icon: IMG_DAIBIAO_1, link: '/baihe/content/daibiao1' },
						{ icon: IMG_SHEQU_1, link: '/baihe/content/shequ1' },
						{ icon: IMG_SHEQU_2, link: '/baihe/content/shequ2' },
						{ icon: IMG_SHEQU_3, link: '/baihe/content/shequ3' },
						{ icon: IMG_SHEQU_4, link: '/baihe/content/shequ4' },
					].map((item, idx) => <div className="item" key={idx} onClick={() => {
						props.history.push(item.link);
						return;
					}}>
						<img src={item.icon} />
					</div>)
				}
			</div>
		</section>
	);
};

export default PageModel;