/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './home.less';

// components
import SpikeComp from '../../components/spike';


// images
// import IMG_BANNER_YIKATONG from './images/banners/yikatong.jpg';
import IMG_BANNER_YIKATONG from './images/banners/yikatong_zqgq.jpeg';
import IMG_BANNER_DRAGONBOAT from './images/banners/dragonboat.jpg';
import IMG_BANNER_ZIZHU from './images/banners/净心斋素菜馆素食自助.jpeg';
import IMG_BANNER_JIANG from './images/banners/jiang.jpeg';
import IMG_BANNER_SHUISHANG from './images/banners/shuishang.jpg';
import IMG_BANNER_SDG from './images/banners/sdg.jpg';

import IMG_COVER_KA from './images/entrances/cover-ka.png';
import IMG_COVER_HOT from './images/entrances/cover-hot.png';
import IMG_COVER_DATE from './images/entrances/cover-date.png';
import IMG_ICON_DATE from './images/entrances/icon-date.png';
import IMG_ICON_DATE_ORDER from './images/entrances/icon-date-order.png';
import IMG_ICON_HOT from './images/entrances/icon-hot.png';
import IMG_ICON_ZHUSHIDAJI from './images/entrances/icon-zhushidaji.png';
import IMG_ICON_PRESENT from './images/entrances/icon-present.png';
import IMG_ICON_RESTAURANT from './images/entrances/icon-restaurant.png';
import IMG_ICON_TEA from './images/entrances/icon-tea.png';
import IMG_ICON_SALE from './images/entrances/icon-sale.png';
// import IMG_YIKATONG from './images/yikatong.jpg';
import IMG_YIKATONG from './images/yikatong_zqgq.jpg';
import IMG_ROOM_COVER from './images/room-cover.jpg';
import IMG_HOT_COVER_05 from './images/hot-cover-05.jpg';
import IMG_SELF_QR from './images/qrcode.png';

import * as DATA from './data/index';

import * as CONFIG from './config';
const LINK = CONFIG.links;

const PageModel = props => {
	useEffect(() => {
		Utils.addICO('/images/zhujiajiaov2/favicon.ico');
	}, []);

	const [ banners, setBanners ] = useState([]);
	const getBanners = () => {
		const banners = [
			{ cover: IMG_BANNER_SDG, link: LINK.banners.sdg },
			{ cover: IMG_BANNER_YIKATONG, link: LINK.banners.yikatong },
			// { cover: IMG_BANNER_ZIZHU, link: LINK.banners.zizhu },
			{ cover: IMG_BANNER_JIANG, link: LINK.banners.jiang },
			{ cover: IMG_BANNER_SHUISHANG, link: LINK.banners.shuishang },
			// { cover: IMG_BANNER_DRAGONBOAT, link: LINK.banners.dragonboat },

		];
		setBanners(banners);
	};
	useEffect(() => {
		getBanners();

		Utils.setTitle('角里优选');
	}, [123]);
	const [ swipeIndex, setSwipeIndex ] = useState(0);

	const [ storeList, setStoreList ] = useState([]);
	const getStoreList = () => {
		const _storeList = DATA.storeList;

		const storeList = [];
		const _getList = (target) => {
			const list = [];
			if(_storeList[target].list) {
				_storeList[target].list.map(item => {
					if(!!item.showHomePage && list.length < 2) {
						list.push(item)
					}
				});
			}
			return list;
		};

		[
			{ blockTitle: '一卡通·餐厅', target: 'restaurant' },
			{ blockTitle: '一卡通·茶饮', target: 'tea' },
			{ blockTitle: '一卡通·伴手礼', target: 'present' },
			{ blockTitle: '一卡通·酒吧', target: 'bar' },
		].map(itm => {
			const list = _getList(itm.target);
			if(list && list.length > 0) {
				storeList.push({
					blockTitle: itm.blockTitle, moreLink: LINK[itm.target], list
				});
			}
		});
		setStoreList(storeList);

	};
	useEffect(() => {
		getStoreList();
	}, [123]);

	const [ qrcodeFlag, setQrcodeFlag ] = useState(false);
	const [ qrcodeLink, setQrcodeLink ] = useState('');
	return (
		<section className="zhujiajiao-container">
			<div className="banner-list-wrap">
				{
					banners && banners.length > 0 && <ReactSwipe
						className="banner-list"
						swipeOptions={{
							continuous: false,
							stopPropagation: false,
							transitionEnd: setSwipeIndex
						}}
					>
						{
							banners.map((banner, idx) => <div className="banner-item" key={idx} style={{ backgroundImage: `url(${banner.cover})` }} onClick={() => {
								window.location.href = banner.link;
								return;
							}} />)
						}
					</ReactSwipe>
				}
				{
					banners && banners.length > 1 && <div className="index-wrap">
						{ banners.map((p, idx) => <em key={idx} className={idx == swipeIndex ? 'active' : ''} />) }
					</div>
				}
			</div>
			<div className="entrances">
				{
					[
						{
							cover: IMG_COVER_KA, bgColor: '#EAF6FF',
							list: [
								{ icon: IMG_ICON_RESTAURANT, txt: '餐厅', link: LINK.restaurant },
								{ icon: IMG_ICON_TEA, txt: '茶饮', link: LINK.tea },
								{ icon: IMG_ICON_PRESENT, txt: '伴手礼', link: LINK.present }
							]
						},
						{
							cover: IMG_COVER_DATE, bgColor: '#E5F9F6',
							list: [
								{
									icon: IMG_ICON_DATE, txt: '古镇预约', link: LINK.date,
									clickHandle: (target) => {
										if(window.innerWidth == 1080) {
											setQrcodeFlag(true);
											setQrcodeLink(target.link);
										}else{
											window.location.href = target.link;
										}
									}
								},
								{
									icon: IMG_ICON_DATE_ORDER, txt: '预约订单', link: LINK.dateOrder,
									clickHandle: (target) => {
										if(window.innerWidth == 1080) {
											setQrcodeFlag(true);
											setQrcodeLink(target.link);
										}else{
											window.location.href = target.link;
										}
									}
								}
							]
						},
						{
							cover: IMG_COVER_HOT, bgColor: '#FEF1E6',
							list: [
								{
									icon: IMG_ICON_SALE, txt: '角里·优选', link: LINK.haowu,
									clickHandle: (target) => {
										if(window.innerWidth == 1080) {
											setQrcodeFlag(true);
											setQrcodeLink(target.link);
										}else{
											window.location.href = target.link;
										}
									}
								},
								{
									icon: IMG_ICON_HOT, txt: '特价·爆款', link: LINK.baokuan
								},
								{
									icon: IMG_ICON_ZHUSHIDAJI, txt: '朱市大集', link: LINK.zhushidaji
								}
							]
						}
					].map((item, index) => {
						return (
							<div className="entrance-block" key={index}>
								<div className="cover" style={{ backgroundImage: `url(${item.cover})` }}></div>
								{
									item.list.map((itm, idx) => {
										return (
											<div className="item" style={{ backgroundColor: item.bgColor }} key={idx} onClick={() => {
												if(itm.clickHandle && typeof itm.clickHandle == 'function') {
													itm.clickHandle(itm);
												}else{
													window.location.href = itm.link;
												}
											}}>
												<div className="item-icon" style={{ backgroundImage: `url(${ itm.icon })` }} />
												<div className="item-text">{itm.txt}</div>
											</div>
										)
									})
								}
							</div>
						)
					})
				}
			</div>

			{/*<SpikeComp {...{*/}
				{/*cover: IMG_BANNER_ACTIVITY01,*/}
				{/*link: LINK.banners.a02,*/}
				{/*startTime: moment('2021/01/28 00:00:00'),*/}
				{/*endTime: moment('2021/03/09 00:00:00')*/}
			{/*}} />*/}

			<div className="yikatong" onClick={() => {
				window.location.href = LINK.card;
			}}>
				<img src={IMG_YIKATONG} />
			</div>

			{
				CONFIG.hotList && CONFIG.hotList.length > 0 && <div className="hot-recommend-list">
					<div className="title-wrap" onClick={() => {
						window.location.href = LINK.baokuan;
						return;
					}}>
						<span className="title-txt">热门推荐</span>
						<span>好的东西要拿出来跟大家一起分享</span>
						<div className="readmore">
							{
								[{}, {}, {} ].map((titm, tidx) => <em key={tidx} />)
							}
						</div>
					</div>
					{
						CONFIG.hotList.map((item, idx) => {
							return (
								<div className="hot-recommend-item" key={idx} onClick={() => {
									window.location.href = item.link;
									return;
								}}>
									<div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
									<div className="content">
										<div className="title">
											<span className="txt">{item.title}</span>
											{ item.isCoupon && <span className="coupon">限时特惠</span> }
										</div>
										<div className="ctrls">
											<div className="price"><span className="saleval">¥{item.salePrice}</span><span>¥{item.oriPrice}</span></div>
											<div className="buybtn">马上抢</div>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			}

			{/*<div className="store-list">
				{
					storeList.map((item, index) => {
						return (
							<div className="store-block" key={index}>
								<div className="title" onClick={() => {
									props.history.push(item.moreLink);
								}}>
									<span>{item.blockTitle}</span>
									<div className="more">更多</div>
								</div>
								<div className="list">
									{
										item.list.map((itm, idx) => {
											return (
												<div className="item" key={idx} onClick={() => {
													props.history.push(item.moreLink);
												}}>
													<div className="cover" style={{ backgroundImage: `url(/images/zhujiajiaov2/${itm.cover}.jpg)` }} />
													/!*<div className="cover" style={{ backgroundImage: `url(https://www.yeekin.cn/images/zhujiajiao/%E5%BC%8B%E5%92%8C%E6%98%A5.jpg)` }} />*!/
													<div className="content">
														<span className="name">{itm.title}</span>
														<span className="coupon">{itm.couponHome || itm.coupon}</span>
													</div>
												</div>
											);
										})
									}
								</div>
							</div>
						);
					})
				}
			</div>*/}

			<div className="sale-list">
				{
					[
						{
							blockTitle: '角里·优选',
							link: LINK.haowu,
							list: [
								{ cover: IMG_ROOM_COVER, name: '朱家角文创纪念公仔' }
							]
						},
						{
							blockTitle: '特价·爆款',
							link: LINK.baokuan,
							list: [
								{ cover: IMG_HOT_COVER_05, name: '「朱里俱舍」冬日温泉&寿喜烧双人套餐' },
								{ cover: 'https://www.yeekin.cn/images/zhujiajiao/Mayercoffee.jpg', name: 'MAYER咖啡下午茶套餐' }
							]
						}
					].map((item, index) => {
						return (
							<div className="sale-block" key={index}>
								<div className="title" onClick={() => {
									window.location.href = item.link;
								}}>
									<span>{item.blockTitle}</span>
									<div className="more">
										{
											[{}, {}, {} ].map((titm, tidx) => <em key={tidx} />)
										}
									</div>
								</div>
								<div className="list">
									{
										item.list.map((itm, idx) => {
											return (
												<div className="item" key={idx} onClick={() => {
													window.location.href = item.link;
												}}>
													<div className="cover" style={{ backgroundImage: `url(${itm.cover})` }} />
													<div className="txt">{itm.name}</div>
												</div>
											);
										})
									}
								</div>
							</div>
						);
					})
				}
			</div>

			<div className="hotlines-wrap">
				<div className="hotlines">
					<div className="hotline-item">
						<div className="hicon h0" /><span className="hname">客服热线</span><span className="hnum">021-59243792 021-59246725</span>
					</div>
				</div>
				<div className="hotlines">
					<div className="hotline-item">
						<div className="hicon h1" /><span className="hname">客服热线</span><span className="hnum">15692127515</span>
					</div>
					<div className="hotline-item">
						<div className="hicon h2" /><span className="hname">商务合作</span><span className="hnum">13788925440</span>
					</div>
					<div className="hotline-item">
						<div className="hicon h3" /><span className="hname">投诉热线</span><span className="hnum">13262852630</span>
					</div>
				</div>
			</div>

			<div className="footer">
				<div className="item">
					<em className="icon icon-home" /><span>首页</span>
				</div>
				<div className="item">
					<em className="icon icon-user" onClick={() => {
						window.location.href = LINK.userOrder;
					}} /><span>我的</span>
				</div>
			</div>

			{
				qrcodeFlag && qrcodeLink && <div className="qrcode-wrapper">
					<div className="close" onClick={() => {
						setQrcodeFlag(false);
					}} />
					<QRCode
						{...{
							value: qrcodeLink, // 生成二维码的内容
							size: 600, // 二维码的大小
							fgColor: '#000000', // 二维码的颜色
							/*
							 imageSettings: { // 中间有图片logo
							 src: IMG_LOGO,
							 height: 60,
							 width: 60,
							 excavate: true
							 }
							 */
						}}
					/>
				</div>
			}
		</section>
	);
};

export default PageModel;
