/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import './page.less';

import Header from './header';
import Footer from './footer';

const CompPage = props => {
	document.title = props.title || '奕肯';
	
	return (
		<section className="container">
			<Header />
			<section className="body" style={{ minHeight: window.innerHeight - 136 }}>{props.children}</section>
			<Footer />
		</section>
	);
};

export default CompPage;