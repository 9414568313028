/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Image, Input, InputNumber, Form, message, Modal, Select, Tooltip, Table, Typography} from 'antd';
import { WarningOutlined, ZoomInOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';

import * as CONST from '../../common/const';
import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import Page from '../../components/page';
import UploadPictureList from '../../components/uploadPictureList';
import './index.less';

const PageModel = props => {
  const [form] = Form.useForm();

  const [ modalVisible, setModalVisible ] = useState(false);
  const closeModalHandle = () => {
    setModalVisible(false);
    setTimeout(() => {
      form.resetFields();
    }, 20);
  };

  const [ list, setList ] = useState([]);
  const getList = async () => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/list'
    });
    if(!result) return;

    result.data.list.map(item => {
      item.key = item.id;
      return item;
    });
    setList(result.data.list);
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const submitHandle = async (params = {}) => {
    if(!params) return;

    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/add',
      data: params
    });
    if(!result) return;

    message.success('操作成功');

    closeModalHandle();
    getList();
  };
  const deleteHandle = async (id) => {
    if(!id) return;

    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/delete',
      data: {
        id
      }
    });
    if(!result) return;

    message.success('操作成功');
    getList();
  };

  return (
    <Page {...{
      ...props,
      breadcrumbs: [ { label: '首页' }, { label: '管理员列表' } ],
      title: '管理员设置'
    }}>
      <section className="mall-container" style={{ minHeight: window.innerHeight }}>
        <div className="mall-top">
          <Button type="primary" onClick={async () => {
            setModalVisible(true);
            form.resetFields();
          }}>新增管理员</Button>
        </div>
        <Table {...{
          size: 'small',
          locale: { emptyText: '暂无数据' },
          dataSource: list,
          columns: [
            { title: '姓名', dataIndex: 'name', key: 'name' },
            { title: '手机号码', dataIndex: 'mobile', key: 'mobile' },
            {
              title: '操作', dataIndex: 'edit', key: 'edit', width: 156, fixed: 'right',
              render: (text, record, index) => {
                const _style = { marginRight: 10,	fontSize: 12 };
                return <>
                  {
                    window.ZJJUserInfo?.id == 1 && record.id != 1 && <Button type="text" danger size="small" style={_style} onClick={() => {
                      Modal.confirm({
                        title: '删除的数据将无法找回，请慎重操作！',
                        icon: <WarningOutlined style={{ color: '#E45357' }} />,
                        // content: 'Bla bla ...',
                        okText: '确认删除',
                        cancelText: '取消',
                        onOk: () => {
                          deleteHandle(record.id);
                        },
                        onCancel: () => {
                          // console.log('Cancel');
                        },
                      });
                    }}>删除</Button>
                  }
                </>
              }
            },
          ]
        }} />
      </section>

      <Modal {...{
        title: '新增管理员',
        width: '75%',
        visible: modalVisible,
        maskClosable: false,
        destroyOnClose: true,
        onCancel: closeModalHandle,
        footer: null
      }}>
        {
          modalVisible && <Form {...{
            initialValues: undefined,
            form,
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            onFinish: values => {
              submitHandle(values);
            }
          }}>
            <Form.Item label="姓名" name="name" rules={[
              { required: true, message: '姓名不能为空' }
            ]}>
              <Input placeholder="请输入管理员姓名" allowClear />
            </Form.Item>
            <Form.Item label="手机号码" name="mobile">
              <Input placeholder="请输入手机号码（手机号码即为登录账号）" allowClear />
            </Form.Item>
            <Form.Item label="登录密码" name="password">
              <Input placeholder="请设置登录密码" allowClear />
            </Form.Item>

            <Form.Item label=" " colon={false}>
              <Button style={{ marginRight: 16 }} onClick={closeModalHandle}>取消</Button>
              <Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>
        }
      </Modal>

    </Page>
  );
};

export default PageModel;
