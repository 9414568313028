/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';

import './index.less';
import * as Utils from "../common/utils";

// import IMG_TO_MATOU from './images/v1/tomatou.png';
// import IMG_TO_YUYUE from './images/v1/toyuyue.png';
import IMG_START_TRAVEL from './images/v2/start_travel.png';
import IMG_TO_ORDER from './images/v2/boat_order.png';
import IMG_TO_NAVIGATION from './images/v2/wharf_navigation.png';
import IMG_TO_BACK_PAGE from './images/v2/back.png';
import IMG_TO_BOOKING from './images/v2/booking.png';

import IMG_COVER_15 from './images/v2/viewspot/15-和心园.png';
import IMG_COVER_14 from './images/v2/viewspot/14-全华水彩艺术馆.png';
import IMG_COVER_13 from './images/v2/viewspot/13-大清邮局.png';
import IMG_COVER_12 from './images/v2/viewspot/12-平安桥.png';
import IMG_COVER_11 from './images/v2/viewspot/11-城隍庙.png';
import IMG_COVER_10 from './images/v2/viewspot/10-廊桥.png';
import IMG_COVER_09 from './images/v2/viewspot/09-泰安桥.png';
import IMG_COVER_08 from './images/v2/viewspot/08-圆津禅院.png';
import IMG_COVER_07 from './images/v2/viewspot/07-北大街.png';
import IMG_COVER_06 from './images/v2/viewspot/06-上海市鹤龙美术馆.png';
import IMG_COVER_05 from './images/v2/viewspot/05-江南第一茶楼.png';
import IMG_COVER_04 from './images/v2/viewspot/04-放生桥.png';
import IMG_COVER_03 from './images/v2/viewspot/03-阿婆茶楼.png';
import IMG_COVER_02 from './images/v2/viewspot/02-课植园.png';
import IMG_COVER_01 from './images/v2/viewspot/01-古镇概况.png';
import IMG_BANNER_01 from './images/v2/banner/b1-古镇概况.png';
import IMG_BANNER_02 from './images/v2/banner/b2-课植园.png';
import IMG_BANNER_03 from './images/v2/banner/b3-阿婆茶楼.png';
import IMG_BANNER_04 from './images/v2/banner/b4-放生桥.png';
import IMG_BANNER_05 from './images/v2/banner/b5-江南第一茶楼.png';
import IMG_BANNER_06 from './images/v2/banner/b6-上海市鹤龙美术馆.png';
import IMG_BANNER_07 from './images/v2/banner/b7-北大街.png';
import IMG_BANNER_08 from './images/v2/banner/b8-圆津禅院.png';
import IMG_BANNER_09 from './images/v2/banner/b9-泰安桥.png';
import IMG_BANNER_10 from './images/v2/banner/b10-廊桥.png';
import IMG_BANNER_11 from './images/v2/banner/b11-城隍庙.png';
import IMG_BANNER_12 from './images/v2/banner/b12-平安桥.png';
import IMG_BANNER_13 from './images/v2/banner/b13-大清邮局.png';
import IMG_BANNER_14 from './images/v2/banner/b14-全华水彩艺术馆.png';
import IMG_BANNER_15 from './images/v2/banner/b15-和心园.png';
const LIST = [
  { id: 1, name: '古镇概况', enName:'Overview of Ancient Town', cover: IMG_COVER_01, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/01-古镇概况.mp3', banner:IMG_BANNER_01 },
  { id: 2, name: '课植园', enName:'Kezhi Garden', cover: IMG_COVER_02, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/02-课植园.mp3', banner:IMG_BANNER_02  },
  { id: 3, name: '和心园', enName:'The Garden Of Inseparable Hearts', cover: IMG_COVER_15, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/03-和心园.mp3',banner:IMG_BANNER_15 },
  { id: 4, name: '阿婆茶楼', enName:'Apo Teahouse', cover: IMG_COVER_03, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/03-阿婆茶楼.mp3',banner:IMG_BANNER_03 },
  { id: 5, name: '放生桥', enName:'Fangsheng Bridge', cover: IMG_COVER_04, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/04-放生桥.mp3',banner:IMG_BANNER_04 },
  { id: 6, name: '江南第一茶楼', enName:'First Jiangnan Teahouse', cover: IMG_COVER_05, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/05-江南第一茶楼.mp3',banner:IMG_BANNER_05 },
  { id: 7, name: '上海市鹤龙美术馆', enName:'Shanghai Helong Art Gallery', cover: IMG_COVER_06, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/06-上海市鹤龙美术馆.mp3',banner:IMG_BANNER_06 },
  { id: 8, name: '北大街', enName:'Great North Street', cover: IMG_COVER_07, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/07-北大街.mp3' ,banner:IMG_BANNER_07},
  { id: 9, name: '圆津禅院', enName:'Yuanjin Buddhist Temple', cover: IMG_COVER_08, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/08-圆津禅院.mp3' ,banner:IMG_BANNER_08 },
  { id: 10, name: '泰安桥', enName:'Taian Bridge', cover: IMG_COVER_09, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/09-泰安桥.mp3',banner:IMG_BANNER_09 },
  { id: 11, name: '廊桥', enName:'Lounge Bridge', cover: IMG_COVER_10, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/10-廊桥.mp3',banner:IMG_BANNER_10 },
  { id: 12, name: '城隍庙', enName:'City God Temple', cover: IMG_COVER_11, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/11-城隍庙.mp3',banner:IMG_BANNER_11 },
  { id: 13, name: '平安桥', enName:'Pingan Bridge', cover: IMG_COVER_12, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/12-平安桥.mp3',banner:IMG_BANNER_12 },
  { id: 14, name: '大清邮局', enName:'Post Office Of Qing Dynasty', cover: IMG_COVER_13, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/13-大清邮局.mp3',banner:IMG_BANNER_13 },
  { id: 15, name: '全华水彩艺术馆', enName:'Quanhua Watercolor Art Museum', cover: IMG_COVER_14, url: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/14-全华水彩艺术馆.mp3',banner:IMG_BANNER_14 },
  // { cover: IMG_COVER_14, tip: '只是占位' },
  // { cover: IMG_COVER_14, tip: '只是占位' },
  // { cover: IMG_COVER_14, tip: '只是占位' },
  // { cover: IMG_COVER_14, tip: '只是占位' },
];
const INTRO_VIDEO_URL = `https://static-yeekin.oss-cn-shanghai.aliyuncs.com/朱家角语音导览/语音欢迎词（男版）.mp3`;
const PAGE_SPACE = 15;
let introAudioRef = null;

// const CtrlRender = () => {
//   return (
//     <div className="entrances-wrap">
//       <div className="entrance-item" style={{ backgroundImage: `url(${IMG_TO_YUYUE})` }} onClick={() => {
//         // window.location.href = `https://yeekin.cn/zhujiajiao/v2/cruise/reserve`;
//         window.location.href = `http://zjj.5james.com/shareUrl?toUrl=goods&id=18`;
//       }} />
//       <div className="entrance-item" style={{ backgroundImage: `url(${IMG_TO_MATOU})` }} onClick={() => {
//         window.location.href = `//ditu.amap.com/search?query=%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%9C%B1%E5%AE%B6%E8%A7%92%E8%A5%BF%E4%BA%95%E8%A1%97113%E5%8F%B7&city=310000&geoobj=120.904556%7C30.783602%7C122.608819%7C31.723008&zoom=9.63`;
//       }} />
//     </div>
//   )
// }

const PageModel = props => {
  const [ list, setList ] = useState([]);
  const [ voicePage, setVoicePage ] = useState(1);
  const [ audioList, setAudioList ] = useState([]);
  const getList = async () => {
    const list = LIST.slice((voicePage-1)*PAGE_SPACE, (voicePage)*PAGE_SPACE);
    for(let i = 0, len = list.length; i < len; i++) {
      for(let ai = 0, alen = audioList.length; ai < alen; ai++) {
        if(list[i] && audioList[ai] && list[i].id == audioList[ai].id) {
          list[i] = audioList[ai];
        }
      }
    }
    setList(list);
  };
  useEffect(() => {
    getList();
    return () => false;
  }, [voicePage]);

  const [currentVoice,setCurrentVoice] = useState(LIST[0]);
  const [ page, setPage ] = useState(1);
  const audioHandle = async (current, index) => {
    for(let i = 0, len = audioList.length; i < len; i++) {
      if(audioList[i] && audioList[i].id != current.id && audioList[i].audio) {
        audioList[i].audio.pause();
      }
    }
    if(!current.audio) {
      current.audio = new Audio(current.url);
      current.audio.loop = true;
      current.audio.play();
      list[index] = current;
      setList([...list]);

      audioList.push(current);
      setAudioList(audioList);
    }else{
      if(current.status == 'pause') {
        current.audio.play();
      }else{
        current.audio.pause();
      }
    }

    current.audio.addEventListener('play', () => {
      current.status = 'play';
      list[index] = current;
      setList([...list]);
    });
    current.audio.addEventListener('pause', () => {
      current.status = 'pause';
      list[index] = current;
      setList([...list]);
    });
    setCurrentVoice(current);
  }

  const [ audioFlag, setAudioFlag ] = useState(false);
  const introVideoHandle = (audioFlag) => {
    if(!introAudioRef) {
      introAudioRef = new Audio(INTRO_VIDEO_URL);
    }

    if(audioFlag) {
      introAudioRef.play();
    }else{
      introAudioRef.pause();
    }

    introAudioRef.addEventListener('play', () => {
      setAudioFlag(true);
    });
    introAudioRef.addEventListener('pause', () => {
      setAudioFlag(false);
    });
  };

  useEffect(() => {
    Utils.setPageTitle('古镇游船');

    return () => false;
  }, []);

  //播放按钮以及上一个下一个按钮事件处理
  const audioPlayHandle = async() =>{
    await audioHandle(currentVoice, currentVoice.id - 1);
  }
  const audioPreviousHandle=async ()=>{
    console.log(currentVoice);
    if(currentVoice.id === LIST[0].id){
      //当前为第一个播放文件,设置播放最后一个
      console.log(LIST[LIST.length-1])
      await audioHandle(LIST[LIST.length-1], LIST[LIST.length-1].id-1);
    }else {
      console.log(LIST[currentVoice.id-2])
      await audioHandle(LIST[currentVoice.id-2], LIST[currentVoice.id-2].id-1);
    }
  }

  const audioNextHandle = async () =>{
    console.log(currentVoice);
    if(currentVoice.id === LIST.length){
      //当前为最后一个播放文件,设置播放第一个
      console.log(LIST[0])
      await audioHandle(LIST[0], LIST[0].id-1);
    }else {
      console.log(LIST[currentVoice.id])
      await audioHandle(LIST[currentVoice.id], currentVoice.id-1);
    }
  }

  return (
    <section className="voice-intro-wrapper">
      {
        page === 1 && <section className="voice-intro-page voice-intro-page-new1" style={{ height: window.innerHeight }}>
            <div className={`audio-ctrl ${!audioFlag && 'close' || ''}`} onClick={() => {
              introVideoHandle(!audioFlag);
            }} />
            <div className="intro-welcome">
            </div>
            <div className="intro-text">
              <span>欢迎您乘坐朱家角古镇的游船</span>
              <span>体会不一样的水乡风韵</span>
            </div>
            <div className="entrances-wrap">
              <div className="entrance-item" style={{ backgroundImage: `url(${IMG_TO_ORDER})` }} onClick={() => {
                // window.location.href = `https://yeekin.cn/zhujiajiao/v2/cruise/reserve`;
                window.location.href = `https://zjj-travel.yeekin.cn/product/18?sid=5`;
              }} />
              <div className="entrance-item" style={{ backgroundImage: `url(${IMG_START_TRAVEL})` }}  onClick={() => {
                if(audioFlag){ introVideoHandle(!audioFlag);}
                introAudioRef=null;
                setPage(2);
                window.scrollTo(0, 0);
              }}/>
              <div className="entrance-item" style={{ backgroundImage: `url(${IMG_TO_NAVIGATION})` }} onClick={() => {
                window.location.href = `//ditu.amap.com/search?query=%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%9C%B1%E5%AE%B6%E8%A7%92%E8%A5%BF%E4%BA%95%E8%A1%97113%E5%8F%B7&city=310000&geoobj=120.904556%7C30.783602%7C122.608819%7C31.723008&zoom=9.63`;
              }} />
            </div>
          </section>
      }
      {
        page === 2 && <section className="voice-intro-page voice-intro-page2" style={{ height: window.innerHeight }}>
          <div className="banner-list" >
            <img src={currentVoice.banner} />
            <div className="voice-title">
              <span className="name">{currentVoice.name}</span>
              <span className="en">{currentVoice.enName}</span>
            </div>
            <div className="voice-player">
              <div className="previous" onClick={() => {
                audioPreviousHandle().then(r => {});;
              }}/>


              <div className={`voice ${currentVoice.status == 'play' ? 'play' : 'pause'}`} onClick={() => {
                audioHandle(currentVoice, currentVoice.id-1).then(r => {});
              }}/>

              <div className="next" onClick={() => {
                audioNextHandle().then(r => {});
              }}/>

            </div>
          </div>
          <div className="voice-list">
            {
              list?.length > 0 && list.map((item, idx) => {
                const isSpace = item && !item.id;
                return (
                  <div className={`voice-list-item${isSpace && ' hide' || ''}`} key={idx} onClick={() => {
                    if(isSpace) return;
                    let _item = { ...item };
                    audioHandle(_item, idx);
                  }}>
                    <img src={item.cover} />
                  </div>
                )
              })
            }
          </div>
        <div className="step-warp-list">
          <div className="voice-step-wrap">
            <div className={`voice-step-ctrl back`} style={{ backgroundImage: `url(${IMG_TO_BACK_PAGE})` }} onClick={() => {
              setPage(1);
              // window.location.reload();
              window.scrollTo(0, 0);
            }} />
            <div className={`voice-step-ctrl order`} style={{backgroundImage:`url(${IMG_TO_BOOKING})`}}  onClick={() => {
              window.location.href = `https://zjj-travel.yeekin.cn/product/18?sid=5`;
            }} />
          </div>
          <div className="voice-address-wrap">
            {/*<div className={`voice-step-ctrl`} style={{ backgroundImage: `url(${IMG_ADDRESS})` }} />*/}
            {/*<div className={`voice-step-ctrl`} style={{ backgroundImage: `url(${IMG_PHONE})` }} />*/}
          </div>
        </div>
          {/*<div className="entrances-div">*/}
          {/*  <div className="entrances-wrap">*/}
          {/*    <div className="entrance-item" style={{ backgroundImage: `url(${IMG_TO_BACK_PAGE})` }}  onClick={() => {*/}
          {/*      setPage(1);*/}
          {/*      window.scrollTo(0, 0);*/}
          {/*    }}/>*/}
          {/*    <div className="entrance-item" style={{backgroundImage:`url(${IMG_TO_ORDER})`}} onClick={() => {*/}
          {/*      window.location.href = `http://zjj.5james.com/shareUrl?toUrl=goods&id=18`;*/}
          {/*    }} />*/}
          {/*  </div>*/}
          {/*  <div className="entrances-right">*/}
          {/*    <div className="entrance-item">*/}
          {/*      <img src={IMG_ADDRESS}/>*/}
          {/*    </div>*/}
          {/*    <div className="entrance-item">*/}
          {/*      <img src={IMG_PHONE}/>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>
      }
    </section>
  );
};

export default PageModel;
