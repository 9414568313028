/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import Page from '../../components/page';

import './contact.less';
import IMG_BANNER1 from './images/banner/t1.jpg';
import IMG_BANNER2 from './images/banner/t2.jpg';
import IMG_BANNER3 from './images/banner/t3.jpg';
import IMG_BANNER4 from './images/banner/t4.jpg';
import IMG_TIMELINE from './images/timeline.jpeg';
import IMG_LOGO from './images/logo-black.png';
import IMG_ADDRESS from './images/address.png';
import IMG_WECHAT from './images/wechat.png';
import IMG_EMAIL from './images/email.png';
import IMG_MOBILE from './images/mobile.png';

const PageContainer = props => {
  const [ target, setTarget ] = useState(1);
	return (
  <section className="temp-container">
    <div className="temp-header" style={{ position: 'relative', backgroundColor: '#ffffff', height: 56 }}>
      <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link className="logo" style={{ backgroundImage: `url(${IMG_LOGO})`, width: 36, height: 36 }} to="/home" />
        <div className="tabs">
          <Link className="tab-item" to="/cases">客户案例</Link>
          <Link className="tab-item" to="/aboutus">公司简介</Link>
          <Link className="tab-item" to="/contact" style={{ textDecoration: 'underline' }}>联系我们</Link>
          {/*<Link className="tab-item" to="">技术支持</Link>*/}
        </div>
      </div>
    </div>
    <section className="temp-body" style={{ paddingBottom: 56, minHeight: window.innerHeight - 180 }}>
      <div className="content-banner" style={{ backgroundImage: `url(${IMG_BANNER2})` }} />
      <div className="temp-wrapper">
        <div className="contact-title">您可以通过以下方式跟我们联系</div>
        <div className="contact-main">
          <div className="item">
            <img src={IMG_MOBILE} />
            <span>021-12345678</span>
          </div>
          <div className="item">
            <img src={IMG_EMAIL} />
            <span>bussiness@xx.com</span>
          </div>
          <div className="item">
            <img src={IMG_ADDRESS} />
            <span>上海市青浦区漕盈路1500号</span>
          </div>
          <div className="item">
            <img src={IMG_WECHAT} />
            <span>微信搜索公众号"公众号名称"</span>
          </div>
        </div>
        {/*<div className="feature-online"></div>*/}
      </div>
    </section>
    <div className="temp-footer">
      © 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 沪ICP备19019249号-1
    </div>
  </section>
	);
};

export default PageContainer;