/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './header.less';

import IMG_LOGO from './images/logo.png';

const CompHeader = props => {
	return (
		<header>
			<div className="body-container header">
				<Link to='/' className="logo" title="上海奕肯信息技术有限公司"><img src={IMG_LOGO} /></Link>
				<nav>
					{/*<Link to='/about'>关于我们</Link>*/}
				</nav>
			</div>
		</header>
	);
};

export default CompHeader;