/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import {Image}from 'antd';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';
import * as AMap from '../../common/amap';

import './ly_parking.less';

// components
import SpikeComp from '../../components/spike';


// images
import IMG_PRINKING from './images/prinking.jpg';
import IMG_BANNER_YIKATONG from './images/banners/yikatong_zqgq.jpeg';
import IMG_BANNER_DRAGONBOAT from './images/banners/dragonboat.jpg';
import IMG_BANNER_ZIZHU from './images/banners/净心斋素菜馆素食自助.jpeg';
import IMG_BANNER_JIANG from './images/banners/jiang.jpeg';
import IMG_BANNER_SHUISHANG from './images/banners/shuishang.jpg';
import IMG_BANNER_SDG from './images/banners/sdg.jpg';

import IMG_COVER_KA from './images/entrances/cover-ka.png';
import IMG_COVER_HOT from './images/entrances/cover-hot.png';
import IMG_COVER_DATE from './images/entrances/cover-date.png';
import IMG_ICON_DATE from './images/entrances/icon-date.png';
import IMG_ICON_DATE_ORDER from './images/entrances/icon-date-order.png';
import IMG_ICON_HOT from './images/entrances/icon-hot.png';
import IMG_ICON_ZHUSHIDAJI from './images/entrances/icon-zhushidaji.png';
import IMG_ICON_PRESENT from './images/entrances/icon-present.png';
import IMG_ICON_RESTAURANT from './images/entrances/icon-restaurant.png';
import IMG_ICON_TEA from './images/entrances/icon-tea.png';
import IMG_ICON_SALE from './images/entrances/icon-sale.png';
// import IMG_YIKATONG from './images/yikatong.jpg';
import IMG_YIKATONG from './images/yikatong_zqgq.jpg';
import IMG_ROOM_COVER from './images/room-cover.jpg';
import IMG_HOT_COVER_05 from './images/hot-cover-05.jpg';
import IMG_SELF_QR from './images/qrcode.png';

import * as DATA from './data/index';

import * as CONFIG from './config';
import * as Request from "../../common/request";
const LINK = CONFIG.links;

const COLORS = [ '#F07167', '#0081A7', '#6F476B', '#00AFB9', '#A4BE94', '#7E101C', '#1C1C1C' ];

const PARKING_MAP = {
	'游客中心':{
		address: '上海市青浦区课植园路555号',
		lnglat: '121.050575,31.114525',
		totalParkingSpace:167,
		openTime: '00:00—24:00'
	},
	'课植园路F1': {
		address: '上海市青浦区课植园路555号',
		lnglat: '121.050575,31.114525',
		totalParkingSpace: 150,
		openTime: '00:00—24:00'
	},
	'证大': {
		address: '上海市青浦区课植园路599弄',
		lnglat: '121.049149,31.113352',
		totalParkingSpace: 350,
		openTime: '00:00—24:00'
	},
	'影剧院广场': {
		address: '上海市青浦区新溪路18号',
		lnglat: '121.059435,31.109651',
		totalParkingSpace: 108,
		openTime: '00:00—24:00'
	},
	'乐湖': {
		address: '上海市青浦区东湖街340号',
		lnglat: '121.049828,31.103765',
		totalParkingSpace: 55,
		openTime: '00:00—24:00'
	},
	'中南朱里雅集': {
		address: '上海市青浦区油车浜路94号附近',
		lnglat: '121.058374,31.110956,',
		totalParkingSpace: 350,
		openTime: '00:00—24:00'
	},
	'新角里停车场': {
		address: '上海市青浦区新风路160号',
		lnglat: '121.058014,31.108629',
		totalParkingSpace: 260,
		openTime: '00:00—24:00'
	},
}


const PageModel = props => {
	const [ list , seList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/zjj/parking/list'
		});
		if(!result) return;

		const list = result.data.info || [];
		console.log(list);
		seList(list);
	};
	useEffect(() => {
		getList();

		Utils.setTitle('停车场信息');
	}, [123]);
	// https://gaode.com/dir?from[name]=起点&from[lnglat]=116.379028,39.865042&to[name]=终点&to[lnglat]=116.427281,39.903719&policy=1&type=car
	return (
		<section className="parking-container" style={{ minHeight: window.innerHeight }}>
			<div className='pkdiv'>
				<Image className='pkimg' src={IMG_PRINKING} />
			</div>
			{
				list && list.length > 0 && list.map((item, idx) => {
					const _parkingItem = PARKING_MAP[item.parkingName];
					if(!_parkingItem) return null;

					const name = item.parkingName + (item.parkingName && item.parkingName.indexOf('停车场') == -1 && '停车场' || '');
					let radio = ((item.parkingRemaining < 0 ? 0 : item.parkingRemaining) / _parkingItem.totalParkingSpace * 100).toFixed(2);
					radio = Math.min(radio, 100);
					console.log('-----',radio);

					return (
						<div className="parking-item2" key={idx} onClick={async () => {
							const res = await AMap.getCurrentPosition2();
							let startAddress = '';
							let startLngLat = '';
							if(res.formattedAddress && res.lnglat) {
								startAddress = res.formattedAddress;
								startLngLat = res.lnglat.join(',');
							}
							const link = `https://gaode.com/dir?from[name]=${startAddress}&from[lnglat]=${startLngLat}&to[name]=${_parkingItem.address}&to[lnglat]=${_parkingItem.lnglat}&policy=1&type=car`;
							window.location.href = link;
							return;
						}}>
							<div className="parking-sign" />
							<div className="parking-info">
								<div className="base-msg">
									<div className="name">{name}</div>
									<div className="opentime">营业时间<span>{_parkingItem.openTime}</span></div>
								</div>
								<div className="address-msg">
									<span>{_parkingItem.address}</span>
									<span className="dh">导航去这里</span>
								</div>
								<div className="poi-msg">
									<div className="radio"><span style={{ width: radio + '%' }} /></div>
									<div className="unit">
										<span>剩余总数/车位总数</span>
										<span>{item.parkingRemaining < 0 ? 0 : item.parkingRemaining}个/{_parkingItem.totalParkingSpace}个</span>
									</div>
								</div>
							</div>
						</div>
					)
					// return (
					// 	<div className="parking-item" key={idx}>
					// 		<div className="cover" style={{ backgroundColor: COLORS[idx] }}>{item.venueName[0]}</div>
					// 		<div className="content">
					// 			<span className="title">{item.venueName}{item.venueName && item.venueName.indexOf('停车场') == -1 && '停车场' || ''}</span>
					// 			<span>车位总数：{item.maxValue}</span>
					// 			<span>剩余总数：{item.parkingValue}</span>
					// 		</div>
					// 		<div className="block" />
					// 	</div>
					// )
				})
			}
		</section>
	);
};

export default PageModel;
