/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Tabs, Form, Input, Space, Button, Divider, message} from 'antd';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import IMG_BG from './images/bg.jpg';

import './index.less';

const PageModel = props => {
  const [form] = Form.useForm();

  const [ codeImg, setCodeImg ] = useState(null);
  const getCodeImg = async () => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/imgcode/get'
    });
    if(!result) return;

    const codeImg = `data:image/svg+xml;base64,${window.btoa(result.data.img)}`;
    setCodeImg(codeImg);
  };
  useEffect(() => {
    getCodeImg();
  }, [123]);

  const loginHandle = async (params = {}) => {
    if(!params.imgCode) {
      message.error('请输入验证码');
      return;
    }
    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/login',
      data: params
    });
    if(!result) return;

    message.success('登录成功');

    props.history.push('/zhujiajiao/v2/admin/announce');
  };

  return (
    <section className="login-container" style={{ minHeight: window.innerHeight, backgroundImage: `url(${IMG_BG})` }}>
      <div className="login-wrap">
        <div className="login-content-wrap">
          <div className="login-content">
            <div className="login-title">欢迎登录朱家角运营管理系统</div>
            <Form {...{
              initialValues: undefined,
              form,
              onFinish: values => {
                console.log(values);
                loginHandle(values);
              }
            }}>
              <Form.Item label="" colon={false} name="mobile">
                <Input placeholder="请输入账号/手机号" maxLength={11} size="large" />
              </Form.Item>
              <Form.Item label="" colon={false} name="password">
                <Input.Password placeholder="请输入密码" size="large" />
              </Form.Item>
              <Form.Item label="" colon={false} name="imgCode">
                <Input placeholder="请输入验证码" maxLength={6} size="large" suffix={
                  <img src={codeImg} style={{ height: 25 }} onClick={getCodeImg} />
                } />
              </Form.Item>

              <Form.Item label="" colon={false}>
                <Button type="primary" block size="large"  htmlType="submit">提交</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageModel;
