/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Image, Input, InputNumber, Form, message, Modal, Select, Tooltip, Table, Typography} from 'antd';
import { WarningOutlined, ZoomInOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';

import * as CONST from '../../common/const';
import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import Page from '../../components/page';
import UploadPictureList from '../../components/uploadPictureList';
import './index.less';

const PageModel = props => {
  const [form] = Form.useForm();
  const announceType = Form.useWatch('type', form);

  const [ imageFileList, setImageFileList ] = useState([]);
  const [ detailValue, setDetailValue ] = useState(null);
  const [ modalFormObj, setModalFormObj ] = useState(null);
  const [ modalVisible, setModalVisible ] = useState(false);
  const closeModalHandle = () => {
    setImageFileList([]);
    setModalVisible(false);
    setModalFormObj(null);
    setDetailValue(null);
    setTimeout(() => {
      form.resetFields();
    }, 20);
  };

  const [ list, setList ] = useState([]);
  const getList = async () => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/announce/list'
    });
    if(!result) return;

    result.data.list.map(item => {
      item.key = item.id;
      return item;
    });
    setList(result.data.list);
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const submitHandle = async (params = {}) => {
    if(!params) return;

    let url = '/zhujiajiao/v2/admin/announce/add';
    if(modalFormObj?.id) {
      params.id = modalFormObj.id;
      url = '/zhujiajiao/v2/admin/announce/update';
    }

    const result = await REQUEST.post({
      url,
      data: params
    });
    if(!result) return;

    message.success('操作成功');

    closeModalHandle();
    getList();
  };
  const deleteHandle = async (id) => {
    if(!id) return;

    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/admin/announce/delete',
      data: {
        id
      }
    });
    if(!result) return;

    message.success('操作成功');
    getList();
  };

  const ImageWithPreview = (props) => {
    // const [ showEye, setShowEye ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const { src } = props;
    return (
      <div style={{ display:'inline-block', cursor: 'pointer', position: 'relative', width: 56, height: 56, backgroundImage: `url(${src})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} onClick={() => setVisible(true)}>
        {/*{
          showEye && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', cursor: 'pointer', left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.45)',zIndex: 9 }}>
            <EyeOutlined style={{ color: '#FFFFFF', fontSize: 16 }} /><span style={{ paddingLeft: 8, color: '#FFFFFF', fontSize: 14 }}>预览</span>
          </div>
        }*/}
        <ZoomInOutlined style={{ color: 'rgba(255,255,255,0.85)', fontSize: 18, position: 'absolute', zIndex: 9, right: 8, bottom: 8 }} />
        <Image
          height={56}
          style={{ display: 'none' }}
          src={src}
          preview={{
            visible,
            src,
            onVisibleChange: value => {
              setVisible(value);
            },
          }}
        />
      </div>
    )
  };
console.log('detailValue', detailValue, modalFormObj)
  return (
    <Page {...{
      ...props,
      breadcrumbs: [ { label: '首页' }, { label: '公告列表' } ],
      title: '公告设置'
    }}>
      <section className="announce-container" style={{ minHeight: window.innerHeight }}>
        <div className="announce-top">
          <Button type="primary" onClick={async () => {
            setModalVisible(true);
            form.resetFields();
          }}>新增公告</Button>
        </div>
        <Table {...{
          size: 'small',
          locale: { emptyText: '暂无数据' },
          dataSource: list,
          columns: [
            {
              title: '封面', dataIndex: 'cover', key: 'cover',
              render: (text, record, index) => <ImageWithPreview src={CONST.imgPrefix + record.cover} />
            },
            {
              title: '标题', dataIndex: 'title', key: 'title', ellipsis: { showTitle: false },
              render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
            },
            {
              title: '描述', dataIndex: 'description', key: 'description', ellipsis: { showTitle: false },
              render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
            },
            {
              title: '类型', dataIndex: 'type', key: 'type',
              render: (text, record, index) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{CONST.announceType[text]}</span>
                  { text == 1 && <Typography.Link href={record.link} target="_blank">点击跳转</Typography.Link> }
                </div>
              }
            },
            { title: '排序', dataIndex: 'order', key: 'order', width: 88 },
            {
              title: '操作', dataIndex: 'edit', key: 'edit', width: 156, fixed: 'right',
              render: (text, record, index) => {
                const _style = { marginRight: 10,	fontSize: 12 };
                return <>
                  <Button size="small" type="primary" ghost style={_style} onClick={() => {
                    const _record = { ...record };
                    setModalFormObj(_record);
                    if(_record.detail) {
                      const detailValue = BraftEditor.createEditorState(_record.detail);
                      setDetailValue(detailValue);
                    }
                    if(_record.cover && _record.cover.length > 0) {
                      setImageFileList([_record.cover]);
                    }

                    form.setFieldsValue({ ...record });
                    setModalVisible(true);
                  }}>编辑</Button>
                  <Button type="text" danger size="small" style={_style} onClick={() => {
                    Modal.confirm({
                      title: '删除的数据将无法找回，请慎重操作！',
                      icon: <WarningOutlined style={{ color: '#E45357' }} />,
                      // content: 'Bla bla ...',
                      okText: '确认删除',
                      cancelText: '取消',
                      onOk: () => {
                        deleteHandle(record.id);
                      },
                      onCancel: () => {
                        // console.log('Cancel');
                      },
                    });
                  }}>删除</Button>
                </>
              }
            },
          ]
        }} />
      </section>

      <Modal {...{
        title: modalFormObj && '编辑' || '新增',
        width: '75%',
        visible: modalVisible,
        maskClosable: false,
        destroyOnClose: true,
        onCancel: closeModalHandle,
        footer: null
      }}>
        {
          modalVisible && <Form {...{
            initialValues: modalFormObj || undefined,
            form,
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            onFinish: values => {
              if(detailValue && detailValue.toHTML() != '<p></p>') {
                values.detail = detailValue.toHTML();
              }
              values.cover = imageFileList && imageFileList[0] || '';
              console.log(values);
              submitHandle(values);
            }
          }}>
            <Form.Item
              label="封面图"
              name="cover"
              required
              rules={[
                {
                  validator: async (_, names) => {
                    if (!imageFileList || imageFileList.length <= 0) {
                      return Promise.reject(new Error('轮播图至少需要上传1张'));
                    }
                  },
                },
              ]}
            >
              <UploadPictureList {...{
                pictureLength: 1,
                multiple: true,
                action: `${CONST.uploadPrefix}/zhujiajiao/v2/admin/announce/upload`,
                defaultFileList: imageFileList || [],
                changeHandle: fileList => {
                  setImageFileList(fileList);
                }
              }} />
            </Form.Item>
            <Form.Item label="标题" name="title" rules={[
              { required: true, message: '标题不能为空' }
            ]}>
              <Input placeholder="请输入10字以内的商城名称" allowClear />
            </Form.Item>
            <Form.Item label="描述" name="description">
              <Input.TextArea placeholder="请输入描述信息" allowClear />
            </Form.Item>
            <Form.Item label="公告类型" name="type">
              <Select placeholder="请设置公告类型">
                {
                  Object.keys(CONST.announceType).map((key, idx) => {
                    return (
                      <Select.Option key={idx} value={Number(key)}>{CONST.announceType[key]}</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="公告排序" name="order" extra={<><ExclamationCircleOutlined style={{ paddingRight: 4 }} /><span>越大越靠前</span></>}>
              <InputNumber placeholder="请输入排序" style={{ width: 200 }} />
            </Form.Item>
            {
              announceType == 1 && <Form.Item label="跳转链接" name="link">
                <Input placeholder="请输入跳转链接" allowClear />
              </Form.Item>
            }
            {
              announceType == 2 && <Form.Item label="公告详情">
                <BraftEditor
                  style={{ height: 'calc(100% - 52px)', overflow: 'auto' }}
                  value={detailValue}
                  onChange={setDetailValue}
                  media={{
                    uploadFn: Utils.editorUploadHelper({ url: '/zhujiajiao/v2/admin/announce/upload' }),
                    validateFn: (file) => {
                      return Utils.imageUploadSizeVerify(file);
                    }
                  }}
                  imageControls={[
                    'float-left', // 设置图片左浮动
                    'float-right', // 设置图片右浮动
                    'align-left', // 设置图片居左
                    'align-center', // 设置图片居中
                    'align-right', // 设置图片居右
                    'link', // 设置图片超链接
                    'size', // 设置图片尺寸
                    'remove' // 删除图片
                  ]}
                />
              </Form.Item>
            }

            <Form.Item label=" " colon={false}>
              <Button style={{ marginRight: 16 }} onClick={closeModalHandle}>取消</Button>
              <Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>
        }
      </Modal>

    </Page>
  );
};

export default PageModel;
