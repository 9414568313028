/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import ReactSwipe from 'react-swipe';

import Page from '../components/page';

import './case.less';

import IMG_CASE1 from './images/case/1.jpeg';
import IMG_CASE2 from './images/case/2.png';
import IMG_CASE3 from './images/case/3.png';

const PageCase = props => {
	return (
		<Page title="精选案例">
			<section className="case-list">
				{
					[
						{
							cover: IMG_CASE1,
							title: '这里是案例的标题文案信息，建议一行文字',
							content: '这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好',
							tags: ['党建', '时尚', '创意']
						},
						{
							cover: IMG_CASE2,
							title: '这里是案例的标题文案信息，建议一行文字',
							content: '这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好',
							tags: ['时尚', '创意']
						},
						{
							cover: IMG_CASE3,
							title: '这里是案例的标题文案信息，建议一行文字',
							content: '这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好这里是案例的描述信息，可以多行，但是建议3到4行文字会比较好',
							tags: ['党建', '70周年']
						}
					].map((item, idx) => {
						const contentDom = (
							<div className="case-content">
								<div className="title">{item.title}</div>
								<div className="description">{item.content}</div>
								<div className="tag-wrap">{ item.tags.map((tag, _idx) => <span key={_idx}>{tag}</span>) }</div>
							</div>
						);
						const coverDom = (
							<div className="case-cover" style={{ backgroundImage: `url(${item.cover})` }}></div>
						);
						return (
							<div className="case-block" key={idx}>
								{
									idx % 2 == 0 ?
										<div className="body-container case-content-wrap">
											{contentDom}
											{coverDom}
										</div> :
										<div className="body-container case-content-wrap">
											{coverDom}
											{contentDom}
										</div>
								}
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageCase;