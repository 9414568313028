/**
 * Created by jimmy on 2019/11/8.
 */

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';

export const appId = {
	// rongmeiti: 'wx6ef0bb6a5b9e3955',   // test
	// rongmeiti: 'wx598f0d10b210ba3a'    // 金泽
	// rongmeiti: 'wxa8887811053a937e',    // 融媒体
	rongmeiti: 'wx43986b362f6ff2a7',    // 三色练塘
};

export const unAuthTip = '登录信息失效，请重新登录！';
export const unSupportTip = '暂时不支持浏览器打开哦亲～';