/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Form, Select, Input, DatePicker, Row, Button, Col, Upload, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './index.less';
import * as CONST from '../../common/const';

const CompModel = props => {
	const [ fields, setFields ] = useState([]);
	const initFields = () => {
		setFields(props.fields || []);
	};
	useEffect(() => {
		initFields();
	}, [ props.fields ]);

	const doms = [];
	if(fields && fields.length > 0) {
		fields.map((item, idx) => {
			const _labelDom = <div style={{ display: 'flex', flexDirection: 'column' }}>
				{item.label}
				{ item.subLabel && <span style={{ fontSize: 10, fontWeight: 900, color: '#0d6cc0', marginTop: -4 }}>{item.subLabel}</span> }
			</div>;
			const _itemStyle = { marginBottom: 20, minWidth: 260, ...item.style };

			if(item.type == 'select') {
				doms.push(
					<Form.Item label={_labelDom} key={idx} name={item.name} style={_itemStyle}>
						<Select placeholder={item.placeholder} allowClear>
							{ item.options.length > 0 && item.options.map((item, idx) => <Select.Option value={item.id} key={idx}>{item.label}</Select.Option>) }
						</Select>
					</Form.Item>
				);
			}else if(item.type == 'input') {
				doms.push(
					<Form.Item label={_labelDom} key={idx} name={item.name} style={_itemStyle}>
						<Input placeholder={item.placeholder} allowClear />
					</Form.Item>
				)
			}else if(item.type == 'date') {
				doms.push(
					<Form.Item label={_labelDom} key={idx} name={item.name} style={_itemStyle}>
						<DatePicker placeholder={item.placeholder} style={{ width: '100%' }} />
					</Form.Item>
				)
			}else if(item.type == 'range-date') {
				doms.push(
					<Form.Item label={_labelDom} key={idx} name={item.name} style={_itemStyle}>
						<DatePicker.RangePicker placeholder={item.placeholder || [ '请输入起始时间', '请输入结束时间' ]} style={{ width: '100%' }} format="YYYY-MM-DD" />
					</Form.Item>
				)
			}
		});
	}

	const valuesHandle = (values) => {
		const data = {};
		Object.keys(values).map(k => {
			const nk = k.replace('s-', '');
			data[nk] = values[k];
		});
		return data;
	};

	const [form] = Form.useForm();

	const [ importLoading, setImportLoading ] = useState(false);
	return (
		<div className="filter-container" style={props.style}>
			<Form {...{
				layout: 'inline',
				form,
				onFinish: values => {
					const data = valuesHandle(values);
					props.filterHandle && props.filterHandle(data);
				}
			}}>
				<Row>{doms}</Row>
				<Row>
					<Form.Item label="" colon={false}>
						{ fields.length > 0 && <Button style={{ marginRight: 16 }} onClick={() => form.resetFields()}>重置</Button> }
						{ fields.length > 0 && <Button style={{ marginRight: 16 }} type="primary" htmlType="submit">查询</Button> }
						{
							fields.length > 0 && props.exportHandle && <Button style={{ marginRight: 16 }} type="primary" onClick={() => {
								const values = form.getFieldsValue();
								const data = valuesHandle(values);
								typeof props.exportHandle == 'function' && props.exportHandle(data);
							}}>导出</Button>
						}
						{
							props.newHandle && <Button style={{ marginRight: 16, alignItems: 'center' }} type="primary" onClick={() => {
								props.newHandle && props.newHandle();
							}}><i className={`icon iconfont iconadd`} style={{ marginRight: 4, fontSize: 12 }} />新增</Button>
						}
						{
							props.importAction && <div style={{display: 'inline-block'}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<Upload {...{
										name: 'file',
										action: CONST.apiPrefix + props.importAction,
										onChange: (info) => {
											setImportLoading(true);
											if (info.file.status !== 'uploading') {
												console.log(info.file, info.fileList);

											}
											if (info.file.status === 'done') {
												if(info.file.response && info.file.response.ok == 0) {
													message.success(`${info.file.name}导入成功，请刷新页面查看数据`);
												}else{
													message.error(`${info.file.name}导入失败-${info.file.response.msg}`);
												}
												setImportLoading(false);
											} else if (info.file.status === 'error') {
												message.error(`${info.file.name} file upload failed.`);
											}
										},
									}}>
										{
											importLoading &&
											<Button type="primary" icon={<LoadingOutlined />}>导入中……</Button> ||
											<Button type="primary" icon={<PlusOutlined />}>数据导入</Button>
										}
									</Upload>
									{ props.importTplLink && <a href={props.importTplLink} target="__blank" style={{marginLeft: 4, textDecoration: 'underline'}}>导入模版下载</a> }
								</div>
							</div>
						}
						{
							props.extra && <Button style={{ marginRight: 16, alignItems: 'center' }} type="primary" onClick={() => {
								props.extra.actionHandle && props.extra.actionHandle();
							}}><i className={`icon iconfont iconedit`} style={{ marginRight: 4, fontSize: 12 }} />{props.extra.btnText}</Button>
						}
					</Form.Item>
				</Row>
			</Form>
		</div>
	)
};

export default CompModel;
