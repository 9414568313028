/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './images.less';

import PageBack from './pageBackComp';

// images
import IMG_PINPAI_TITLE from './images/pinpai/title.png';
import IMG_PINPAI_ENTRANCE_1 from './images/pinpai/鹤老汇2.png';
import IMG_PINPAI_ENTRANCE_2 from './images/pinpai/楼长制2.png';
import IMG_PINPAI_ENTRANCE_3 from './images/pinpai/思享荟2.png';
import IMG_PINPAI_ENTRANCE_4 from './images/pinpai/相聚里2.png';
import IMG_PINPAI_ENTRANCE_5 from './images/pinpai/直通车2.png';

const PageModel = props => {
	return (
		<section className="baihe-images-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="title" style={{ backgroundImage: `url(${IMG_PINPAI_TITLE})` }} />
			<div className="entrances">
				{
					[
						{ icon: IMG_PINPAI_ENTRANCE_1, link: '/baihe/pinpai/pinpai1' },
						{ icon: IMG_PINPAI_ENTRANCE_2, link: '/baihe/pinpai/pinpai2' },
						{ icon: IMG_PINPAI_ENTRANCE_3, link: '/baihe/pinpai/pinpai3' },
						{ icon: IMG_PINPAI_ENTRANCE_4, link: '/baihe/pinpai/pinpai4' },
						{ icon: IMG_PINPAI_ENTRANCE_5, link: '/baihe/pinpai/pinpai5' },
					].map((item, idx) => <div className="item" key={idx} onClick={() => {
						props.history.push(item.link);
						return;
					}}>
						<img src={item.icon} />
					</div>)
				}
			</div>
		</section>
	);
};

export default PageModel;