/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import Page from '../../components/page';

import './cases.less';
import IMG_BANNER1 from './images/banner/t1.jpg';
import IMG_BANNER2 from './images/banner/t2.jpg';
import IMG_BANNER3 from './images/banner/t3.jpg';
import IMG_BANNER4 from './images/banner/t4.jpg';
import IMG_LOGO from './images/logo-black.png';

const PageContainer = props => {
	return (
  <section className="temp-container">
    <div className="temp-header" style={{ position: 'relative', backgroundColor: '#ffffff', height: 56 }}>
      <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link className="logo" style={{ backgroundImage: `url(${IMG_LOGO})`, width: 36, height: 36 }} to="/home" />
        <div className="tabs">
          <Link className="tab-item" to="/cases" style={{ textDecoration: 'underline' }}>客户案例</Link>
          <Link className="tab-item" to="/aboutus">公司简介</Link>
          <Link className="tab-item" to="/contact">联系我们</Link>
          {/*<Link className="tab-item" to="">技术支持</Link>*/}
        </div>
      </div>
    </div>
    <section className="temp-body">
      {
        [
          { cover: IMG_BANNER1, tags: ['党建', '时尚', '创意'], title: '项目一的名字简短扼要', content: '项目一的描述，简短扼要详细，突出重点，可以是摘要啥的，一般是两到三行为最佳，可以往下显示，现实不下的省略号显示，一般会控制字数' },
          { cover: IMG_BANNER2, tags: ['党建', '创意'], title: '项目二的名字简短扼要', content: '项目二的描述，简短扼要详细，突出重点，可以是摘要啥的，一般是两到三行为最佳，可以往下显示，现实不下的省略号显示，一般会控制字数' },
          { cover: IMG_BANNER3, tags: ['党建', '时尚', '创意'], title: '项目三的名字简短扼要', content: '项目三的描述，简短扼要详细，突出重点，可以是摘要啥的，一般是两到三行为最佳，可以往下显示，现实不下的省略号显示，一般会控制字数' },
          { cover: IMG_BANNER4, tags: ['党建', '时尚'], title: '项目四的名字简短扼要', content: '项目四的描述，简短扼要详细，突出重点，可以是摘要啥的，一般是两到三行为最佳，可以往下显示，现实不下的省略号显示，一般会控制字数' },
        ].map((item, idx) => {
          return (
            <div className="cases-block" key={idx} onClick={() => {
              props.history.push('/cases/123');
            }}>
              <div className="temp-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {
                  idx % 2 == 0 ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
                    <div className="message">
                      <div className="title">{item.title}</div>
                      <div className="tag-wrap">{ item.tags.map((tag, _idx) => <span key={_idx}>{tag}</span>) }</div>
                      <div className="content">{item.content}</div>
                    </div>
                  </div> : <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="message" style={{ alignItems: 'flex-end' }}>
                      <div className="title">{item.title}</div>
                      <div className="tag-wrap">{ item.tags.map((tag, _idx) => <span key={_idx}>{tag}</span>) }</div>
                      <div className="content">{item.content}</div>
                    </div>
                    <div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
                  </div>
                }
              </div>
            </div>
          )
        })
      }
    </section>
    <div className="temp-footer">
      © 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 沪ICP备19019249号-1
    </div>
  </section>
	);
};

export default PageContainer;