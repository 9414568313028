/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';

import './announce.less';

import IMG_ZJJ_BANNER from './images/announce/zjj-banner.png';

const DATA = {
  a312: {
    banner: IMG_ZJJ_BANNER,
    title: '景区公告',
    content: `
    <p>各位游客朋友：</p><br />
    <p>朱家角古镇景区已于今日（6月14日）对外开放，景区内户外公共区域恢复接待，室内景点和课植园室内场馆暂不开放，将结合疫情防控要求逐步恢复。</p>
    <p>景区将继续做好疫情防控各项举措，感谢理解和配合，请持续关注“乐游朱家角”官方发布。</p>
<!--    <p>给您带来的不便，敬请谅解！</p>-->
<!--    <p>特此公告。</p>-->
    `,
    owner: '上海朱家角古镇旅游发展有限公司',
    time: '2022年6月14日'
  }
}

const PageCIIE = props => {
  const { target } = props.match.params;
  if(!target) {
    return null;
  }
  const data = DATA[target] || {};

  // if(data.content) {
  //   data.content = data.content.replace(/\n/ig, '<br />');
  // }
  document.title = '景区公告';

  return (
    <div className="announce-container" style={{ height: window.innerHeight }}>
      {/*<div className="main-banner" style={{ backgroundImage: `url(${data.banner})` }} />*/}
      <img src={data.banner} />
      <div className="main">
        <div className="announce-title">{data.title}</div>
        <div className="announce-content">
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
          <br />
          <p className="tright">{data.owner}</p>
          <p className="tright">{data.time}</p>
        </div>
      </div>
    </div>
  )
};

export default PageCIIE;
