/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './ly_recommend.less';

// components
import SpikeComp from '../../components/spike';


// images
// import IMG_BANNER_YIKATONG from './images/banners/yikatong.jpg';
import IMG_BANNER_YIKATONG from './images/banners/yikatong_zqgq.jpeg';
import IMG_BANNER_DRAGONBOAT from './images/banners/dragonboat.jpg';
import IMG_BANNER_ZIZHU from './images/banners/净心斋素菜馆素食自助.jpeg';
import IMG_BANNER_JIANG from './images/banners/jiang.jpeg';
import IMG_BANNER_SHUISHANG from './images/banners/shuishang.jpg';
import IMG_BANNER_SDG from './images/banners/sdg.jpg';

import IMG_COVER_KA from './images/entrances/cover-ka.png';
import IMG_COVER_HOT from './images/entrances/cover-hot.png';
import IMG_COVER_DATE from './images/entrances/cover-date.png';
import IMG_ICON_DATE from './images/entrances/icon-date.png';
import IMG_ICON_DATE_ORDER from './images/entrances/icon-date-order.png';
import IMG_ICON_HOT from './images/entrances/icon-hot.png';
import IMG_ICON_ZHUSHIDAJI from './images/entrances/icon-zhushidaji.png';
import IMG_ICON_PRESENT from './images/entrances/icon-present.png';
import IMG_ICON_RESTAURANT from './images/entrances/icon-restaurant.png';
import IMG_ICON_TEA from './images/entrances/icon-tea.png';
import IMG_ICON_SALE from './images/entrances/icon-sale.png';
// import IMG_YIKATONG from './images/yikatong.jpg';
import IMG_YIKATONG from './images/yikatong_zqgq.jpg';
import IMG_ROOM_COVER from './images/room-cover.jpg';
import IMG_HOT_COVER_05 from './images/hot-cover-05.jpg';
import IMG_SELF_QR from './images/qrcode.png';

import * as DATA from './data/index';

import * as CONFIG from './config';
import * as Request from "../../common/request";
import IMG_BANNER from "../images/banner/3.png";
const LINK = CONFIG.links;

const PageModel = props => {
	let reactSwipeEl;
	const [ eventList, setEventList ] = useState([]);
	const getEventList = async () => {
		const result = await Request.get({
			url: '/zjj/recommend/hot/event'
		});
		if(!result) return;

		setEventList(result.data.list);
	};

	const [ shopList, setShopList ] = useState([]);
	const [ notes, setNotesList] = useState([]);
	const [ shopTab, setShopTab ] = useState({});
	const [ shopContentList, setShopContentList ] = useState([]);
	const [ shopFlag, setShopFlag ] = useState(false);
	const [ shopModalObj, setShopModalObj ] = useState({});
	const getShopList = async () => {
		const result = await Request.get({
			url: '/zjj/recommend/hot/shop'
		});
		if(!result) return;

		const list = result.data.list || [];
		setShopList(list);
		if(list && list[0]) {
			setShopTab(list[0]);
		}
	};
	const getNotes = async () => {
		const result = await Request.get({
			url: '/zjj/recommend/hot/notes'
		});
		if (!result) return;

		const list = result.data.list || [];
		setNotesList(list);
	};
	useEffect(() => {
		getEventList();
		getShopList();
		getNotes();
		Utils.setTitle('乐游朱家角');
	}, [123]);
	const getListByTabTitle = tabTitle => {
		let list = [];
		for(let i = 0, len = shopList.length; i < len; i++) {
			const item = shopList[i];
			if(item.tabTitle == tabTitle) {
				list = item.children;
			}
		}
		setShopContentList(list);
	};
	useEffect(() => {
		getListByTabTitle(shopTab.tabTitle);
	}, [ shopTab ]);

	const [ shopCoverIndex, setShopCoverIndex ] = useState(0);

	return (
		<section className="recommend-container">
		{
				notes && notes.length > 0 && <div className="block-part">
				<div className="block-space" />
				<div className="block-title">
						<span className="primary">游记攻略</span>
						<span>INTRODUCTION TO TRAVEL NOTES</span>
				</div>
				<div className="block-list">
					{
							notes && notes.length > 0 && notes.map((item, idx) => {
							return (
								<div className="block-item block-item-event" key={idx} style={{ backgroundImage: `url(${item.cover})` }} onClick={() => {
									if (item.link) {
										window.location.href = item.link;
										return;
									}
								}} />
							)
						})
					}
				</div>
			</div>
			}

		</section>
	);
};

export default PageModel;
