/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './matrixRender.less';

import IMG_COMMING from './images/matrixRender/comming.jpg';
// 盈浦党建
import IMG_YPDJ from './images/matrixRender/yingpudangjian/盈浦党建.jpg';
import IMG_LZZJ from './images/matrixRender/yingpudangjian/绿舟之家.jpg';
import IMG_JFJY from './images/matrixRender/yingpudangjian/解放骄阳.jpg';
import IMG_YPSM from './images/matrixRender/yingpudangjian/盈浦尚美社区.jpg';
import IMG_YLFC from './images/matrixRender/yingpudangjian/怡澜风采.jpg';
import IMG_WXSD from './images/matrixRender/yingpudangjian/温馨上达.jpg';
import IMG_TEQZX from './images/matrixRender/yingpudangjian/天恩桥在线.jpg';
import IMG_HXMX from './images/matrixRender/yingpudangjian/和谐民欣.jpg';
import IMG_YJLKX from './images/matrixRender/yingpudangjian/俞家埭快讯.jpg';
import IMG_SZPC from './images/matrixRender/yingpudangjian/崧子浦community.jpg';
import IMG_QXZD from './images/matrixRender/yingpudangjian/庆新织点.jpg';
import IMG_YLSQ from './images/matrixRender/yingpudangjian/盈联社区.jpeg';
import IMG_HZSQ from './images/matrixRender/yingpudangjian/浩泽社区居民委员会.jpg';
import IMG_NHWS from './images/matrixRender/yingpudangjian/你好万寿.jpg';
import IMG_NSC from './images/matrixRender/yingpudangjian/南厍村.png';
import IMG_YPFX from './images/matrixRender/yingpudangjian/盈浦复兴.jpg';
import IMG_DSPSQ from './images/matrixRender/yingpudangjian/淀山浦社区居民委员会.jpg';
import IMG_LWSQ from './images/matrixRender/yingpudangjian/龙威社区.jpg';
import IMG_HPSQ from './images/matrixRender/yingpudangjian/华浦社区.jpg';
import IMG_YYGW from './images/matrixRender/yingpudangjian/盈盈港湾.jpg';
import IMG_XBWS from './images/matrixRender/yingpudangjian/西部微视.jpg';

const DATA = {
  'yingpudangjian': {
    pageTitle: '盈浦微矩阵',
    list: [
      {
        title: '',
        subList: [
          { cover: IMG_YPDJ, name: '党群中心', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzODA0NDU0NQ==&scene=124#wechat_redirect' },
          { cover: IMG_LZZJ, name: '绿舟', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzOTEzMzUxMw==&scene=124#wechat_redirect' },
          { cover: IMG_JFJY, name: '解放', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzMDQ5Nzg2OA==&scene=124#wechat_redirect' },
          { cover: IMG_YPSM, name: '尚美', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5ODMxOTY1OA==&scene=124#wechat_redirect' },
          { cover: IMG_YLFC, name: '怡澜', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3ODgzMzY0OA==&scene=124#wechat_redirect' },
          { cover: IMG_WXSD, name: '上达', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5MzAzNDEyMg==&scene=124#wechat_redirect' },
          { cover: IMG_TEQZX, name: '天恩桥', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzNDM4NDg5Nw==&scene=124#wechat_redirect' },
          { cover: IMG_HXMX, name: '民欣', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU0OTYxODcxMQ==&scene=124#wechat_redirect' },
          { cover: IMG_YJLKX, name: '俞家埭', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0MTIzMjE1Mg==&scene=124#wechat_redirect' },
          { cover: IMG_SZPC, name: '崧子浦', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIwMzQwMDMxNw==&scene=124#wechat_redirect' },
          { cover: IMG_QXZD, name: '庆新', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNzc3Mjc4MA==&scene=124#wechat_redirect' },
          { cover: IMG_YLSQ, name: '盈联', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUxNDYxMTQ1Mw==&scene=124#wechat_redirect' },
          { cover: IMG_HZSQ, name: '浩泽', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNTkwMzUzOQ==&scene=124#wechat_redirect' },
          { cover: IMG_NHWS, name: '万寿', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI1NjAzMzk5OQ==&scene=124#wechat_redirect' },
          { cover: IMG_NSC, name: '南厍村', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIxNDM5OTU4Mw==&scene=124#wechat_redirect' },
          { cover: IMG_YPFX, name: '复兴', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2NjYzNDI1MQ==&scene=124#wechat_redirect' },
          { cover: IMG_DSPSQ, name: '淀山浦', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI2MDU3MDU0NA==&scene=124#wechat_redirect' },
          { cover: IMG_LWSQ, name: '龙威', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNzc4NzUzMA==&scene=124#wechat_redirect' },
          { cover: IMG_HPSQ, name: '华浦', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5OTMwMTU5Mw==&scene=124#wechat_redirect' },
          { cover: IMG_YYGW, name: '盈港', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0MzQxMDYzNw==&scene=124#wechat_redirect' },
          { cover: IMG_XBWS, name: '西部花苑', link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI3MjA1MDIxOQ==&scene=124#wechat_redirect' },
        ]
      }
    ]
  },
};

const PageModel = props => {
  const [ list, setList ] = useState([]);
  const getList = () => {
    const { target } = props.match.params;
    const _target = DATA[target] || null;
    if(_target && _target.list && _target.list.length && _target.list.length > 0) {
      setList(_target.list);
    }

    if(_target && _target.pageTitle) {
      document.title = _target.pageTitle;
    }
  };
  useEffect(() => {
    getList();
  }, [123]);

	return (
    <div className="matrix-render-container" style={{ minHeight: window.innerHeight }}>
      {
        list && list.length > 0 && list.map((item, idx) => {
          return (
            <div className="list" key={idx}>
              { item.title && <div className="title">{item.title}</div> }
              <div className="sub-list">
                {
                  item.subList && item.subList.length && item.subList.length > 0 && item.subList.map((_item, _idx) => {
                    return (
                      <div className="item" key={_idx} onClick={() => {
                        window.location.href = _item.link;
                        return;
                      }}>
                        <div className="cover" style={{ backgroundImage: `url(${_item.cover})` }} />
                        <span className="name">{_item.name}</span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
};

export default PageModel;
