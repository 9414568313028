/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as EXIF from 'exif-js';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as WX from '../../common/wx';

import './xueLeifeng.less';

// source
import IMG_HEADER from './images/xueLeifeng/header.png';

const activityName = 'xueLeiFeng';
const { isInWechat, isInRongMeiApp, isIOS, isAndroid } = Utils.getUA();

const PageCTownProtect = props => {
  const [ formObj, setFormObj ] = useState({ name: '', slogan: '', imgPath: '' });
  const [ imgContent, setImgContent ] = useState('');
  const [ activityInfo, setActivityInfo ] = useState({});
  const [ formFlag, setFormFlag ] = useState(false);
  const [ maskFlag, setMaskFlag ] = useState(false);
  const [ ruleFlag, setRuleFlag ] = useState(false);
  const [ list, setList ] = useState([]);
  const [ playState, setPlayState ] = useState(false);
  const [ showMusic, setShowMusic ] = useState(false);
  const [ fightFlag, setFightFlag ] = useState(false);

  /**
   * initial activity information
   */
  const shareImgConfig = (shareConfig = {}) => {
    const div = document.createElement('div');
    div.style.display = 'none';
    const img = document.createElement('img');
    img.src = shareConfig.imgUrl;
    div.appendChild(img);

    const bodyFirst = document.body.firstChild;
    document.body.insertBefore(div, bodyFirst);
  };
  const initialActivityConfig = async () => {
    const result = await Request.get({
      headers: { 'Activity-Name': activityName },
      url: '/activity/config'
    });
    if(!result) return;

    const data = result.data || {};
    setActivityInfo(data);

    // initial page title
    if(data.config && data.config.title) {
      Utils.setTitle(data.config.title);
    }

    shareImgConfig(data.shareConfig);

    const audio1 = document.getElementById('audio1');
    if(isAndroid) {
      audio1.addEventListener('canplay', function () {
        setShowMusic(true);

        audio1.play();
        setPlayState(true);
      });
    }else{
      setTimeout(() => {
        setShowMusic(true);
      }, 1500);
    }
    setTimeout(() => {
      if(!showMusic) {
        setShowMusic(true);
      }
    }, 30000);
  };
  useEffect(() => {
    initialActivityConfig();
  }, [123]);

  const imagesInitial = (initialSlide = null) => {
    setTimeout(() => {
      new window.Swiper('.swiper-container', {
        initialSlide,
        slidesPerView: 1.5,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    }, 250);
  };
  const getList = async () => {
    const result = await Request.get({
      headers: { 'Activity-Name': activityName },
      url: '/activity/list/get'
    });
    if(!result) return;

    const list = result.data.list || [];
    setList(list);

    const myobj = localStorage.getItem('myobj');
    list.map(item => {
      item = JSON.stringify(item);
      return item;
    });
    const idx = !!myobj && list.indexOf(myobj) || 0;
    imagesInitial(idx);
  };
  useEffect(() => {
    getList();
  }, [123]);

	return (
    <section className="xue-leifeng-container" style={{ height: window.innerHeight }}>
      {
        showMusic && <div className="music music-move" style={{ animationPlayState: playState ? '' : 'paused' }} onClick={() => {
          const audio1 = document.getElementById('audio1');
          if(playState) {
            audio1.pause();
          }else{
            audio1.play();
          }
          setPlayState(!playState);
        }} />
      }
      <div style={{ display: 'none' }}>
        <audio controls="controls" id="audio1">
          {/*<source src="song.ogg" type="audio/ogg" />*/}
          <source src="http://www.yeekin.cn/activity/%E9%82%A3%E8%8B%B1%20-%20%E6%98%A5%E6%9A%96%E8%8A%B1%E5%BC%80.mp3" type="audio/mpeg" />
        </audio>
      </div>
      <div className="banner" style={{ backgroundImage: `url(${IMG_HEADER})` }} />
      <div className="rule-entrance" onClick={() => {
        setRuleFlag(true);
        setMaskFlag(true);
      }}><span>活动说明</span></div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {
            list.map((item, idx) => {
              return (
                <div className="swiper-slide" key={idx}>
                  <div style={{ padding: 10, display: 'flex', flex: 1, flexDirection: 'column', width: 'calc(100% - 20px)' }}>
                    <div className="cover" style={{ backgroundImage: `url(/upload/${item.imgPath})` }} />
                    <span className="name">{item.name}</span>
                    <span className="slogan">{item.slogan}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
        {/*<div className="swiper-pagination"></div>*/}
      </div>
      <div className="power-pass-ctrl" onClick={() => {
        setFormFlag(true);
        setMaskFlag(true);
        {/*setFightFlag(true);*/}
        {/*setTimeout(() => {*/}
          {/*setFightFlag(false);*/}
        {/*}, 2000);*/}
      }}>传递能量</div>


      { maskFlag && <div className="mask" /> }
      {
        formFlag && <div style={{ position: 'relative' }}>
          <div className="pass-power-layer">
            <div className="upload-input">
              <div className="cover" id="pop-cover" style={{ backgroundImage: `url(${imgContent})` }}>{!imgContent ? '+' : ''}</div>
              <input type="file" accept="image/jpeg, image/jpg, image/png" onChange={e => {
                const file = e.target.files[0];
                const cvs = document.createElement('canvas');
                const cvsCtx = cvs.getContext('2d');
                if(file){
                  const url = window.URL.createObjectURL(file);
                  const img = new Image();
                  img.src = url;
                  img.onload = function () {
                    let ratio = img.naturalWidth / img.naturalHeight;
                    cvs.width = 400;
                    cvs.height = cvs.width / ratio;

                    EXIF.getData(this, async function() {
                      let orientation = EXIF.getTag(this, "Orientation");
                      if(orientation == 6) {
                        cvsCtx.rotate(90*Math.PI/180);
                        cvsCtx.translate(0, -cvs.height);
                      }else if(orientation == 8) {
                        cvsCtx.rotate(-90*Math.PI/180);
                        cvsCtx.translate(0, -cvs.height);
                      }

                      cvsCtx.drawImage(img, 0, 0, cvs.width, cvs.height);

                      const base64 = cvs.toDataURL("image/png");
                      const result = await Request.post({
                        headers: { 'Activity-Name': activityName },
                        url: '/activity/upload',
                        data: {
                          base64
                        }
                      });
                      if(!result) return;

                      formObj.imgPath = result.data.img;
                      setFormObj(formObj);
                      setImgContent(base64);
                    });
                  }
                }
              }} />
            </div>
            <input className="text-input" placeholder="你的名字" maxLength="10" onChange={e => {
              formObj.name = e.target.value;
              setFormObj(formObj);
            }} />
            <input className="text-input" placeholder="你的口号" maxLength="28" onChange={e => {
              formObj.slogan = e.target.value;
              setFormObj(formObj);
            }} />
            <div className="submit" onClick={async () => {
              if(!formObj.imgPath || !formObj.name || !formObj.slogan) {
                Utils._alert('完善信息，传输能量哦');
                return;
              }
              const result = await Request.post({
                headers: { 'Activity-Name': activityName },
                url: '/activity/list/set',
                data: { ...formObj }
              });
              if(!result) return;

              setMaskFlag(false);
              setFormFlag(false);
              setImgContent('');
              setFormObj({ name: '', slogan: '', imgPath: '' });

              localStorage.setItem('myobj', JSON.stringify(formObj));

              {/*Utils._alert('提交成功');*/}

              setFightFlag(true);
              setTimeout(() => {
                setFightFlag(false);
              }, 2000);

              getList();
            }} >能量传输</div>
          </div>
          <div className="close" style={{ bottom: '6%' }} onClick={() => {
            setMaskFlag(false);
            setFormFlag(false);
          }} />
        </div>
      }
      {
        fightFlag && <div className="fight-gif" style={{ backgroundImage: `url(/activity/xueleifeng/fight.gif)` }} />
      }
      {
        ruleFlag && <div style={{ position: 'relative' }}>
          <div className="rule-layer">
            <span className="title">规则规则</span>
            <span>3月来了</span>
            <span>这是春暖花开的季节</span>
            <span>也是学雷锋的歌声最嘹亮的季节</span>
            <span>2020年，一个不同寻常的年份</span>
            <span>我们投身疫情防控阻击战，彰显个人担当、无私奉献，志愿服务精神闪闪发光，谱写了全社会携手同心、同抗疫情的大爱赞歌。</span>
            <span>战役之中，我们争做战士赴前线</span>
            <span>联防联控，筑牢每段防疫墙</span>
            <span>支援前线，争当志愿者献爱心</span>
            <span>复产复工，只争朝夕抓生产</span>
            <span>爱党爱国，做新时代向上向善好青年</span>
            <span>追逐梦想，为复兴大业奋斗</span>
            <span>乐于奉献，助人为乐暖人心</span>
            <span>敬业乐业，做闪光的螺丝钉</span>
            <span>3月5日，向雷锋同志学习，我们做了好事，留下姓名，担当社会责任，传递志愿精神。今天，请您发布自己做好事的照片，留下您的名字，我们一起发扬雷锋精神，传播正能量，接过接力棒，把爱传递下去。</span>
          </div>
          <div className="close" onClick={() => {
            setMaskFlag(false);
            setRuleFlag(false);
          }} />
        </div>
      }
    </section>
	);
};

export default PageCTownProtect;