/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './video.less';

// components
import PageBack from './pageBackComp';


// images
import IMG_VIDEO_1 from './images/video-1.png';
import IMG_VIDEO_2 from './images/video-2.png';
import IMG_VIDEO_3 from './images/video-3.png';

const PageModel = props => {
	const [ targetVideo, setTargetVideo ] = useState(null);

	return (
		<section className="baihe-video-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="entrances">
				{
					[
						{ icon: IMG_VIDEO_1, text: '社区中心宣传片', src: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/社区中心宣传片.mp4' },
						{ icon: IMG_VIDEO_2, text: '幸福社区', src: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/幸福社区.mp4' },
						{ icon: IMG_VIDEO_3, text: '幸福云', src: 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/幸福云.mp4' },
					].map((item, idx) => <div className="item" key={idx} onClick={() => {
						setTargetVideo(item);
					}}>
						<img src={item.icon} />
					</div>)
				}
			</div>

			{
				targetVideo && <div className="video-displayer-wrapper">
					<div className="close" onClick={() => setTargetVideo(null)} />
					<video className="video-displayer" controls={true} autoPlay={true} width={window.innerWidth}>
						<source src={targetVideo.src} type="video/mp4" />
					</video>
				</div>
			}
		</section>
	);
};

export default PageModel;
