/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import * as Utils from '../../common/utils';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
import * as WX from '../../common/wx';

import './ciie.less';

// source
import IMG_POSTCARD_1 from './images/postcard/1.png';
import IMG_POSTCARD_1s from './images/postcard/1s.png';
import IMG_POSTCARD_5 from './images/postcard/5.png';
import IMG_POSTCARD_5s from './images/postcard/5s.png';
import IMG_POSTCARD_6 from './images/postcard/6.png';
import IMG_POSTCARD_6s from './images/postcard/6s.png';
import IMG_POSTCARD_7 from './images/postcard/7.png';
import IMG_POSTCARD_7s from './images/postcard/7s.png';
// import IMG_POSTCARD_2 from './images/postcard/2.png';
// import IMG_POSTCARD_2s from './images/postcard/2s.png';
// import IMG_POSTCARD_3 from './images/postcard/3.png';
// import IMG_POSTCARD_3s from './images/postcard/3s.png';
// import IMG_POSTCARD_4 from './images/postcard/4.png';
// import IMG_POSTCARD_4s from './images/postcard/4s.png';

const LIST = [
	{ src: IMG_POSTCARD_1, srcs: IMG_POSTCARD_1s, rotate: 6 },
	{ src: IMG_POSTCARD_7, srcs: IMG_POSTCARD_7s, rotate: -2 },
	{ src: IMG_POSTCARD_5, srcs: IMG_POSTCARD_5s, rotate: 2 },
	{ src: IMG_POSTCARD_6, srcs: IMG_POSTCARD_6s, rotate: -4 },
	// { src: IMG_POSTCARD_3, rotate: 2 },
	// { src: IMG_POSTCARD_3, rotate: 7 },
	// { src: IMG_POSTCARD_3, rotate: -3 },
	// { src: IMG_POSTCARD_3, rotate: -6 },
	// { src: IMG_POSTCARD_3, rotate: 1 },
	// { src: IMG_POSTCARD_3, rotate: 5 },
];


const activityName = 'ciie';
const appDownloadLink = 'https://www.shmedia.tech/qpapp';
const _resTxt = '牛逼哄哄的好玩的给你换个脸怎么样，很好玩的呀，哈哈哈～';
const pageSource = Utils.getSearchByKey('source');
let btnLock = false;

const PageCIIE = props => {
	// initial page by page source
	const [ page, setPage ] = useState(({ share: 6, rank: 7, votecode: 8 })[pageSource] || 1);
	
	// initial some modal flag
	const [ ruleFlag, setRuleFlag ] = useState(false);
	const [ voteCodeFlag, setVoteCodeFlag ] = useState(false);
	const [ shareTipFlag, setShareTipFlag ] = useState(false);
	
	// initial source list config local
	const [ slist, setSlist ] = useState([]);
	const [ rlist, setRlist ] = useState([]);
	
	// initial base64 for imgs
	const [ screen, setScreen ] = useState({});
	const [ role, setRole ] = useState({});
	const [ resImg, setResImg ] = useState('');
	const [ resText, setResText ] = useState('');
	const [ uploadImg, setUploadImg ] = useState('');
	
	// initial user information
	const [ userInfo, setUserInfo ] = useState({});
	
	// initial rank list information when page source is rank
	const [ voteList, setVoteList ] = useState([]);
	
	// initial vote token
	const [ voteToken, setVoteToken] = useState('');
	
	// initial vote code
	const [ voteCode, setVoteCode] = useState('');
	const [ voteCode2, setVoteCode2] = useState('');
	
	// initial activity information
	const [ activityInfo, setActivityInfo ] = useState({});

	// initial front user agent
	const { isInWechat, isInRongMeiApp } = Utils.getUA();

	/**
	 * initial source list
	 */
	useEffect(() => {
		if(!isInWechat) return;
		initialSourceList();
	}, [123]);
	const initialSourceList = () => {
		const slist = LIST.map(item => ({ ...item, selected: false }));
		setSlist(slist);
		
		// const rlist = LIST[0].list.map(item => ({ ...item, selected: false }));
		// setRlist(rlist);
	};
	
	/**
	 * initial activity information
	 */
	useEffect(() => {
		initialActivityConfig();
	}, [123]);
	const initialActivityConfig = async () => {
		const result = await Request.get({
			headers: { 'Activity-Name': activityName },
			url: '/activity/config'
		});
		if(!result) return;
		
		const data = result.data || {};
		setActivityInfo(data);
		
		// initial page title
		if(data.config && data.config.title) {
			Utils.setTitle(data.config.title);
		}
		
		// initial share loading page information
		// mainly means generated photo
		if(pageSource == 'share'){
			const stoken = Utils.getSearchByKey('stoken');
			if(data.info && data.info.photo && data.info.photo[stoken]){
				const photo = data.info.photo[stoken];
				setResImg(photo);
			}
		}
	};
	
	/**
	 * initial activity information about user
	 */
	useEffect(() => {
		if(!isInWechat) return;
		initialActivityInfo();
	}, [userInfo.yid]);
	const initialActivityInfo = async () => {
		const stoken = Utils.getSearchByKey('stoken');
		console.log('stoken', stoken);
		const result = await Request.get({
			headers: { 'Activity-Name': activityName },
			url: '/activity/info',
			data: {
				stoken
			}
		});
		if(!result) return;
		
		const data = result.data || {};
		// initial share loading page information
		// mainly means generated photo
		if(pageSource == 'share'){
			if(data.photo) setResImg(data.photo);
			if(data.txt) setResText(data.txt || _resTxt);
		}
	};
	
	/**
	 * initial user information
	 */
	useEffect(() => {
		if(!isInWechat) return;
		initialUserInfo();
	}, [123]);
	const initialUserInfo = async () => {
		const info = await Utils.user.getInfo();
		if(info && info.yid && info.nickname) {
			setUserInfo(info);
		}else{
			const info = await Utils.user.login();
			setUserInfo(info);
		}
	};
	
	/**
	 * initial share config
	 * mainly for wechat
	 */
	useEffect(() => {
		if(!isInWechat) return;
		initialShare();
	}, [userInfo.yid, JSON.stringify(activityInfo)]);
	const initialShare = async () => {
		await WX.sign();
		setTimeout(() => {
			WX.share({
				...activityInfo.config
			});
		}, 666);
	};
	
	/**
	 * initial list of vote
	 */
	useEffect(() => {
		voteListInitial()
	}, [123]);
	const voteListInitial = async () => {
		if(pageSource != 'rank') return;

		const result = await Request.get({
			headers: { 'Activity-Name': activityName },
			url: `/activity/vote/list`
		});
		if(!result) return;

		setVoteList(result.data.list || []);
	};
	
	// action when ask for vote
	const voteHandle = async () => {
		if(!voteCode) {
			Utils._alert('投票码不能为空');
			return;
		}
		const stoken = Utils.getSearchByKey('stoken');
		const result = await Request.post({
			headers: { 'Activity-Name': activityName },
			url: '/activity/vote',
			data: {
				stoken,
				code: voteCode
			}
		});
		if(!result) return;
		Utils._alert('投票成功');
	};
	
	// get vote token
	const getVoteToken = async (callback) => {
		const stoken = Utils.getSearchByKey('stoken');
		if(!stoken) return;
		
		const result = await Request.get({
			url: '/activity/token',
			data: {
				stoken
			}
		});
		if(!result) return;
		
		setVoteToken(result.data.token);
		callback && callback();
	};
	
	/**
	 * do face merge action
	 * @param merge
	 * @param template
	 * @param callback
	 * @returns {Promise.<void>}
	 */
	const doMerge = async (merge, template, callback) => {
		// merge = debugPhoto.merge;
		// template = debugPhoto.template;
		const result = await Request.post({
			headers: { 'Activity-Name': activityName },
			url: '/activity/ai/mergeface',
			data: {
				txt: resText,
				merge,
				template
			}
		});
		if(!result) return;

		return result.data.result;
	};
	
	/**
	 * only support used in Wechat and RongMeiTi App
	 */
	if(!isInWechat && !isInRongMeiApp) {
		return <span style={{ color: '#585252', textAlign: 'center', marginTop: window.innerHeight / 3, fontSize: 14, display: 'block' }}>{CONST.unSupportTip}</span>;
	}

	return (
		<section className="ciie-container" style={{ height: window.innerHeight }}>
			{ /* home / intro */ }
			<div className={`page page1${page == 1 ? ' show' : ''}`}>
				<div className="start-btn allbtn" onClick={() => {
					setPage(2);
				}}><span>开始体验</span></div>
				<div className="rule" onClick={() => setRuleFlag(true)}>活动说明</div>

				{
					ruleFlag && <div className="rule-wrap">
						<div className="mask" onClick={() => setRuleFlag(false)} />
						<div style={{ position: 'relative', zIndex: 99 }}>
							<div className="title">活动规则</div>
							<div className="content" style={{ maxHeight: window.innerHeight * 0.6 }}>
								<span>1. 活动时间：2019年11月13日 00:00 – X月X日 00:00</span>
								<span>2. 活动期间，可以自行上传带头像的照片，DIY喜欢的进博会场景生成属于你自己的明信片，并分享给朋友为自己拉一票。投票期间，活动页面实时更新排名情况，可在<a href={appDownloadLink}>“绿色青浦”APP</a>中查看</span>
								<span>3. 活动结束后，我们将会对投票数在前X名的用户赠送精美礼品一份；请在活动期间在“绿色青浦”APP的活动页中备注自己的邮寄信息和联系方式，方便我们寄出礼品</span>
								<em>* 礼品以实物为准，邮寄仅限大陆地区地址；</em>
								<em>* 青浦融媒体中心拥有对本次活动的最终解释权</em>
							</div>
						</div>
					</div>
				}
			</div>

			{ /* screen */ }
			<div className={`page page2${page == 2 ? ' show' : ''}`}>
				<div className="page-title"><span>选择你喜欢的场景</span></div>
				<div className="screen-container">
					{
						slist.map((item, idx) => {
							return (
								<div className={`screen-item${item.selected ? ' selected' : ''}`} key={idx} style={{ transform: `rotate(${item.rotate}deg)` }}>
									<div
										className="cover"
										style={{
											backgroundImage: `url(${item.srcs})`
										}}
										onClick={async () => {
										const _slist = slist.map((list, lidx) => {
												list.selected = lidx == idx ? !list.selected : false;
												return list;
											});
											setSlist(_slist);
											
											const selectedList = _slist.filter(l => !!l.selected);
											if(selectedList.length <= 0) {
												return setScreen('');
											}
											
											setScreen(item);
										}}
									><span /></div>
								</div>
							)
						})
					}
				</div>
				<div className="next-btn allbtn" onClick={() => {
					if(!screen.src) {
						Utils._alert('场景要选一下哦');
						return;
					}
					setPage(4);
				}}><span>下一步</span></div>
			</div>

			{ /* role */ }
			{/*<div className={`page page3${page == 3 ? ' show' : ''}`}>*/}
				{/*<div className="page-title">选择角色</div>*/}
				{/*<div className="role-container">*/}
					{/*{*/}
						{/*rlist.map((item, idx) => {*/}
							{/*if(item.type == screen.type) {*/}
								{/*return (*/}
									{/*<div className={`role-item${item.selected ? ' selected' : ''}`} key={idx} style={{ backgroundImage: `url(${item.src})` }} onClick={async () => {*/}
										{/*const _rlist = rlist.map((list, lidx) => {*/}
											{/*list.selected = lidx == idx ? !list.selected : false;*/}
											{/*return list;*/}
										{/*});*/}
										{/*setRlist(_rlist);*/}
										{/**/}
										{/*const selectedList = _rlist.filter(l => !!l.selected);*/}
										{/*if(selectedList.length <= 0) {*/}
											{/*return setRole('');*/}
										{/*}*/}
										{/**/}
										{/*setRole(item);*/}
									{/*}}><span /></div>*/}
								{/*)*/}
							{/*}*/}
						{/*})*/}
					{/*}*/}
				{/*</div>*/}
				{/*<div className="next-btn allbtn" onClick={() => {*/}
					{/*if(!role.src) {*/}
						{/*Utils._alert('请选择角色');*/}
						{/*return;*/}
					{/*}*/}
					{/*setPage(4);*/}
				{/*}}><span>下一步</span></div>*/}
			{/*</div>*/}

			{ /* upload photo and text */ }
			<div className={`page page4${page == 4 ? ' show' : ''}`} id="page4">
				<div className="page-title"><span>合成明信片</span></div>
				<div className="input-wrap">
					<input type="file" id="file1" name="file1" onChange={e => {
						const file1 = document.getElementById('file1').files[0];
						if(file1) {
							setUploadImg('');
							Utils.imageUtil.yasuo(file1, base64 => {
								setUploadImg(base64);
							});
						}
					}} />
					<div className="preview" style={{ backgroundImage: `url(${uploadImg})` }} />
					<em className="icon-camera">请上传大头照，尽量大而清晰</em>
				</div>
				<div className="next-btn allbtn" onClick={async () => {
					if(!uploadImg) {
						return Utils._alert('先上传你的靓照哦');
					}
					if(btnLock) return;
					btnLock = true;
					const screenBase64 = await Utils.imageUtil.toBase64(screen.src);
					const result = await doMerge(uploadImg, screenBase64);
					setResImg(result);
					btnLock = false;
					setPage(5);
				}}><span>开始合成</span></div>
			</div>

			{ /* merge result */ }
			<div className={`page page5${page == 5 ? ' show' : ''}`}>
				<div className="xiongmao" />
				<div className="photo-wrap">
					<div className="photo" style={{ backgroundImage: `url(${resImg})` }} />
					<div className="txt">
						<div className="zhang" />
					</div>
					<div className="txt-space" />
				</div>
				<div className="btn-wrap">
					<div className="next-btn allbtn" onClick={() => {
						window.location.reload();
					}}><span>重新拍</span></div>
					<div className="next-btn allbtn" onClick={() => {
						WX.share({
							...activityInfo.shareConfig,
							link: `${window.location.origin}/activity/ciie?source=share&stoken=${userInfo.yid}`
						}, false, () => {
							setShareTipFlag(true);
						});
					}}><span>分享求投票</span></div>
				</div>
				
				{
					shareTipFlag && <div className="sharetip-wrap">
						<div className="share-tip" />
						<div className="share-btn" onClick={() => setShareTipFlag(false)} />
					</div>
				}
			</div>

			{ /* loading page for share */ }
			<div className={`page page6${page == 6 ? ' show' : ''}`}>
				<a className="toplay" href="/activity/ciie" />
				<div className="xiongmao" style={{ top: page == 6 ? 20 : 0 }} />
				<div className="photo-wrap">
					<div className="photo" style={{ backgroundImage: `url(${resImg})` }} />
					<div className="txt">
						<div className="zhang" />
					</div>
					<div className="txt-space" />
				</div>
				<div className="btn-wrap">
					<a className="btn-item allbtn" href='/activity/ciie?source=rank'><span>查看排名</span></a>
					<div className="btn-item allbtn" onClick={() => {
						getVoteToken(() => {
							setVoteCodeFlag(true);
						});
					}}><span>去投票</span></div>
				</div>
				{/*<div className="allbtn" style={{ width: '75%', margin: '-16px auto 26px', borderRadius: 80, border: 'solid 2px #ffffff' }}><span>玩一下</span></div>*/}
				
				{
					voteCodeFlag && <div className="votecode-wrap">
						<div className="mask" onClick={() => {
							setVoteToken('');
							setVoteCodeFlag(false);
						}}/>
						<div className="token-content-container">
							<div className="token-wrap">
								<div className="token-intro">亲，复制以下<em>随机码</em>到青浦融媒体APP中换取<em>投票码</em></div>
								<div className="token-txt" id="voteToken">
									{voteToken}
									<CopyToClipboard text={voteToken} onCopy={() => Utils._alert('已复制')}>
										<div className="guide-btn">复制</div>
									</CopyToClipboard>
								</div>
								<div className="code-txt">
									<input placeholder="粘贴投票码到这里哦" onChange={e => {
										const value = e.target.value;
										setVoteCode(value);
									}} />
								</div>
							</div>
							<div className="btns-wrap">
								<div className="allbtn" onClick={() => window.location.href = appDownloadLink}><span>下载APP</span></div>
								<div className="allbtn" onClick={voteHandle}><span>投一票</span></div>
							</div>
						</div>
					</div>
				}
			</div>

			{ /* rank */ }
			<div className={`page page7${page == 7 ? ' show' : ''}`}>
				<div className="page-title"><span>排行榜</span></div>
				<div className="rank-top">
					{
						[
							{ ...voteList[1], rank: 2 },
							{ ...voteList[0], rank: 1 },
							{ ...voteList[2], rank: 3 },
						].map((item, idx) => {
							if(!item.user) {
								return <div className={`rank-item rank-item${item.rank}`} key={idx} />;
							}

							return <div className={`rank-item rank-item${item.rank}`} key={idx}>
								<div className="hat" />
								<div className="rank-item-inner">
									<div className="rank-cover"><span className="img" style={{ backgroundImage: `url(${item.user.headimgurl})` }} /></div>
									<span>{item.user.nickname}</span>
								</div>
							</div>
						})
					}
				</div>
				<div className="rank-body">
					{
						voteList.map((item, idx) => {
							return <div className="rank-body-item" key={idx}>
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<span className="order" style={{ color: '#f9d110' }}>{idx + 1}</span>
									<div className="cover" style={{ backgroundImage: `url(${item.user.headimgurl})` }} />
									<span className="name">{item.user.nickname}</span>
								</div>
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div className="zan">{item.cnt}</div>
									<a className="vote" href={`/activity/ciie?source=share&stoken=${item.user.stoken}`}>去投票</a>
								</div>
							</div>
						})
					}
				</div>
			</div>
			
			{ /* vote code page */ }
			<div className={`page page8${page == 8 ? ' show' : ''}`}>
				<div className="page-title"><span>生成投票码</span></div>
				<div className="random-code-wrap">
					<textarea placeholder="粘贴随机码" onChange={async (e) => {
						const token = e.target.value;
						if(!token) return;
						const result = await Request.get({
							hideError: true,
							headers: { 'Activity-Name': activityName },
							url: '/activity/code',
							data: {
								token
							}
						});
						if(!result) return;
						
						setVoteCode2(result.data && result.data.code || '错误随机码');
					}} />
				</div>
				<div className="vote-code-wrap">
					<div className="votecode-tip" />
					<em>{voteCode2}</em>
					<div className="vote-btns">
						<CopyToClipboard text={voteCode2} onCopy={() => Utils._alert('已复制')}>
							<div className="allbtn vote-code-copy"><span>复制</span></div>
						</CopyToClipboard>
						<a className="allbtn check-ranklist" href="/activity/ciie?source=rank"><span>排行榜</span></a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PageCIIE;