/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './home.less';

// components
import SpikeComp from '../../components/spike';


// images
import IMG_ENTRANCE_1 from './images/home-entrance-1.png';
import IMG_ENTRANCE_2 from './images/home-entrance-2.png';
import IMG_ENTRANCE_3 from './images/home-entrance-3.png';
import IMG_ENTRANCE_4 from './images/home-entrance-4.png';
import IMG_KONGJIAN_ENTRANCE from './images/home-kongjian.png';

const PageModel = props => {
	const [ kongjianFlag, setKongjianFlag ] = useState(false);

	return (
		<section className="baihe-home-container" style={{ height: window.innerHeight }}>
			<div className="entrances">
				{
					[
						{ icon: IMG_ENTRANCE_1, link: '/baihe/video' },
						{ icon: IMG_ENTRANCE_3, link: '/baihe/shequ' },
						{ icon: IMG_ENTRANCE_2, link: '/baihe/daibiao' },
						{ icon: IMG_ENTRANCE_4, link: '/baihe/red-story' },
					].map((item, idx) => <div className="item" key={idx} onClick={() => {
						props.history.push(item.link);
						return;
					}}>
						<img src={item.icon} />
					</div>)
				}
			</div>
			<div className="kongjian-entrances"><img src={IMG_KONGJIAN_ENTRANCE} onClick={() => setKongjianFlag(true)} /></div>

			{
				kongjianFlag && <div className="kongjian-display">
					<div className="close" onClick={() => setKongjianFlag(false)} />
				</div>
			}
		</section>
	);
};

export default PageModel;
