/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
//引入组件
import { PhotoProvider, PhotoView } from "react-photo-view";
//引入css
import "react-photo-view/dist/react-photo-view.css";


const PageModel = props => {
	return (
		<div className="App">
			开发中....
		</div>
	);
};

export default PageModel;
