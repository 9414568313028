/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Image, Input, DatePicker, InputNumber, Form, message, Modal, Select, Tooltip, Table, Typography} from 'antd';
import { WarningOutlined, ZoomInOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import * as CONST from '../../common/const';
import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import Page from '../../components/page';
import UploadPictureList from '../../components/uploadPictureList';
import './index.less';
import moment from "moment";
import FilterForm from "../../../../../components/filterForm";

const PageModel = props => {
  const [form] = Form.useForm();

  const [ list, setList ] = useState([]);
  const getList = async (data = {}) => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/cruise/reserve-list',
      data
    });
    if(!result) return;

    result.data.list.map(item => {
      item.key = item.id;
      return item;
    });
    setList(result.data.list);
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const exportHandle = async (data = {}) => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/admin/cruise/reserve-list/export',
      data
    });
    if(!result) return;
    window.open(result.data.path);
  };

  return (
    <Page {...{
      ...props,
      breadcrumbs: [ { label: '首页' }, { label: '游船预约订单列表' } ],
      title: '游船预约订单'
    }}>
      <section className="cruise-schedule-container" style={{ minHeight: window.innerHeight }}>
        {
          list?.length > 0 && <FilterForm {...{
            style: { marginTop: 20, marginBottom: 0 },
            fields: [
              {
                type: 'select', label: '预约日期', name: 's-scheduleDate', placeholder: '请选择', options: (() => {
                  const arr = [];
                  const arrMap = {};
                  list.map(lst => {
                    if(!arrMap[lst.scheduleDate]) {
                      arrMap[lst.scheduleDate] = true;
                      arr.push({ id: lst.scheduleDate, label: lst.scheduleDate })
                    }
                  });
                  return arr;
                })()
              },
            ],
            filterHandle: data => {
              getList(data);
            },
            exportHandle
          }} />
        }

        <Table {...{
          style: { marginTop: 20 },
          size: 'small',
          locale: { emptyText: '暂无数据' },
          dataSource: list,
          columns: [
            { title: '游客姓名', dataIndex: 'traveller', key: 'traveller' },
            { title: '手机号', dataIndex: 'mobile', key: 'mobile' },
            { title: '预约数量', dataIndex: 'count', key: 'count' },
            {
              title: '预约出行日期与时段', dataIndex: 'scheduleDate', key: 'scheduleDate',
              render: (text, record, index) => <span>{record.scheduleDate} {record.scheduleTime}</span>
            },
            {
              title: '提交时间', dataIndex: 'createdAt', key: 'createdAt',
              render: (text, record, index) => <span>{text && moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
            }
          ]
        }} />
      </section>
    </Page>
  );
};

export default PageModel;
