/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import * as Utils from '../../common/utils';
import * as DATA from './data/index';

import './storeList.less';
import * as CONFIG from './config';

const PageModel = props => {
	useEffect(() => {
		Utils.addICO('/images/zhujiajiaov2/favicon.ico');
	}, []);

	const [ tab, setTab ] = useState(null);
	const getTab = () => {
		const tab = Utils.getSearchByKey('tab') || 'restaurant';
		setTab(tab);
	};
	useEffect(() => {
		getTab();

		Utils.setTitle('一卡通商铺列表');
	}, [123]);

	const [ tabs, setTabs ] = useState([]);
	const getTabs = () => {
		const tabs = [];
		Object.keys(DATA.storeList).map(tab => {
			tabs.push({
				id: tab,
				tabName: DATA.storeList[tab].tabName
			});
		});
		setTabs(tabs);
	};
	useEffect(() => {
		getTabs();
	}, [tab]);

	const [ list, setList ] = useState([]);
	const getList = () => {
		if(DATA.storeList[tab] && DATA.storeList[tab].list) {
			setList(DATA.storeList[tab].list);
			let list = DATA.storeList[tab].list || [];
			list = list.sort(function (a, b) {
				return a.order - b.order;
			});
			setList(list);
		}
	};
	useEffect(() => {
		getList();
	}, [tab]);

	return (
		<section className="storelist-container" style={{ height: window.innerHeight }}>
			<div className="design" />
			<div className="tabs">
				{
					tabs && tabs.length > 0 && tabs.map((_tab, _idx) => {
						return (
							<div className={`item${_tab.id == tab ? ' active' : ''}`} key={_idx} onClick={() => {
								setTab(_tab.id);
								window.scrollTo(0, 0);
							}}>{_tab.tabName}</div>
						)
					})
				}
			</div>
			<div className="list">
				{
					list && list.length > 0 && list.map((item, idx) => {
						const _couponReg = new RegExp(/(\d|\d(.\d{1,2}))折/, 'g');
						let _couponTarget = item.coupon.match(_couponReg);
						if(_couponTarget && _couponTarget.length > 0) {
							_couponTarget = Array.from(new Set(_couponTarget));
							_couponTarget.map(tar => {
								item.coupon = item.coupon.replace(new RegExp(tar, 'g'), `<span style="color: red; font-weight: 900;">${tar}</span>`);
							});
						}

						return (
							<div className="item" key={idx} onClick={() => {
								window.location.href = CONFIG.links.card;
							}}>
								<div className="cover" style={{ backgroundImage: `url(/images/zhujiajiao/${item.cover}.jpg)` }} />
								{/*<div className="cover" style={{ backgroundImage: `url(http://www.yeekin.cn/images/zhujiajiao/%E6%B8%B8%E6%B0%91%E5%92%96%E5%95%A1.jpg)` }} />*/}
								<div className="content">
									<div className="title">
										<span dangerouslySetInnerHTML={{ __html:  item.title }} />
										{ item.label && item.label != '' && <div className="label">{item.label}</div> }
									</div>
									<div className="address">{item.address}</div>
									<div className="coupon">
										<div className="label">折扣优惠</div>
										<div className="text" dangerouslySetInnerHTML={{ __html: item.coupon}} />
									</div>
								</div>
							</div>
						);
					})
				}
			</div>
		</section>
	);
};

export default PageModel;
