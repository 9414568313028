/**
 * Created by jimmy on 2020/11/6.
 */
export const links = {
	todo: '/todo',
	card: '/zhujiajiaov2/card',
	restaurant: '/zhujiajiaov2/store-list?tab=restaurant',
	present: '/zhujiajiaov2/store-list?tab=present',
	tea: '/zhujiajiaov2/store-list?tab=tea',
	bar: '/zhujiajiaov2/store-list?tab=bar',

	/**
	 * 第三方链接
	 */
	// 铁塔公司
	date: 'http://www.zhujiajiaotravel.com/Home/Index?routeFlag=booking',
	dateOrder: 'http://www.zhujiajiaotravel.com/Home/Index?routeFlag=ticketList',

	/**
	 * 有赞商城
	 */
	// 常规
	userOrder: 'https://shop91212188.youzan.com/v2/showcase/usercenter?alias=5jKo8qm1ox',
	// yikatong: 'https://j.youzan.com/8mpv22?source=h5',
	yikatong: 'https://j.youzan.com/9bCv22?source=h5',
	haowu: 'https://shop91212188.youzan.com/v2/feature/i6kWF47Qzw',
	baokuan: 'https://shop91212188.m.youzan.com/v2/feature/vVxeUmC8Qq',
	zhushidaji: 'https://shop91212188.youzan.com/v2/feature/2YRLxVHmsy',

	/**
	 * Banner 配置
	 */
	banners: {
		'yikatong': '/zhujiajiaov2/card',
		'sdg': 'https://shop91212188.m.youzan.com/wscgoods/detail/26y43jatv3uf8tx?scan=1&activity=none&from=kdt&qr=directgoods_1231710863&shopAutoEnter=1',
		// 'b03': 'https://shop91212188.m.youzan.com/v2/tag/2cs2ixn6',
		// 'b04': '/zhujiajiaov2/store-list?tab=restaurant',
		// 'b05': '/zhujiajiaov2/store-list?tab=tea',
		// 'b06': 'https://shop91212188.m.youzan.com/wscgoods/detail/2xhzesxhloyt0?scan=1&activity=none&from=kdt&qr=directgoods_872665407&shopAutoEnter=1',
		// 'b062': 'https://shop91212188.m.youzan.com/wscgoods/detail/2780ez1fijrdg?scan=1&activity=none&from=kdt&qr=directgoods_921424979&shopAutoEnter=1',
		// 'b07': 'https://shop91212188.m.youzan.com/wscgoods/detail/2okirs377j7dw?scan=1&activity=none&from=kdt&qr=directgoods_898929809&shopAutoEnter=1',
		// 'a01': 'https://shop91212188.m.youzan.com/wscgoods/detail/35wzx5k6zazvo?ump_type=seckill&ump_alias=1bgg5jjo8&activityType=seckill&shopAutoEnter=1',
		// 'a02': 'https://shop91212188.youzan.com/v2/feature/upjtOIue0S',
		'shuishang': 'https://shop91212188.m.youzan.com/wscgoods/detail/2frxm2n2qzsxw?scan=1&activity=none&from=kdt&qr=directgoods_934436411&shopAutoEnter=1',
		'jiang': 'https://shop91212188.m.youzan.com/wscgoods/detail/3eta4ct1xuyno?scan=1&activity=none&from=kdt&qr=directgoods_953762665&shopAutoEnter=1',
		'dragonboat': 'https://shop91212188.m.youzan.com/wscgoods/detail/1ye72tf83ghk4?scan=1&activity=none&from=kdt&qr=directgoods_957451447&shopAutoEnter=1',
		'zizhu': 'https://shop91212188.m.youzan.com/wscgoods/detail/2xj8yp8zhruec?scan=1&activity=none&from=kdt&qr=directgoods_963869630&shopAutoEnter=1',
	}
};

/**
 * 首页爆款商品列表
 */
export const hotList = [
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/11/04/Fmggs5VM8KDDG6wcVCwlPJJXugxV.jpg',
		title: '朱家角古镇 · 秋冬双人露营体验套餐',
		salePrice: '128',
		oriPrice: '318',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/35x261uo969msmo?scan=1&activity=none&from=kdt&qr=directgoods_1279635463&shopAutoEnter=1',
		isCoupon: false
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/04/20/FhbNV0r_ufRpOtWhS00EUy8ppfX-.jpg',
		title: '朱家角特色体验：水上『皮划艇』（2人起订，大小同价）',
		salePrice: '135',
		oriPrice: '199',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2frxm2n2qzsxw?scan=1&activity=none&from=kdt&qr=directgoods_934436411&shopAutoEnter=1',
		isCoupon: false
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/05/28/FkfGVqxhWkj7WYSydOylPLH02nba.jpg',
		title: '朱家角特色体验：水上『桨板』（2人起订，大小同价）',
		salePrice: '135',
		oriPrice: '298',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/3eta4ct1xuyno?scan=1&activity=none&from=kdt&qr=directgoods_953762665&shopAutoEnter=1',
		isCoupon: false
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/07/01/FtmukSBrpxeqdUv63dlBYOSI-aNZ.jpg',
		title: '智能标准房单晚度假套餐（含早+乐园门票）',
		salePrice: '568',
		oriPrice: '946',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2732v3bxvdero?scan=1&activity=none&from=kdt&qr=directgoods_971909239&shopAutoEnter=1'
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/09/16/Fl-MKoXhSTr-Jk5fS9vEXVxaYVjl.jpg',
		title: '国内首个SDG营地，一日研学体验',
		salePrice: '179',
		oriPrice: '868',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/26y43jatv3uf8tx?scan=1&activity=none&from=kdt&qr=directgoods_1231710863&shopAutoEnter=1',
		isCoupon: true
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/04/13/FhSUoLnhhfuACKKxkajZXuC9vS7Y.jpg',
		title: '朱家角灵动时刻SMART MOMENT综合体验馆多景点家庭套票',
		salePrice: '129',
		oriPrice: '398',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2olq938j7c084?scan=1&activity=none&from=kdt&qr=directgoods_930430194&shopAutoEnter=1',
		isCoupon: false
	},
	// {
	// 	cover: 'https://img01.yzcdn.cn/upload_files/2021/06/08/For1yHlZKyUqF-ru0cv-hmn0ycGp.jpg',
	// 	title: '青浦首家全净素 净心斋素菜馆素食自助',
	// 	salePrice: '15',
	// 	oriPrice: '56',
	// 	link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2xj8yp8zhruec?scan=1&activity=none&from=kdt&qr=directgoods_963869630&shopAutoEnter=1'
	// },
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/06/21/FpsEMhQQ28pjj1MBIr33WLEGGjLY.jpg',
		title: '灵动卡丁车+玛雅单人下午茶套餐',
		salePrice: '78',
		oriPrice: '103',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2xfiw185mq3ro?scan=1&activity=none&from=kdt&qr=directgoods_977370809&shopAutoEnter=1'
	},
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/06/18/FhcvXu4dIqKAkfhWgAYENw8hMAlX.jpg',
		title: '灵动时刻卡丁车',
		salePrice: '48',
		oriPrice: '100',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2fwvuuki5xdic?scan=1&activity=none&from=kdt&qr=directgoods_965817917&shopAutoEnter=1'
	},
	// {
	// 	cover: 'https://img01.yzcdn.cn/upload_files/2021/06/02/FqPXSimNMlQm4hxF61ehNU0UNwPq.jpg',
	// 	title: '朱家角端午龙舟体验项目',
	// 	salePrice: '148',
	// 	oriPrice: '398',
	// 	link: 'https://shop91212188.m.youzan.com/wscgoods/detail/1ye72tf83ghk4?scan=1&activity=none&from=kdt&qr=directgoods_957451447&shopAutoEnter=1'
	// },
	{
		cover: 'https://img01.yzcdn.cn/upload_files/2021/06/08/Fl05agWLpa-kJbZkioLDDnJjKH_E.jpg',
		title: 'COMPANION小伴咖啡',
		salePrice: '15',
		oriPrice: '',
		link: 'https://shop91212188.m.youzan.com/wscgoods/detail/2fy3i83kza5us?scan=1&activity=none&from=kdt&qr=directgoods_963457061&shopAutoEnter=1'
	},
	// {
	// 	cover: 'https://img01.yzcdn.cn/upload_files/2021/06/08/FjRStvCU-Hv_9r--cSv1ahnfxrTR.jpeg',
	// 	title: '朱家角净心斋 创意轻蔬素食馆 5人套餐',
	// 	salePrice: '569',
	// 	oriPrice: '599',
	// 	link: 'https://shop91212188.m.youzan.com/wscgoods/detail/3637juxwadt90?scan=1&activity=none&from=kdt&qr=directgoods_963871805&shopAutoEnter=1'
	// },
];

// 一卡通价格
export const cardPrice = 10;
