/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import ReactSwipe from 'react-swipe';

import Page from '../components/page';

import './about.less';
import IMG_BANNER from './images/banner/3.png';

const PageAbout = props => {
	let reactSwipeEl;
	
	return (
		<Page title="关于我们">
			<section className="banner-wrap">
				<ReactSwipe
					className="banner"
					swipeOptions={{
						continuous: false,
						stopPropagation: false
					}}
					ref={el => (reactSwipeEl = el)}
				>
					{
						[IMG_BANNER].map((pic, idx) => <div className="banner-item" key={idx} style={{ backgroundImage: `url(${pic})` }} />)
					}
				</ReactSwipe>
				{/*<button className="ctrl-btn prev" onClick={() => reactSwipeEl.prev()} />*/}
				{/*<button className="ctrl-btn next" onClick={() => reactSwipeEl.next()} />*/}
			</section>
			<section className="body-container body-content">
				<div className="title">公司简介</div>
				<div className="intro">
					{
						[
							'上海奕肯信息技术有限公司成立于2017年，是一家集平面广告设计，展陈空间设计，活动策划，软件开发，社会组织第三方服务，企业形象和品牌推广于一体的综合性公司。团队核心成员均来自腾讯，阿里，国家大剧院等技术骨干。',
							'服务对象包括上海市宣传部，上海东方网，上海市文旅局，上海市青浦区组织部，上海市青浦区宣传部，上海市青浦区融媒体中心，上海市青浦区团委，上海青浦区文旅局，上海市青浦区练塘镇土地整治项目指挥部、上海市青浦区及各街镇党建服务中心、上海市青浦区及各街镇城市网格化综合管理中心等。',
							'作品包括，参与了各级多个党群服务站的设计及施工，注重特色发掘，注重体验的交互性，团队具备开创性设计思维。参与了上海市乡村振兴示范村的设计建设，引入智慧村庄的软件及设备，开发党建网格，综治，城市管理网格三网融合精细化管理软件，引入3d村庄虚拟漫游项目，及为乡村振兴开发导入文创品牌等诸多项目。参与上海朱家角旅游智慧文创整体规划设计，将智慧旅游，文创开发，电商运营，平台打造等进行生态系统打造，实现产业闭环。参与设计上海青浦创建全国文明城市系列项目，含公益海报设计制作，景观设计，主要路口大型宣传景观，活动策划等。参与政府开发设计小程序，如垃圾分类，智慧旅游等。提供社会组织购买服务，app，微信公共号开发运营等。提供城市雕塑及景观小品设计制作。',
							'我们始终坚持“为客户提供最优的解决方案和服务”，创新，进取，卓越。'
						].map((item, idx) => (
							<div className="p" key={idx}>{item}</div>
						))
					}
				</div>
			</section>
		</Page>
	);
};

export default PageAbout;