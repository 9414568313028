/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './content.less';

// components
import PageBack from './pageBackComp';


// images
import IMG_SHEQU_TITLE_1 from './images/shequ-title-1.png';
import IMG_SHEQU_CONTENT_1 from './images/shequ-content-1.png';
import IMG_SHEQU_TITLE_2 from './images/shequ-title-2.png';
import IMG_SHEQU_CONTENT_2 from './images/shequ-content-2.png';
import IMG_SHEQU_TITLE_3 from './images/shequ-title-3.png';
import IMG_SHEQU_CONTENT_3 from './images/shequ-content-3.png';
import IMG_SHEQU_TITLE_4 from './images/shequ-title-4.png';
import IMG_SHEQU_CONTENT_4 from './images/shequ-content-4.png';
import IMG_SHEQU_TITLE_5 from './images/shequ-title-5.png';
import IMG_SHEQU_CONTENT_5 from './images/shequ-content-5.png';
import IMG_SHEQU_TITLE_6 from './images/shequ-title-6.png';
import IMG_SHEQU_CONTENT_6 from './images/shequ-content-6.png';

import IMG_DAIBIAO_TITLE_1 from './images/daibiao-title-1.png';
import IMG_DAIBIAO_CONTENT_1 from './images/daibiao-content-1.png';
import IMG_DAIBIAO_TITLE_5 from './images/daibiao-title-5.png';
import IMG_DAIBIAO_CONTENT_5 from './images/daibiao-content-5.png';
import IMG_DAIBIAO_TITLE_6 from './images/daibiao-title-6.png';
import IMG_DAIBIAO_CONTENT_6 from './images/daibiao-content-6.png';

const _STORE = {
	shequ1: { title: IMG_SHEQU_TITLE_1, content: IMG_SHEQU_CONTENT_1 },
	shequ2: { title: IMG_SHEQU_TITLE_2, content: IMG_SHEQU_CONTENT_2 },
	shequ3: { title: IMG_SHEQU_TITLE_3, content: IMG_SHEQU_CONTENT_3, titleStyle: { height: 100, marginBottom: 20 } },
	shequ4: { title: IMG_SHEQU_TITLE_4, content: IMG_SHEQU_CONTENT_4 },
	shequ5: { title: IMG_SHEQU_TITLE_5, content: IMG_SHEQU_CONTENT_5 },
	shequ6: { title: IMG_SHEQU_TITLE_6, content: IMG_SHEQU_CONTENT_6 },

	daibiao1: { title: IMG_DAIBIAO_TITLE_1, content: IMG_DAIBIAO_CONTENT_1 },
	daibiao5: { title: IMG_DAIBIAO_TITLE_5, content: IMG_DAIBIAO_CONTENT_5 },
	daibiao6: { title: IMG_DAIBIAO_TITLE_6, content: IMG_DAIBIAO_CONTENT_6 },
};

const PageModel = props => {
	const [ data, setData ] = useState(null);
	const getData = () => {
		const { id } = props.match.params;
		setData(_STORE[id] || null);
	};
	useEffect(() => {
		getData();
	}, [ props.match.params ]);

	if(!data) return null;
	return (
		<section className="baihe-content-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="title" style={{ backgroundImage: `url(${data.title})`, ...data.titleStyle }} />
			<div className="content-body">
				<img src={data.content} />
			</div>
		</section>
	);
};

export default PageModel;
