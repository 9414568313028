/**
 * Created by jimmy on 2019/11/7.
 */
import * as Request from './request';
import * as WX from './wx';
import * as CONST from './const';
import EXIF from './exif';

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';
	
	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] || '';
};

export const setTitle = title => {
	if(document) {
		document.title = title;
	}
};

export const imageUtil = {
	//对图片旋转处理 added by lzk
	rotateImg: function (img, direction,canvas,expectWidth,expectHeight) {
		//最小与最大旋转方向，图片旋转4次后回到原方向
		let min_step = 0;
		let max_step = 3;
		
		if (img == null) return;
		let width = expectWidth;//img.width; //
		let height = expectHeight;// img.height;//
		let step = 2;
		if (step == null) {
			step = min_step;
		}
		if (direction == 'right') {
			step++;
			//旋转到原位置，即超过最大值
			step > max_step && (step = min_step);
		} else {
			step--;
			step < min_step && (step = max_step);
		}
		
		//旋转角度以弧度值为参数
		let degree = step * 90 * Math.PI / 180;
		let ctx = canvas.getContext('2d');
		switch (step) {
			case 0:
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, expectWidth, expectHeight);
				break;
			case 1:
				canvas.width = height;
				canvas.height = width;
				ctx.rotate(degree);
				ctx.drawImage(img, 0, -height, expectWidth, expectHeight);
				break;
			case 2:
				canvas.width = width;
				canvas.height = height;
				ctx.rotate(degree);
				ctx.drawImage(img, -width, -height, expectWidth, expectHeight);
				break;
			case 3:
				canvas.width = height;
				canvas.height = width;
				ctx.rotate(degree);
				ctx.drawImage(img, -width, 0, expectWidth, expectHeight);
				break;
		}
	},
	yasuo: function (file1, callback) {
		let Orientation;
		EXIF.getData(file1, function() {
			EXIF.getAllTags(this);
			Orientation = EXIF.getTag(this, 'Orientation');
		});
		
		const fileRead = new FileReader();
		fileRead.readAsDataURL(file1);
		fileRead.onload = async (e) => {
			let image = new Image();
			image.src = e.target.result;
			image.onload = function () {
				let expectWidth = this.naturalWidth;
				let expectHeight = this.naturalHeight;
				// alert(expectWidth+'###'+expectHeight)
				console.log('expectWidth',expectWidth, 'expectHeight', expectHeight);
				if (this.naturalWidth > this.naturalHeight && this.naturalWidth > 750) {
					expectWidth = 750;
					expectHeight = expectWidth * this.naturalHeight / this.naturalWidth;
				} else if (this.naturalHeight > this.naturalWidth && this.naturalHeight > 1330) {
					expectHeight = 1330;
					expectWidth = expectHeight * this.naturalWidth / this.naturalHeight;
				}
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				canvas.width = expectWidth;
				canvas.height = expectHeight;
				// alert(canvas.width+';'+canvas.height);
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
				let base64 = canvas.toDataURL("image/jpeg", 0.6);
				//修复ios
				if (navigator.userAgent.match(/iphone/i)) {
					//如果方向角不为1，都需要进行旋转 added by lzk
					if(Orientation != "" && Orientation != 1){
						switch(Orientation){
							case 6://需要顺时针（向左）90度旋转
								imageUtil.rotateImg(this,'left',canvas,expectWidth,expectHeight);
								break;
							case 8://需要逆时针（向右）90度旋转
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);
								break;
							case 3://需要180度旋转
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);//转两次
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);
								break;
						}
					}
				}else if (navigator.userAgent.match(/Android/i)) {// 修复android
					//base64 = encoder.encode(ctx.getImageData(0, 0, expectWidth, expectHeight), 80);
				}else{
					if(Orientation != "" && Orientation != 1){
						switch(Orientation){
							case 6://需要顺时针（向左）90度旋转
								imageUtil.rotateImg(this,'left',canvas,expectWidth,expectHeight);
								break;
							case 8://需要逆时针（向右）90度旋转
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);
								break;
							case 3://需要180度旋转
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);//转两次
								imageUtil.rotateImg(this,'right',canvas,expectWidth,expectHeight);
								break;
						}
					}
					base64 = canvas.toDataURL("image/jpeg", 0.6);
				}
				base64 = canvas.toDataURL("image/jpeg", 0.6);
				
				callback && callback(base64);
			};
		}
	},
	// hard code first
	// img1 means background/screen
	// img2 means person
	merge: (srcimg, contentimg, contentx = 0/* from left */, contenty = 0/* from top */) => {
		return new Promise(async (resolve, reject) => {
			let canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const screenWidth = window.innerWidth;
			const radio = 6/8;
			
			const screenImg = new Image();
			// screenImg.crossOrigin = 'Anonymous';
			screenImg.src = srcimg;
			screenImg.onload = async function () {
				canvas.width = screenWidth;
				canvas.height = screenWidth/radio;
				ctx.drawImage(screenImg, 0, 0, canvas.width, canvas.height);
				
				const contentImg = new Image();
				// contentImg.crossOrigin = 'Anonymous';
				contentImg.src = contentimg;
				contentImg.onload = async function () {
					// const wid = screenWidth * 0.5;
					// const hei = this.height / this.width * wid;
					let expectWidth = this.naturalWidth;
					let expectHeight = this.naturalHeight;
					// alert(expectWidth+'###'+expectHeight)
					
					if (this.naturalWidth > this.naturalHeight && this.naturalWidth > 750) {
						expectWidth = 750;
						expectHeight = expectWidth * this.naturalHeight / this.naturalWidth;
					} else if (this.naturalHeight > this.naturalWidth && this.naturalHeight > 1330) {
						expectHeight = 1330;
						expectWidth = expectHeight * this.naturalWidth / this.naturalHeight;
					}
					
					const x = screenWidth * contentx;
					const y = screenWidth/radio * contenty;
					ctx.drawImage(contentImg, x, y, expectWidth, expectHeight);
					const dataURL = canvas.toDataURL('image/png');
					resolve(dataURL);
					canvas = null;
				};
			};
		});
	},
	toBase64: (img) => {
		return new Promise(async (resolve, reject) => {
			let image = new Image();
			image.src = img;
			image.onload = function () {
				let expectWidth = this.naturalWidth;
				let expectHeight = this.naturalHeight;
				
				if (this.naturalWidth > this.naturalHeight && this.naturalWidth > 750) {
					expectWidth = 750;
					expectHeight = expectWidth * this.naturalHeight / this.naturalWidth;
				} else if (this.naturalHeight > this.naturalWidth && this.naturalHeight > 1330) {
					expectHeight = 1330;
					expectWidth = expectHeight * this.naturalWidth / this.naturalHeight;
				}
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				canvas.width = expectWidth;
				canvas.height = expectHeight;
				
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
				let base64 = canvas.toDataURL("image/jpeg", 0.6);
				resolve(base64);
			};
		});
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const getUA = () => {
	if(!window || !window.navigator || !window.navigator.userAgent){
		return null;
	}
	
	const ua = window.navigator.userAgent.toLocaleLowerCase();
	
	return {
		ua,
		isInWechat: ua.indexOf('micromessenger') > -1,
		isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
	}
};

export const _alert = (txt) => {
	if(!txt) return;
	
	toast.show(txt);
	
	setTimeout(() => {
		toast.hide();
	}, 1500);
};

/**
 * 登录功能
 * 微信内：微信登录
 * APP 内：暂时先 return
 */
const _user = {
	getInfo: async () => {
		let userInfo = sessionStorage.getItem('userinfo');
		userInfo = userInfo ? JSON.parse(userInfo) : {};
		if(userInfo && userInfo.yid) {
			return userInfo;
		}
		
		const result = await Request.get({
			url: '/user'
		});
		if(!result) return {};
		
		const _userinfo = result.data || {};
		_user.setInfo(_userinfo);
		return _userinfo;
	},
	setInfo: (info = {}) => {
		sessionStorage.setItem('userinfo', JSON.stringify(info));
	},
	login: async () => {
		const { isInWechat, isInRongMeiApp } = getUA();
		const yid = localStorage.getItem('yid');
		if(isInWechat) {
			return await WX.login();
		}else if(isInRongMeiApp) {
			const result = await Request.post({
				url: '/user/login',
				data: {
					yid
				}
			});
			if(!result) return;
			_user.setInfo(result.data);
			return result.data;
		}
	}
};
export const user = _user;

/**
 * add metas sync
 * @param list
 */
export const setMetas = (list = []) => {
	const _headDom = document.getElementsByTagName('head')[0];
	list.map(l => {
		let meta = document.createElement('meta');
		meta.content = l.content;
		meta.name = l.name;
		_headDom.appendChild(meta);
	});
};

export const addICO = (icoPath = '') => {
	if(!icoPath) return;

	if(document && document.head && document.head.children && document.head.children[1]) {
		document.head.children[1].href = icoPath;
	}
};