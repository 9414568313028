/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Typography, Input, Form, message, Modal, Select} from 'antd';

import * as CONST from '../common/const';
import * as REQUEST from "../common/request";

import Page from '../components/page';
import './index.less';
import * as Utils from "../common/utils";
import {Link} from "react-router-dom";
import moment from "moment";

const PageModel = props => {
  const [ list, setList ] = useState([]);
  const getList = async () => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/announce/list'
    });
    if(!result) return;

    result.data.list.map(item => {
      item.key = item.id;
      return item;
    });
    setList(result.data.list);
  };
  useEffect(() => {
    getList();

    Utils.setPageTitle('资讯公告');

    return () => false;
  }, [ 123 ]);

  return (
    <section className="announce-list-wrapper">
      {
        list?.length > 0 && list.map((item, idx) => {
          return <div className="item" key={idx} onClick={() => {
            if(item.type == 1 && item.link) {
              window.location.href = item.link;
            }else if(item.type == 2) {
              props.history.push(`/zhujiajiao/v2/announce/${item.id}`);
            }
          }}>
            <div className="content">
              <span className="title">{item.title}</span>
              <span className="desc">{item.createdAt && moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</span>
            </div>
            <div className="cover" style={{ backgroundImage: `url(${CONST.imgPrefix + item.cover})` }} />
          </div>
        })
      }
    </section>
  );
};

export default PageModel;
