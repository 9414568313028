/**
 * Created by jimmy on 2022/7/1.
 */

import React, { useState, useEffect, useRef } from 'react';
import {Button, Typography, Input, Form, message, Modal, Select} from 'antd';

import * as CONST from '../common/const';
import * as REQUEST from "../common/request";

import Page from '../components/page';
import './index.less';
import * as Utils from "../common/utils";
import * as module from "module";
import moment from "moment";

const _resetDate = date => date && date.replace('年', '/').replace('月', '/').replace('日', '') || '';

const PageModel = props => {
  const [ reserveSuccess, setReserveSuccess ] = useState(false);
  const [ currentSchedule, setCurrentSchedule ] = useState(null);
  const [ formObj, setFormObj ] = useState({});
  const changeHandle = (target, value) => {
    formObj[target] = value;
    setFormObj({...formObj});
  };

  const [ list, setList ] = useState([]);
  const getList = async () => {
    const result = await REQUEST.get({
      url: '/zhujiajiao/v2/cruise/schedule/list'
    });
    if(!result) return;

    const list = [];
    if(result.data.list?.length > 0) {
      const currentMoment = moment();
      const currentDate = currentMoment.format('YYYY/MM/DD');
      const tomorrowDate = currentMoment.add(1, 'd').format('YYYY/MM/DD');
      const currentHour = currentMoment.format('HH');
      const deadLineHour = 20;
      result.data.list.map(lst => {
        // 16点之后就不能预约当天的
        if (lst.stock > 0) {
          const _date = _resetDate(lst.date);
          if(currentHour < deadLineHour && currentDate <= _date) {
            list.push(lst);
          }else if(currentHour >= deadLineHour && tomorrowDate < _date) {
            list.push(lst);
          }
        }
      });
    }
    if(list.length > 0) {
      list.sort((a, b) => {
        if(a.date == b.date) {
          const _a = a.time.slice(0, 2);
          const _b = b.time.slice(0, 2);
          return Number(_a) - Number(_b);
        }
        let adate = _resetDate(a.date);
        adate = moment(adate).format('YYYYMMDD');
        let bdate = _resetDate(b.date);
        bdate = moment(bdate).format('YYYYMMDD');
        return adate - bdate;
      });

      // list.sort((a, b) => {
      //   const _a = a.time.slice(0, 2);
      //   const _b = b.time.slice(0, 2);
      //   return Number(_a) - Number(_b);
      // });

      const first = list[0];
      setFormObj({
        scheduleId: first.id,
        count: Math.min(1, first.stock)
      });

      setCurrentSchedule(first);
      setList(list);
    }
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const submitHandle = async () => {
    console.log('formObj', formObj);
    if(!formObj.scheduleId) return Utils._alert('请选择日期和时段');
    if(!formObj.count) return Utils._alert('请选择预定船数');
    if(!formObj.traveller) return Utils._alert('请输入预约人姓名');
    if(!/^1[3-9]\d{9}$/.test(formObj.mobile)) return Utils._alert('请输入有效手机号');

    const result = await REQUEST.post({
      url: '/zhujiajiao/v2/cruise/reserve',
      data: formObj
    });
    if(!result) return;
    setReserveSuccess(true);
  };

  useEffect(() => {
    Utils.setPageTitle('古镇游船预约');
    window.scrollTo(0, 0);

    return () => false;
  }, []);

  const radio = Math.min(750/1600, window.innerWidth/window.innerHeight);

  return (
    <section className="cruise-reserve-wrapper" style={{ height: window.innerWidth/radio }}>
      <div className="info-wrapper">
        {
          reserveSuccess && <div className="reserve-success">
            <div className="success-sign" />
            <div className="success-tip">预约成功</div>
            <div className="success-tip-desc">请按预约时间抵达朱家角课植园码头（地址：西井街113号），凭预约手机号付费登船。</div>
          </div> || <div className="form-wrapper">
            <div className="form-item-wrapper">
              <label>预约日期</label>
              <div className="form-item">
                {
                  list?.length > 0 && <select placeholder="请选择日期和时段" onChange={e => {
                    changeHandle('scheduleId', e.target.value);
                    const [ currentSchedule = {} ] = list.filter(lst => lst.id == e.target.value);
                    setCurrentSchedule(currentSchedule);
                  }}>
                    {
                      list.map((item, idx) => {
                        if(item.stock <= 0) return null;
                        return <option key={idx} value={item.id}>{item.date} {item.time}</option>
                      })
                    }
                  </select> || <span style={{ color: '#777777' }}>暂无可选班期</span>
                }
              </div>
            </div>
            <div className="form-item-wrapper">
              <label>预定船数</label>
              <div className="form-item">
                {
                  currentSchedule?.id && <select placeholder="请选择预定船数" onChange={e => {
                    changeHandle('count', e.target.value);
                  }}>
                    {
                      (() => {
                        const stock = currentSchedule?.stock || 0;
                        let arr = [];
                        console.log('currentSchedule', currentSchedule, 'stock', stock);
                        if(stock > 0) {
                          for(let i = 1; i <= stock; i++) {
                            arr.push(i);
                          }
                        }
                        console.log('arr', arr);
                        return arr;
                      })().map((item, idx) => {
                        return <option key={idx} value={item}>{item}</option>
                      })
                    }
                  </select> || <span style={{ color: '#777777' }}>暂无可选时段</span>
                }
              </div>
            </div>
            <div className="form-item-wrapper">
              <label>预约人</label>
              <div className="form-item"><input placeholder="请输入预约人姓名" onChange={e => {
                changeHandle('traveller', e.target.value);
              }} /></div>
            </div>
            <div className="form-item-wrapper">
              <label>手机号码</label>
              <div className="form-item"><input placeholder="请输入手机号码" maxLength={11} onChange={e => {
                changeHandle('mobile', e.target.value);
              }} /></div>
            </div>

            <div className="reserve-button" onClick={submitHandle} />
          </div>
        }
      </div>
    </section>
  );
};

export default PageModel;
