import * as CONST from './const';
import * as REQUEST from "./request";
import {message} from "antd";

export const setPageTitle = title => document.title = title;

export const localStorage = {
  get: key => {
    let ls = {};
    try {
      ls = JSON.parse(window.localStorage.getItem(key)) || {};
    } catch (e) {}
    return ls[key];
  },
  set: (key, value = {}) => {
    try {
      window.localStorage.setItem(
        key,
        JSON.stringify(value)
      );
    }catch (e){}
  }
};

// loading
export const loading = {
  show: () => {
    document.getElementById('loading').style.display = 'block';
  },
  hide: () => {
    document.getElementById('loading').style.display = 'none';
  }
};

export const toast = {
  show: (txt) => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'block';
    $toast.innerText = txt;
  },
  hide: () => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'none';
    $toast.innerText = '';
  }
};

export const _alert = (txt) => {
  if(!txt) return;

  toast.show(txt);

  setTimeout(() => {
    toast.hide();
  }, 1500);
};

export const getUA = () => {
  if(!window || !window.navigator || !window.navigator.userAgent){
    return null;
  }

  const ua = window.navigator.userAgent.toLocaleLowerCase();

  return {
    ua,
    isInWechat: ua.indexOf('micromessenger') > -1,
    isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
  }
};

export const getTargetByKey = (array = [], key, value) => {
  const [ target = {} ] = array.filter(item => item[key] == value);
  return target;
};

export const getFromMapByKey = (map, key) => {
  let target = null;
  if(map[key]) {
    target = map[key];
  }else{
    for(let i in map) {
      if(map[i] == key) {
        target = i;
      }
    }
  }
  return target;
};

export const transferMapToArray = (map, keyField, valueField) => {
  const arr = [];
  Object.keys(map).map(key => {
    arr.push({
      [keyField]: key,
      [valueField]: map[key]
    });
  });
  return arr;
};

export const getSearchByKey = (key, search = window.location.search) => {
  if(!search) return '';

  const _search = search.slice(1);
  const _searchArr = _search.split('&');
  const _map = {};
  _searchArr.map(item => {
    const _item = item.split('=');
    if(_item[0] && _item[1]) {
      _map[_item[0]] = _item[1];
    }
  });
  return _map[key] || '';
};

/**
 * 有效手机号码验证
 * @param mobile
 * @returns {boolean}
 */
export const mobileVerify = mobile => {
  return /^1[3456789]\d{9}$/.test(mobile);
};

/**
 * 有效账户名验证
 * @param account
 * @returns {boolean}
 */
export const accountVerify = account => {
  return /^[_a-zA-Z0-9]+$/.test(account);
};

/**
 * 倒计时
 * @param second，默认60秒
 * @param callback
 * @returns {{start: start}}
 */
export const countdown = (second = 60, callback) => {
  let timer = null;
  return {
    start: () => {
      if(second < 0) {
        clearTimeout(timer);
        timer = null;
        callback && typeof callback == 'function' && callback();
        return;
      }
      timer = setTimeout(function(){
          callback && typeof callback == 'function' && callback({ second });
          second -= 1;

          countdown(second, callback).start();
        }
        ,1000);
    }
  }
};

export const editorUploadHelper = (settings = {}) => {
  const serverURL = CONST.uploadPrefix + settings.url;
  return (param) => {
    const xhr = new XMLHttpRequest;
    const fd = new FormData();
    const successFn = (event) => {
      let { response } = event.target;
      response = JSON.parse(response);
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: CONST.imgPrefix + response.data.src,
        meta: {
          id: param.id,
          title: 'xxx',
          alt: 'xxx',
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          poster: CONST.imgPrefix + response.data.src, // 指定视频播放器的封面
        }
      })
    };
    const progressFn = (event) => {
      // 上传进度发生变化时调用param.progress
      param.progress(event.loaded / event.total * 100)
    };
    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: 'unable to upload.'
      })
    };
    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);

    fd.append('file', param.file);
    xhr.open('POST', serverURL, true);
    xhr.send(fd);
  };
}

export const getImageSize = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      const width = this.width;
      const height = this.height;
      resolve({ width, height, radio: width/height });
    }
  })
};

export const getMallInfo = async (force) => {
  const MALL_ID = 1;

  let res = window.sessionStorage.getItem('_local_mallinfo');
  if(res && res != '{}' && !force) {
    res = JSON.parse(res);
    return res;
  }

  const result = await REQUEST.get({
    url: '/mall/get',
    data: {
      id: MALL_ID
    }
  });
  if(result?.data) {
    window.sessionStorage.setItem('_local_mallinfo', JSON.stringify(result.data));
    return result.data;
  }
  return {};
};

export const imageUploadSizeVerify = (file, showErrTip = true) => {
  const msize = 10;
  const flag = file.size / 1024 / 1024 < msize;
  if (!flag && showErrTip) {
    message.error(`上传图片大小不能超过${msize}M`);
  }
  return flag;
};
