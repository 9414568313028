/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import ReactSwipe from 'react-swipe';
import moment from 'moment';

import * as Utils from '../../common/utils';

import './shequ.less';

// components
import PageBack from './pageBackComp';

// images
import IMG_DAIBIAO_2 from './images/daibiao-entrance-2.png';
import IMG_DAIBIAO_3 from './images/daibiao-entrance-3.png';
import IMG_DAIBIAO_4 from './images/daibiao-entrance-4.png';
import IMG_SHEQU_5 from './images/shequ-entrance-5.png';
import IMG_SHEQU_6 from './images/shequ-entrance-6.png';

const PageModel = props => {
	return (
		<section className="baihe-shequ-container" style={{ height: window.innerHeight }}>
			<PageBack />
			<div className="entrances">
				{
					[
						{ icon: IMG_DAIBIAO_2, link: '/baihe/pinpai' },
						{ icon: IMG_DAIBIAO_3, link: '/baihe/content/daibiao5' },
						{ icon: IMG_DAIBIAO_4, link: '/baihe/content/daibiao6' },
						{ icon: IMG_SHEQU_5, link: '/baihe/content/shequ5' },
						{ icon: IMG_SHEQU_6, link: '/baihe/content/shequ6' },
					].map((item, idx) => <div className="item" key={idx} onClick={() => {
						props.history.push(item.link);
						return;
					}}>
						<img src={item.icon} />
					</div>)
				}
			</div>
		</section>
	);
};

export default PageModel;
