/**
 * Created by jimmy on 2020/10/30.
 */
import React, { useState, useEffect } from 'react';
//引入组件
import { PhotoProvider, PhotoView } from "react-photo-view";
//引入css
import "react-photo-view/dist/react-photo-view.css";

import { Helmet } from 'react-helmet';

const url = 'https://static-yeekin.oss-cn-shanghai.aliyuncs.com/zhujiajiao/images/o_1i9sq4lor1u91fc91in31oj31d21c01.jpg'
const PageModel = props => {
	return (
		<div className="App">
			<Helmet>
				<title>文旅地图</title>
			</Helmet>
			<PhotoProvider>
				<PhotoView src={url}>
					<img style={{ width: '100%', height: '100%' }} src={url} />
				</PhotoView>
			</PhotoProvider>
		</div>
	);
};

export default PageModel;
